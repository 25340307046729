import React, { FC, useState, useEffect } from 'react';
import API from 'repository';
import trackingConst from 'config/TrackingConst';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
    formatPriceServiceToNum,
    formatPriceWithKCharacter,
} from 'utils/BookingUltils';
import { ISelected } from '../../types/Selected';
import KEY from '../../Const';
import Selected from '../components/Selected';
import Title from '../components/Title';

const Service: FC<{
    onChangeSelected: Function;
    selected: ISelected;
    setHasClickNextInSalon: Function;
    trackingData: any;
    hasClickInService: number;
    guideStep5: string;
    isLoadingServices: boolean;
    guideSelectService: any;
    allowChange: boolean;
}> = ({
    onChangeSelected,
    selected,
    setHasClickNextInSalon,
    trackingData,
    hasClickInService,
    guideStep5,
    isLoadingServices,
    guideSelectService,
    allowChange,
}) => {
    const salonId = selected[KEY.SALON].id;
    const listChosenService = selected[KEY.CHOSEN_SERVICES];
    const [message, setMessage] = useState('');
    const [totalMoney, setTotalMoney] = useState(0);

    const postTracking = async (data: any) => {
        try {
            await API.postTrackingDynamic(data);
        } catch (e) {
            console.log(e.message);
        }
    };

    const handleOnChangeService = () => {
        if (salonId === 0) {
            setHasClickNextInSalon(0);
            const data = {
                data: {
                    customerId: trackingData.customerId,
                    phone: selected[KEY.PHONE],
                    salonId,
                    chosenServices: JSON.stringify(listChosenService),
                    dateBook: selected[KEY.DATE_BOOK],
                    stylistId: selected[KEY.STYLIST].id,
                },
                pageId: trackingConst.Pages.BOOKING_SINGLE_PAGE,
                screenId:
                    trackingConst.Screens.BOOKING_SELECT_BOOKING_SINGLE_SCREEN,
                eventId: trackingConst.Events.CLICK_BUTTON_SERVICE,
                tokenKey: trackingData.token,
                source: trackingData.source,
                utmSource: trackingData.utmSource,
            };
            postTracking(data);
        } else {
            onChangeSelected(KEY.STEP, 2);
            setHasClickNextInSalon(1);
        }
    };

    const getClassServiceStep = () => {
        const isSalonSelected = selected[KEY.SALON].id > 0;
        if (!isSalonSelected) return '';
        if (
            isSalonSelected &&
            (selected[KEY.CHOSEN_SERVICES].length > 0 || allowChange)
        )
            return 'main-screen__block--done';
        if (isSalonSelected && selected[KEY.CHOSEN_SERVICES].length === 0)
            return 'main-screen__block--active';
        return '';
    };

    useEffect(() => {
        if (!allowChange) {
            if (selected[KEY.CHOSEN_SERVICES].length === 0) {
                if (hasClickInService === 0)
                    setMessage('Mời bạn chọn dịch vụ để chọn giờ cắt');
                if (hasClickInService === -1)
                    setMessage(
                        'Một số dịch vụ đã ngừng bán, bạn vui lòng chọn lại dịch vụ',
                    );
            } else {
                setMessage('');
            }
        } else {
            setMessage('');
        }
    }, [allowChange, hasClickInService, selected[KEY.CHOSEN_SERVICES]]);

    useEffect(() => {
        if (listChosenService) {
            setTotalMoney(
                listChosenService.reduce(
                    (acc, curr) =>
                        acc + formatPriceServiceToNum(curr.price || ''),
                    0,
                ),
            );
        }
    }, [listChosenService]);

    return (
        <div
            className={`main-screen__block ${getClassServiceStep()}`}
            id="serviceAttributeId"
        >
            <div className="next-item" />
            <Title text="2. Chọn dịch vụ" />
            <Selected
                type="service"
                value={
                    listChosenService.length
                        ? `Đã chọn ${listChosenService.length} dịch vụ`
                        : ''
                }
                placeholder="Xem tất cả dịch vụ hấp dẫn"
                message={message}
                onHandleClick={() => handleOnChangeService()}
                required={
                    selected[KEY.SALON].id !== 0 &&
                    listChosenService.length === 0
                }
            />
            {listChosenService.length > 0 ? (
                <>
                    <div className="block__box">
                        <div className="mt-4">
                            {guideStep5 && (
                                <div className="mb-2.5 py-1.5 px-2.5 text-xs inline-flex item-center rounded-r-xl bg-green-eaf6ee">
                                    <div className="pr-1.5">{guideStep5}</div>
                                    <InfoCircleOutlined
                                        style={{
                                            fontSize: 16,
                                            color: '#EF4E24',
                                        }}
                                    />
                                </div>
                            )}
                            <div className="flex flex-wrap -mx-1.5">
                                {listChosenService.map((service, index) => (
                                    <div
                                        key={service.serviceId}
                                        className="mx-1.5 mb-2.5 rounded px-1.5 border border-gray-bababa text-sm cursor-default"
                                    >
                                        {service?.name}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="text-sm text-[#EF4E24]">
                        Tổng số tiền bạn cần thanh toán:{' '}
                        <span className="font-normal">
                            {formatPriceWithKCharacter(totalMoney)}
                        </span>
                    </div>
                </>
            ) : null}
        </div>
    );
};

export default Service;
