import { Dispatch } from 'redux';
import API from 'repository';
import { ROLE_SET } from '../actionTypes/role';
import { IStateRole } from '../reducers/role';

export type setRoleAction = {
    type: typeof ROLE_SET;
    role: IStateRole;
};

export const setRole = (value: IStateRole): setRoleAction => {
    return {
        type: ROLE_SET,
        role: value,
    };
};

export const fetchRole = (params: any) => async (dispatch: Dispatch) => {
    try {
        const res = await API.getRole(params);
        const data = res?.data || null;
        if (!data) return
        dispatch({
            type: ROLE_SET,
            role: {
                fetched: true,
                ...data,
                ...params,
            },
        });
    } catch {
        dispatch({
            type: ROLE_SET,
            role: null,
        });
    }
};

export type Action = setRoleAction;
