import React, { useEffect, useState, useRef } from 'react';
import { Button, Skeleton } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import Swiper from 'react-id-swiper';
import './styles.scss';
import arrowRight from 'images/icons/arrowRight.svg';
import vectorLeft from 'images/icons/Vector_left.svg';
import vectorRight from 'images/icons/Vector_right.svg';

const Product = ({ product }) => {
    const formatter = new Intl.NumberFormat('vi-VN', {
        style: 'currency',
        currency: 'VND',
        minimumFractionDigits: 0,
    });

    const handleClickDetail = () => {
        const link = `https://shop.30shine.com/chi-tiet-san-pham/${product.slug}`;
        window.open(link, '_blank');
    };

    return (
        <div className="top_product_v2_wrap">
            <div
                style={{ color: '#000' }}
                href={`https://shop.30shine.com/chi-tiet-san-pham/${product.slug}`}
                onClick={handleClickDetail}
            >
                <div className="top_product_content pointer">
                    <div className="top_product_image">
                        <img alt="img" src={product.featuredImage.url} />
                    </div>
                    <div className="top_product_des">
                        <p className="top_product_des_title">{product.name}</p>
                        <div className="flex item-baseline">
                            <p className="top_product_des_price_sale">
                                {product.price.from
                                    ? formatter.format(product.price.from)
                                    : formatter.format(product.price)}
                            </p>
                            <p className="top_product_des_price">
                                {product.listedPrice !== 0 &&
                                    product.isDiscount &&
                                    formatter.format(product.listedPrice)}
                            </p>
                        </div>
                    </div>
                    {/* <div
                                    // href={`https://30shinestore.com/products/${product.handle}`}
                                    // onClick={handleClickDetail}
                                    className="top_product_button_sell flex item-center space-between"
                                >
                                    <p className="top_product_button_sell_text">Mua ngay</p>
                                    <img src={arrowRight} alt=""></img>
                                </div> */}
                </div>
            </div>
        </div>
    );
};

const params = {
    spaceBetween: 48,
    slidesPerView: 3.7,
    // pagination: {
    //     el: '.swiper-pagination',
    //     type: 'progressbar',
    // },
    breakpoints: {
        1200: {
            spaceBetween: 20,
            slidesPerView: 5.5,
        },
        768: {
            spaceBetween: 24,
            slidesPerView: 3.7,
        },
        480: {
            spaceBetween: 10,
            slidesPerView: 2.5,
        },
        320: {
            spaceBetween: 10,
            slidesPerView: 2.5,
        },
        100: {
            spaceBetween: 10,
            slidesPerView: 1.1,
        },
    },
    freeMode: true,
};

const TopProduct = ({ listTopProduct }) => {
    const swiperRef = useRef(null);
    const [isFirstSlide, setIsFirstSlide] = useState(true);
    const [isLastSlide, setIsLastSlide] = useState(false);

    const goNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    const handleSlideChange = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            setIsFirstSlide(swiperRef.current.swiper.isBeginning);
            setTimeout(() => {
                setIsLastSlide(swiperRef.current.swiper.isEnd);
            }, 500);
        }
    };

    const classButtonNext = isLastSlide
        ? 'home_store_top_button home_store_top_right_button home_store_top_button_disable'
        : 'home_store_top_button home_store_top_right_button';
    const classButtonPrev = isFirstSlide
        ? 'home_store_top_button home_store_top_left_button home_store_top_button_disable'
        : 'home_store_top_button home_store_top_left_button';

    return (
        <div className="top-product-v2">
            <div className="home_store_top_content">
                {listTopProduct && listTopProduct.length ? (
                    <>
                        <Swiper
                            ref={swiperRef}
                            {...params}
                            on={{ slideChange: handleSlideChange }}
                        >
                            {listTopProduct.map((product) => (
                                <div key={product.id}>
                                    <Product product={product} />
                                </div>
                            ))}
                        </Swiper>
                        <Button
                            className={classButtonPrev}
                            shape="circle"
                            disabled={isFirstSlide}
                            onClick={goPrev}
                        >
                            <img src={vectorLeft} alt="left" />
                        </Button>
                        <Button
                            className={classButtonNext}
                            shape="circle"
                            disabled={isLastSlide}
                            onClick={goNext}
                        >
                            <img src={vectorRight} alt="right" />
                        </Button>
                    </>
                ) : (
                    <Skeleton active={true} />
                )}
            </div>
        </div>
    );
};

export default TopProduct;
