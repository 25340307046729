import { BANNER_SET } from '../actionTypes/banner';
import { IStateBanner } from '../reducers/banner';

export type setBannerAction = {
    type: typeof BANNER_SET;
    payload: IStateBanner;
};

export const setBanner = (value: IStateBanner): setBannerAction => {
    return {
        type: BANNER_SET,
        payload: value,
    };
};

export type Action = setBannerAction;
