import React, { FC, Fragment } from 'react';
import { diffTimeToday } from 'utils/BookingUltils';
import { ISalonSelected } from '../../types/Salon';

const SalonNew: FC<{ salon: ISalonSelected }> = ({ salon }) => {
    return (
        <>
            {salon.openingDate && diffTimeToday(salon.openingDate) && (
                <div className=" text-center padding-10">
                    <div className="h2" >
                        ĐẶT LỊCH CẮT TÓC ĐẸP TRAI TẠI
                    </div>
                    <div className="ti">
                        <div className="ab">
                            Salon <strong>{salon.name},</strong>
                        </div>
                        {salon.address && (
                            <div className="ab">
                                <strong>{salon.address}</strong>
                            </div>
                        )}
                        <div className="df">
                            Khai trương vào ngày{' '}
                            <strong>{salon.openingDate}</strong>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default SalonNew;
