import moment from 'moment';
import Client from './client/BookingClient';
import ClientMSA from './client/BookingClientMSA';
import ServiceMSA from './client/BookingMSA';
import StoreClient from './client/StoreClient';
import ClientCampaign from './client/CustomerCampaignHistoryClient';
import CustomerViewService from './client/CustomerViewService';

const resources = 'api';
const resourcesBooking = 'api-booking';
const resourcesBookingMSA = 'api/v1/booking';

export default {
    getAllSalonFilter(params) {
        return Client.get(`${resourcesBooking}/salon/salon-filter`, { params });
    },
    checkBooking(params) {
        return Client.get(`${resourcesBooking}/customer/check-book`, {
            params,
        });
    },
    getHousByDate(params) {
        const { listServiceId, ...ortherParam } = params;
        return Client.get(
            `${resourcesBooking}/fifteen-minutes/book-hours${
                listServiceId ? `?choosenServices=${listServiceId}` : ''
            }`,
            { params: ortherParam },
        );
    },

    getHoursGroupByDate(params) {
        const { listServiceId, ...ortherParam } = params;
        return ClientMSA.get(
            `/${resourcesBookingMSA}/book-hours-group${
                listServiceId ? `?choosenServices=${listServiceId}` : ''
            }`,
            { params: ortherParam },
        );
    },
    getAllProvince(params) {
        return Client.get(`${resourcesBooking}/city/list-city`, { params });
    },
    getDistrictById(params) {
        return Client.get(`${resourcesBooking}/district`, { params });
    },
    getSalonById(id) {
        return Client.get(`${resourcesBooking}/salon/${id}`);
    },
    // setVoucher(data) {
    //   return Client.post(`${resourcesBooking}/marketing-campaign/voucher`, data)
    // },
    checkCampaign(data) {
        return Client.post(
            `${resourcesBooking}/marketing-campaign/voucher`,
            data,
        );
    },
    booKing(data) {
        return Client.post(`${resourcesBooking}/fifteen-minutes/booking`, data);
    },
    booKingV2(data) {
        return ServiceMSA.post('/api/v1/booking-multiple', {
            ...data,
            dateBook: moment(data.dateBook, 'DD-MM-YYYY').format(),
        });
    },
    upDateBooKing(data) {
        return Client.put(
            `${resourcesBooking}/fifteen-minutes/booking/${data.id}`,
            data,
        );
    },
    // ____________________________________________________________api
    // api old home
    getShineTV(params) {
        return Client.get(`api-maketing/video`, { params });
    },
    // end api old home
    getServiceStep4(params) {
        return Client.get(`${resources}/combo/step4/service`, { params });
    },
    upDateServices(data) {
        return Client.put(`${resources}/combo/update-chosen-service`, data);
    },
    // _______________________________________________________________api
    deleteBooKing(id) {
        return ServiceMSA.delete(`${resourcesBookingMSA}/${id}`);
    },
    getInfoBooKing(params) {
        return ClientMSA.get(`${resourcesBookingMSA}`, { params });
    },
    checkOtp(data) {
        return ServiceMSA.put(`${resourcesBookingMSA}/verify-otp-booking`, data);
    },
    requesOtp(params) {
        return Client.get(`${resourcesBooking}/customer/phone/otp`, { params });
    },
    // GetSalonOff(params) {
    //     return Client.get(`${resourcesBooking}/salon/salon-off`, { params });
    // },
    GetSalonOff(params) {
        return ClientMSA.get(`${resourcesBookingMSA}/salon-off`, { params });
    },
    getAllStyList(params) {
        return Client.get(`${resourcesBooking}/stylist`, { params });
    },
    getAllStyListV4(params) {
        return ClientMSA.get(`${resourcesBookingMSA}/stylist-info`, { params });
    },
    getShineMember(params) {
        return Client.get(`${resourcesBooking}/customer/history-booking`, {
            params,
        });
    },
    buyShineMember(data) {
        return Client.post(`${resourcesBooking}/membership/order-v2`, data);
    },
    getShineMemberOrderId(params) {
        return Client.get(`${resourcesBooking}/membership/order`, { params });
    },
    // getRole(params) {
    //     return Client.get(
    //         `${resourcesBooking}/fifteen-minutes/booking-privacy-v3`,
    //         { params },
    //     );
    // },
    getRole(params) {
        return ClientMSA.get(`${resourcesBookingMSA}/booking-privacy`, {
            params,
        });
    },

    getSalonMap(params) {
        return Client.get(`${resourcesBooking}/salon/salon-map`, { params });
    },
    getSalonOpeningDate(params) {
        return Client.get(`${resourcesBooking}/salon/opening`, { params });
    },
    buyTopup(data) {
        return Client.post(`${resourcesBooking}/membership/order`, data);
    },
    getUser() {
        return CustomerViewService.get(`/api/v1/app-customer/check-registered`);
    },
    postAgreeProtectPersonalInfo(param) {
        return CustomerViewService.post(
            `/api/v1/app-customer/agree-protect-personal`,
            param,
        );
    },
    getTopup(params) {
        return Client.get(`${resourcesBooking}/product/topup`, { params });
    },
    historyTopup() {
        return Client.get(`${resourcesBooking}/customer/topup/history`);
    },
    billDetail(params) {
        return Client.get(`${resourcesBooking}/customer/topup/history`, {
            params,
        });
    },
    getListService(params) {
        return Client.get(`${resources}/combo/service-booking`, { params });
    },
    getListServiceV2(params) {
        return Client.get(`${resources}/combo/service-booking-v2`, { params });
    },
    getListServiceV3(params) {
        return Client.get(`${resources}/servicecombo/service-booking-v3`, {
            params,
        });
    },
    // getListServiceV4(params) {
    //     return Client.get(`${resources}/servicecombo/service-booking-v4`, {
    //         params,
    //     });
    // },
    getListServiceV4(params) {
        return ClientMSA.get(`/api/v1/service-booking/service-booking`, {
            params,
        });
    },
    // getListServiceV5(params) {
    //     return Client.get(`${resources}/servicecombo/service-booking-v6`, {
    //         params,
    //     });
    // },
    getListServiceV5(params) {
        return ClientMSA.get(`/api/v1/service-booking/service-booking-v2`, {
            params,
        });
    },
    // getDetailServiceV2(params) {
    //     return Client.get(`${resources}/servicecombo/service-booking/detail`, {
    //         params,
    //     });
    // },
    getDetailServiceV2(params) {
        return ClientMSA.get(`/api/v1/service-booking/detail`, {
            params,
        });
    },
    // getDetailServiceV3(params) {
    //     return Client.get(
    //         `${resources}/servicecombo/service-booking/detail-v3`,
    //         {
    //             params,
    //         },
    //     );
    // },
    getDetailServiceV3(params) {
        return ClientMSA.get(`/api/v1/service-booking/detail-v2`, {
            params,
        });
    },
    getDetailService(params) {
        return Client.get(`${resources}/combo/service-booking-detail`, {
            params,
        });
    },
    getListExtension(params) {
        return Client.get(`${resources}/combo/service-utility-booking`, {
            params,
        });
    },
    upDateExtension(data) {
        return Client.put(`${resources}/combo/add-chosen-service`, data);
    },
    getSalonNewFilter(params) {
        return Client.get(`${resourcesBooking}/v4/salon/get`, { params });
    },
    getCampaign(params) {
        return Client.get(`${resourcesBooking}/marketing-campaign`, { params });
    },
    getSalonBackup(params) {
        const { listServiceId, ...ortherParam } = params;
        return Client.get(
            `${resourcesBooking}/fifteen-minutes/salon-near/available-book?choosenServices=${listServiceId}`,
            { params: ortherParam },
        );
    },
    getProvinceStatic(params) {
        return StoreClient.get('/web/district.json', { params });
    },
    getSalonNewFilterStatic() {
        return StoreClient.get(`/web/all_salon.json?v=${Math.random()}`);
    },
    getSalonHasCame(params) {
        return Client.get(`${resourcesBooking}/salon/get-salon-near`, {
            params,
        });
    },
    // getSalonHasCameNew(params) {
    //     return Client.get(`${resourcesBooking}/salon/get-salon-near-v2`, {
    //         params,
    //     });
    // },
    getSalonHasCameNew(params) {
        return ClientMSA.get(`${resourcesBookingMSA}/salon-near`, { params });
    },
    getTopSalonStatic() {
        return StoreClient.get('/ResourceAPI/Booking/top_salon.json');
    },
    getListBookingInformation() {
        return ClientMSA.get(
            `${resourcesBookingMSA}/book-by-customer?brandName=MOYO`,
        );
    },
    getListBookHoursBackup(params) {
        return ClientMSA.get(`${resourcesBookingMSA}/book-hours-backup`, {
            params,
        });
    },
    // Booking at home
    getListProvince() {
        return Client.get(`${resourcesBooking}/salon/city`);
    },
    getListDistrictByProvinceId(provinceId) {
        return Client.get(`${resourcesBooking}/salon/district`, {
            params: {
                cityId: provinceId,
            },
        });
    },
    getListWardByDistrictId(districtId) {
        return Client.get(`${resourcesBooking}/salon/ward`, {
            params: {
                districtId,
            },
        });
    },
    // API lấy ưu đãi khách màn chọn dịch vụ
    getListCampaignBooking(params) {
        return ClientCampaign.post(`/api/v2/booking`, params);
    },
};
