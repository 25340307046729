import React, { FC, Fragment } from 'react';
import { Tag, notification } from 'antd';
import { IProvince } from '../../types/CityDistrict';

const ListProvince: FC<{
    list: IProvince[];
    onChange: Function;
    handleOnShowBox: Function;
}> = ({ list = [], onChange, handleOnShowBox }) => {
    const onClick = (item: IProvince) => {
        if (item.totalSalon > 0) {
            onChange(item);
            handleOnShowBox();
        } else {
            notification.error({
                message: 'Thông báo',
                description: 'Hiện tại không có salon nào ở đây',
                className: 'error',
                key: 'no-salon',
                duration: 3,
            });
        }
    };

    return (
        <div className="search__city">
            <div className="city">
                {list.length > 0 && (
                    <Fragment>
                        <div className="city__title">TỈNH THÀNH</div>
                        {list.map((item, key) => {
                            return (
                                <div
                                    key={`${key + 1}`}
                                    onClick={() => onClick(item)}
                                    className="city__item padding-10 flex space-between h2"
                                    role="presentation"
                                >
                                    <div className="item__name">
                                        {item.cityName}
                                    </div>
                                    <div className="flex item-center">
                                        {item.totalSalon === 0 ? (
                                            <Tag color="#E54D3E">
                                                Chưa có cơ sở
                                            </Tag>
                                        ) : (
                                            <Tag className="item__tag">
                                                {item.totalSalon} cơ sở
                                            </Tag>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </Fragment>
                )}
            </div>
        </div>
    );
};

export default ListProvince;
