import moment from 'moment';
import { ISelected } from 'pages/bookingNew/types/Selected';
import queryString from 'query-string';

export function diffTimeToday(value: string) {
    if (!value) return false;
    let count = 0;
    const time: any = value.split('-');
    const dateOpen = moment([time[2], time[1] - 1, time[0]]);

    const toDay = moment(new Date()).format('DD-MM-YYYY');
    const _time: any = toDay.split('-');
    const _toDay = moment([_time[2], _time[1] - 1, _time[0]]);

    count = dateOpen.diff(_toDay, 'days');
    if (count && count > -1) return count;
    return false;
}

const isMore = (a: number[], b: number[]) => {
    if (a[0] > b[0]) {
        return true;
    }
    if (a[0] === b[0] && a[1] >= b[1]) {
        return true;
    }
    return false;
};

export function ischeckTimePoints(item: string, start: string, end: string) {
    const hours = item.split('h');
    const hourItem = [Number(hours[0]), Number(hours[1])];
    const blockStart = [Number(start[0]), Number(start[1])];
    const blockEnd = [Number(end[0]), Number(end[1])];
    if (isMore(hourItem, blockStart) && isMore(blockEnd, hourItem)) {
        return true;
    }
}

export function setQuery(_selected: ISelected, ortherParams: any = {}) {
    const params: string = queryString.stringify({
        step: _selected.step,
        phone: _selected.customerPhone,
        salonId: _selected.salon.id,
        ...ortherParams,
    });
    return params;
}

export function getQuery(params: string) {
    const data: any = queryString.parse(params);
    if (data.step) {
        if (Number(data.step) > 2) {
            data.step = 2;
        } else {
            data.step = Number(data.step) > 0 ? Number(data.step) : 0;
        }
    }
    if (data.salonId) data.salonId = Number(data.salonId);
    if (
        data.source === 'customer_ios' &&
        Number(data.inApp) === 1 &&
        Number(data.salonId) === 20
    ) {
        data.salonId = 0;
    }
    return data;
}

export function getQueryBookingSingleScreen(params: string) {
    const data: any = queryString.parse(params);
    if (data.step) {
        if (Number(data.step) > 2) {
            data.step = 0;
        } else {
            data.step = Number(data.step) > 0 ? Number(data.step) : 0;
        }
    }
    if (data.salonId) data.salonId = Number(data.salonId);
    if (
        data.source === 'customer_ios' &&
        Number(data.inApp) === 1 &&
        Number(data.salonId) === 20
    ) {
        data.salonId = 0;
    }
    return data;
}

export function setQueryBookingAtHome(_selected: ISelected, ortherParams: any = {}) {
    const params: string = queryString.stringify({
        step: _selected.step,
        phone: _selected.customerPhone,
        ...ortherParams,
    });
    return params;
}

export function getQueryBookingAtHome(params: string) {
    const data: any = queryString.parse(params);
    if (data.step) {
        if (Number(data.step) > 2) {
            data.step = 0;
        } else {
            data.step = Number(data.step) > 2 ? 0 : Number(data.step);
        }
    }
    if (
        data.source === 'customer_ios' &&
        Number(data.inApp) === 1 &&
        Number(data.salonId) === 20
    ) {
        data.salonId = 0;
    }
    return data;
}

export function removeUnicodeChar(str: string) {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
    str = str.replace(/đ/g, 'd');
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
    str = str.replace(/Đ/g, 'D');
    // Combining Diacritical Marks
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // huyền, sắc, hỏi, ngã, nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // mũ â (ê), mũ ă, mũ ơ (ư)
    return str;
}

export const distanceBetween = (point1: any, point2: any) => {
    const dX = point2.longitude - point1.longitude;
    const dY = point2.latitude - point1.latitude;
    const distance = Math.sqrt(Math.pow(dX, 2) + Math.pow(dY, 2));

    return Math.round(distance * 1110) / 10;
};

export const removeDuplicateSalon = (especiallySalon: any) => {
    if (especiallySalon.length < 2) return especiallySalon;
    const listRemoveDuplicate = especiallySalon.filter(
        (salon: any, index: any, self: any) =>
            self.findIndex((item: any) => item.id === salon.id) === index,
    );
    return listRemoveDuplicate;
};

export const setCookieFunc = async (name: string, value: string, cookies: any, setCookies: Function)=>{
    if (cookies[name]) {
        return;
    }
    // Set expires 3 years
    const cookieExpires = new Date(
        new Date().getTime() + 3600 * 1000 * 24 * 365 * 3,
    );
    setCookies(name, value, {
        path: '/',
        expires: cookieExpires,
        domain: process.env.REACT_APP_BASE_URL,
        secure: true,
    });
};
