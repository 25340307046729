import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import Swiper from 'react-id-swiper';
import moment from 'moment';
import 'moment/locale/vi';
import { useHistory, Link } from 'react-router-dom';
import { notification, Button, Modal } from 'antd';
import classNames from 'classnames';
import { setBanner } from 'actions/banner';
import { setIsHandleMenuBooked } from 'actions/common';
import trackingConst from 'config/TrackingConst';
import iconCalendar from 'images/fillWhite/icon_calendar_2.svg';
import iconHome from 'images/fillWhite/icon_pin.svg';
import iconUser from 'images/fillWhite/icon_user_2.svg';
import close from 'images/icons/close.svg';
import close2 from 'images/icons/icon_close_2.svg';
import navigation from 'images/icons/icon_location.svg';
import vectorLeft from 'images/icons/Vector_left.svg';
import vectorRight from 'images/icons/Vector_right.svg';
import API from 'repository';
import { diffTimeToday } from 'components/func/Function';
import ModalConfirm from '../../bookingNew/components/ModalConfirm/ModalConfirm';
import './index.scss';

const Booking = ({ data, handleSelectBooking, postTracking }) => {
    const formatPhone = (customerPhone) => {
        if (customerPhone) return customerPhone.slice(-4);
        return '';
    };

    const formatTimeToNow = (day, time) => {
        const timeToNow = moment(`${day} ${time}`, 'YYYY-MM-DD HH:mm:ss');
        timeToNow.locale('vi');

        let current = moment();
        const dayDiff = timeToNow.diff(current, 'days');
        current = current.add(dayDiff, 'days');
        const hourDiff = timeToNow.diff(current, 'hours');
        current = current.add(hourDiff, 'hours');
        const minuteDiff = timeToNow.diff(current, 'minutes');

        let result = '';
        if (dayDiff) result += `${dayDiff} ngày `;
        if (hourDiff) result += `${hourDiff} giờ `;
        if (minuteDiff) result += `${minuteDiff} phút `;

        return result;
    };

    const formatTime = (day, time, isBookAtHome) => {
        const date = moment(day).format('DD-MM-YYYY');
        let diff = 0;
        if (diffTimeToday(date)) {
            diff = diffTimeToday(date);
        }
        let dayString = '';
        switch (diff) {
            case 0:
                dayString = 'Hôm nay, ';
                break;
            case 1:
                dayString = 'Ngày mai, ';
                break;
            case 2:
                dayString = 'Ngày kia, ';
                break;
            default:
                break;
        }
        const timeString = moment(`${day} ${time}`, 'YYYY-MM-DD HH:mm:ss');
        if (isBookAtHome)
            return `${dayString}${timeString.format(
                'dddd, [ngày] DD[.]MM',
            )}, ${time}`;

        return `${dayString}${timeString.format(
            'dddd, [ngày] DD[.]MM, HH[h]mm',
        )}`;
    };

    return (
        <div className="booking-info">
            <div className="content">
                <div className="time-to">
                    Chỉ còn{' '}
                    <span className="font-bold text-[#E74141]">
                        {formatTimeToNow(data.dateBook, data.hourBook)}
                    </span>{' '}
                    là đến lịch hẹn SĐT *{formatPhone(data.customerPhone)}
                    <span>
                        {' '}({data.numberPersons} người)
                    </span>
                </div>
                <div className="item">
                    <img src={iconCalendar} alt="" />
                    <span>
                        {formatTime(
                            data.dateBook,
                            data.hourBook,
                            data.isBookAtHome,
                        )}
                    </span>
                </div>
                <div className="item item-name">
                    <img src={iconHome} alt="" />
                    <span>{data.salonAddress || data.salonName}</span>
                </div>
                {/* <div className="item">
                    <img src={iconUser} alt="" />
                    <span>
                        {data.stylistName || 'Salon sẽ chọn stylist phù hợp'}
                    </span>
                </div> */}
            </div>
            <div className="action">
                {data.canceled ? (
                    <a
                        className="button button-disable"
                        title="Lịch đặt đã hủy"
                    >
                        Lịch đặt đã hủy
                    </a>
                ) : (
                    <>
                        <a
                            className="button button-cancel"
                            onClick={() => handleSelectBooking(data)}
                            role="presentation"
                        >
                            <img src={close2} alt="" />
                            Đổi/Hủy lịch
                        </a>
                        {!data.isBookAtHome ? (
                            <a
                                className="button"
                                href={data.salonMap}
                                target="_blank"
                                rel="noreferrer"
                                onClick={() =>
                                    postTracking(
                                        trackingConst.Events.CLICK_DIRECT_SALON,
                                    )
                                }
                            >
                                <img src={navigation} alt="" />
                                Chỉ đường Salon
                            </a>
                        ) : (
                            ''
                        )}
                        {data.parkingMap && (
                            <a
                                className="button"
                                href={data.parkingMap}
                                target="_blank"
                                rel="noreferrer"
                                onClick={() =>
                                    postTracking(
                                        trackingConst.Events.CLICK_DIRECT_OTO,
                                    )
                                }
                            >
                                Chỉ chỗ đậu ô tô
                            </a>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

const BookingInformation = ({
    trackingData,
    userPhone,
    isHandleMenuBooked,
    setIsHandleMenuBooked,
}) => {
    const paramsSwiper = {
        spaceBetween: 18,
        slidesPerView: 2,
        breakpoints: {
            768: {
                slidesPerView: 2,
            },
            0: {
                slidesPerView: 1,
            },
        },
        freeMode: true,
    };

    const history = useHistory();
    const [listBookingInformation, setListBookingInformation] = useState([]);
    const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
    const [bookingSelected, setBookingSelected] = useState(null);
    const [isFirstSlide, setIsFirstSlide] = useState(true);
    const [isLastSlide, setIsLastSlide] = useState(false);
    const [
        isShowModalRedirectBooking,
        setIsShowModalRedirectBooking,
    ] = useState(false);
    const swiperRef = useRef(null);
    const [loading, setLoading] = useState(false);

    const getListBookingInformation = async () => {
        let response = null;
        try {
            if (userPhone) response = await API.getListBookingInformation();
            setListBookingInformation(response);
            if (response.length === 0 && isHandleMenuBooked) {
                setIsShowModalRedirectBooking(true);
                setIsHandleMenuBooked(false);
            }
        } catch (error) {
            console.log(error);
        }
        return response;
    };

    async function postTracking(eventId) {
        try {
            const dataTracking = {
                pageId: trackingConst.Pages.HOME_PAGE,
                screenId: trackingConst.Screens.HOME_SCREEN,
                eventId,
                tokenKey: trackingData.token,
                source: trackingData.source,
                utmSource: trackingData.utmSource,
            };
            API.postTrackingDynamic(dataTracking);
        } catch (e) {
            console.log(e.message);
        }
    }

    const handleSlideChange = () => {
        setIsFirstSlide(swiperRef.current.swiper.isBeginning);
        setTimeout(() => {
            setIsLastSlide(swiperRef.current.swiper.isEnd);
        }, 500);
    };

    const handleTouchChange = () => {
        setIsFirstSlide(swiperRef.current.swiper.isBeginning);
        setIsLastSlide(swiperRef.current.swiper.isEnd);
    };

    const handleSlideInit = () => {
        setIsFirstSlide(swiperRef.current.swiper.isBeginning);
        setIsLastSlide(swiperRef.current.swiper.isEnd);
    };

    const goNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
            setIsFirstSlide(swiperRef.current.swiper.isBeginning);
            setIsLastSlide(swiperRef.current.swiper.isEnd);
        }
    };

    const goPrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
            setIsFirstSlide(swiperRef.current.swiper.isBeginning);
            setIsLastSlide(swiperRef.current.swiper.isEnd);
        }
    };

    const classButtonPrev = classNames('swiper_button button_prev', {
        swiper_button_disable: isFirstSlide,
    });

    const classButtonNext = classNames('swiper_button button_next', {
        swiper_button_disable: isLastSlide,
    });

    const upDateBooking = () => {
        if (bookingSelected.isBookAtHome) {
            history.push(
                `/booking-at-home?phone=${bookingSelected.customerPhone}&step=0`,
            );
        } else {
            history.push(
                `/booking?phone=${bookingSelected.customerPhone}&salonId=0&step=0`,
            );
        }
    };

    const handleSelectBooking = (booking) => {
        setBookingSelected(booking);
        setIsShowModalConfirm(true);

        // tracking
        postTracking(trackingConst.Events.CLICK_CHANGE_CANCEL_BOOKING);
    };

    const handleCloseModal = () => {
        setIsShowModalConfirm(false);
        setBookingSelected(null);
    };

    async function deleteBooKing() {
        if (loading) return;
        try {
            setLoading(true);
            await API.deleteBooKing(bookingSelected.bookingId);

            const config = {
                message: 'Hủy Lịch',
                description: 'Hủy Lịch Thành Công!',
                duration: 0.3,
                onClose: () => {
                    handleCloseModal();
                },
                className: 'success',
            };
            const list = [...listBookingInformation].map((item) => {
                if (item.bookingId === bookingSelected.bookingId) {
                    item.canceled = true;
                }
                return item;
            });
            setListBookingInformation(list);
            notification.success(config);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            const config = {
                message: 'Hủy Lịch',
                description: 'Lịch Không Tồn Tại!',
                duration: 0.5,
                onClose: () => {
                    getListBookingInformation();
                },
            };
            notification.warning(config);
        }
    }

    useEffect(() => {
        if (userPhone) {
            getListBookingInformation();
        } else {
            setListBookingInformation([]);
        }
    }, [userPhone]);

    return (
        <>
            {listBookingInformation?.length > 0 && (
                <div className="list-booking-infomation">
                    <div className="container">
                        {/* <div className="title">
                            Lịch đặt của bạn ({listBookingInformation.length}){' '}
                        </div> */}
                        <div className="relative">
                            <Swiper
                                {...paramsSwiper}
                                ref={swiperRef}
                                on={{
                                    slideChange: handleSlideChange,
                                    touchMove: handleTouchChange,
                                    init: handleSlideInit,
                                }}
                            >
                                {listBookingInformation.map(
                                    (booking, index) => (
                                        <div
                                            key={booking.bookingId}
                                            className={
                                                booking.canceled
                                                    ? 'booking-canceled'
                                                    : ''
                                            }
                                        >
                                            <Booking
                                                data={booking}
                                                handleSelectBooking={
                                                    handleSelectBooking
                                                }
                                                postTracking={postTracking}
                                            />
                                            <div className="swiper-pagination">
                                                {index + 1}/
                                                {listBookingInformation.length}
                                            </div>
                                        </div>
                                    ),
                                )}
                            </Swiper>
                            {listBookingInformation.length > 2 && (
                                <>
                                    <Button
                                        className={classButtonPrev}
                                        shape="circle"
                                        disabled={isFirstSlide}
                                        onClick={goPrev}
                                    >
                                        <img src={vectorLeft} alt="left" />
                                    </Button>
                                    <Button
                                        className={classButtonNext}
                                        shape="circle"
                                        disabled={isLastSlide}
                                        onClick={goNext}
                                    >
                                        <img src={vectorRight} alt="right" />
                                    </Button>
                                </>
                            )}
                        </div>
                        <ModalConfirm
                            className="modal-delete-booking"
                            visible={isShowModalConfirm}
                            title="HỦY LỊCH ĐẶT"
                            onCancel={handleCloseModal}
                        >
                            <span>
                                Bạn skinner đã sẵn sàng để phục vụ bạn, bạn chắc chắn muốn
                                hủy lịch chứ?
                            </span>
                            <div
                                className="right h2 "
                                onClick={upDateBooking}
                                role="presentation"
                            >
                                <span style={{ fontWeight: 'bold' }}>
                                    ĐỔI LỊCH
                                </span>
                            </div>
                            <div
                                className="left h2"
                                onClick={deleteBooKing}
                                role="presentation"
                            >
                                <span style={{ fontWeight: 'bold' }}>
                                    CHẮC CHẮN HỦY LỊCH
                                </span>
                            </div>
                        </ModalConfirm>
                    </div>
                </div>
            )}

            <Modal
                className="modal-redirect-to-booking"
                width={450}
                visible={isShowModalRedirectBooking}
                footer={null}
                destroyOnClose
                zIndex={1001}
                closeIcon={<img src={close} alt="" />}
                onCancel={() => setIsShowModalRedirectBooking(false)}
            >
                <div className="content">
                    <div className="title">BẠN CHƯA CÓ LỊCH ĐÃ ĐẶT</div>
                    <div>
                        Bạn đặt lịch ngay để trải nghiệm dịch vụ của chúng em
                        nhé!
                    </div>
                </div>
                <div className="btn">
                    <Link
                        to={`/booking?phone=${userPhone}&salonId=0&step=0`}
                        className="btn-color-1"
                        title="Đặt lịch ngay"
                    >
                        Đặt lịch ngay
                    </Link>
                </div>
            </Modal>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        trackingData: state.trackingData,
        userPhone: state.user.phoneNumber,
        isHandleMenuBooked: state.common.isHandleMenuBooked,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setDataBanner: (value) => dispatch(setBanner(value)),
        setIsHandleMenuBooked: (value) =>
            dispatch(setIsHandleMenuBooked(value)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingInformation);
