import ClientBooking from './client/BookingClient';
import CustomerCampaignHistoryClient from './client/CustomerCampaignHistoryClient';

export default {
    getPrivateReward() {
        return ClientBooking.get(`api-booking/marketing-campaign/reward`);
    },
    getReward(date) {
        return CustomerCampaignHistoryClient.get(
            `/v2/api/campaign-customer/reward?dateBook=${date}`,
        );
    },
    postCode(params) {
        return CustomerCampaignHistoryClient.post(
            '/api/campaign-customer/one-phone-voucher',
            params,
        );
    },
    postGetCampaign(params) {
        return CustomerCampaignHistoryClient.post(
            '/api/campaign-customer/one-phone-campaign',
            params,
        );
    },
    postTakeCampaign(params) {
        return CustomerCampaignHistoryClient.post(
            '/api/campaign-customer/reward',
            params,
        );
    },
    getRewardDetail(params) {
        return CustomerCampaignHistoryClient.get(
            `/v2/api/campaign-customer/detail`,
            { params },
        );
    },
};
