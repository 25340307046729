import React from 'react';
import { Modal, Button } from 'antd';
import { useCookies } from 'react-cookie';
import './index.css';
import { useHistory } from 'react-router-dom';

const ModalPromo: React.FC<{
    isModalOpen: boolean;
    setIsModalOpen: Function;
}> = ({ isModalOpen, setIsModalOpen }) => {
    const [cookies, setCookie] = useCookies(['isViewModalAdsPromo']);
    const history = useHistory();

    const clickModal = () => {
        // Set expires 3 years
        const cookieExpires = new Date(
            new Date().getTime() + 3600 * 1000 * 24 * 365 * 3,
        );
        setCookie('isViewModalAdsPromo', true, {
            expires: cookieExpires,
        });
        setIsModalOpen(false);
        history.push('/discovers/85');
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <>
            <Modal
                style={{
                    borderRadius: '10px',
                    padding: 0,
                    overflow: 'hidden',
                }}
                visible={isModalOpen}
                closable
                onCancel={handleCancel}
                maskClosable={false}
                footer={null}
                width={362}
            >
                <div className="modal-promo-container">
                    <div className="frame">
                        <div className="frame-1">
                            <div className="isolation-mode">
                                <div className="vector" />
                                <div className="vector-2" />
                                <div className="vector-3" />
                                <div className="vector-4" />
                                <div className="max-discount">
                                    <span className="discount-percent">
                                        GIẢM 30% <br />
                                    </span>
                                    <span className="max-50k-total-bill">
                                        TỐI ĐA 50K <br />
                                        CHO TỔNG HOÁ ĐƠN
                                    </span>
                                </div>
                            </div>
                            <span className="apply-next-moyo-gift">
                                Áp dụng cho lần dùng sau
                                <br />
                                Quà tặng khi qua MOYO dùng dịch vụ bất kỳ
                            </span>
                        </div>
                        <Button onClick={clickModal} className="button-5">
                            <span className="view-details">Xem chi tiết</span>
                        </Button>
                    </div>
                    <div className="rectangle" />
                </div>
                <div />
            </Modal>
        </>
    );
};

export default ModalPromo;
