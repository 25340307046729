import React, { FC, useEffect, useRef, useState } from 'react';
import useWindowSize from 'hooks/useWindowSize';
import Swiper from 'react-id-swiper';
import { Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import '../index.scss';
import LayoutHeadTitle from '../headTitle';
import RedirectLink from '../redirectLink';
import ic_book from 'images/icons/ic_book_home.png';
import ic_facebook from 'images/icons/ic_facebook_home.png';
import CommonPicture from 'components/common/Picture';
import vectorLeft from 'images/icons/Vector_left.svg';
import vectorRight from 'images/icons/Vector_right.svg';

const Layout30shineAngels: FC<{ data: any }> = ({ data }) => {
    const swiperRef = useRef<any>(null);
    const [isFirstSlide, setIsFirstSlide] = useState<boolean>(true);
    const [isLastSlide, setIsLastSlide] = useState<boolean>(false);
    const [list, setList] = useState(data);
    const size: any = useWindowSize();
    const isMobile = size.width < 768;

    const paramsSwiper = {
        spaceBetween: 30,
        breakpoints: {
            600: {
                slidesPerView:
                    data.slidePerViewPC > 0 ? data.slidePerViewPC : 4,
                spaceBetween: 30,
            },
            0: {
                slidesPerView:
                    data.slidePerViewMB > 0 ? data.slidePerViewMB : 2.5,
                spaceBetween: 16,
            },
        },
        freeMode: true,
    };

    const handleSlideChange = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            setIsFirstSlide(swiperRef.current.swiper.isBeginning);
            setTimeout(() => {
                setIsLastSlide(swiperRef.current.swiper.isEnd);
            }, 500);
        }
    };

    const handleTouchChange = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            setIsFirstSlide(swiperRef.current.swiper.isBeginning);
            setIsLastSlide(swiperRef.current.swiper.isEnd);
        }
    };

    const handleSlideInit = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            setIsFirstSlide(swiperRef.current.swiper.isBeginning);
            setIsLastSlide(swiperRef.current.swiper.isEnd);
        }
    };

    const goNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
            setIsFirstSlide(swiperRef.current.swiper.isBeginning);
            setIsLastSlide(swiperRef.current.swiper.isEnd);
        }
    };

    const goPrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
            setIsFirstSlide(swiperRef.current.swiper.isBeginning);
            setIsLastSlide(swiperRef.current.swiper.isEnd);
        }
    };

    const classButtonPrev = classNames('swiper_button button_prev', {
        swiper_button_disable: isFirstSlide,
    });

    const classButtonNext = classNames('swiper_button button_next', {
        swiper_button_disable: isLastSlide,
    });

    const isShowButtonSwiper =
        data.list.length > Math.round(data.slidePerViewPC);

    const getRandomInt = (min: number, max: number) => {
        return Math.floor(Math.random() * (max - min + 1) + min);
    };

    useEffect(() => {
        if (data && data?.list.length) {
            // auto change position list data
            let newData = [...data.list];
            for (let index = 0; index < newData.length; index++) {
                if (
                    (index > data.numberPermanent - 1 &&
                        data.numberPermanent > 0) ||
                    data.numberPermanent == 0
                ) {
                    const insertToIndex = getRandomInt(
                        data.numberPermanent,
                        newData.length - 1,
                    );
                    const cutOutItem = newData.splice(index, 1)[0];
                    newData.splice(insertToIndex, 0, cutOutItem);
                }
            }
            setList(newData);
        }
    }, [data]);

    return (
        <div className="layout layout-blog">
            <div className="container">
                <LayoutHeadTitle data={data} />
                <div className="layout__blog">
                    {list.length > 0 && (
                        <>
                            <Swiper
                                ref={swiperRef}
                                {...paramsSwiper}
                                on={{
                                    init: handleSlideInit,
                                    touchMove: handleTouchChange,
                                    slideChange: handleSlideChange,
                                }}
                            >
                                {list.map((item: any) => {
                                    return (
                                        <div
                                            className="blog relative"
                                            key={item.id}
                                        >
                                            <div className="relative">
                                                <RedirectLink
                                                    redirect={item.redirectLink}
                                                >
                                                    <div className="blog__media">
                                                        <CommonPicture
                                                            link={
                                                                isMobile &&
                                                                item.linkImageMb
                                                                    ? item.linkImageMb
                                                                    : item.linkImage
                                                            }
                                                            width-string="-480x480"
                                                            alt="combo service"
                                                        />
                                                    </div>
                                                </RedirectLink>
                                                {/* <div className="flex flex-wrap space-x-1 absolute bottom-2 left-0 px-2 justify-center w-full">
                                                    <RedirectLink
                                                        redirect={
                                                            item.redirectLink
                                                        }
                                                    >
                                                        <div className="mt-1 flex items-center bg-white text-black hover:bg-yellow-300 rounded py-1.5 px-2">
                                                            <img
                                                                src={ic_book}
                                                                alt=""
                                                                className="w-4 h-4"
                                                            />
                                                            <div className="text-xs md:text-sm font-semibold pl-1">
                                                                Book lịch ngay
                                                            </div>
                                                        </div>
                                                    </RedirectLink>
                                                    <RedirectLink
                                                        redirect={item.groupFB}
                                                    >
                                                        <div className="mt-1 flex items-center bg-white text-black hover:bg-yellow-300 rounded py-1.5 px-2">
                                                            <img
                                                                src={
                                                                    ic_facebook
                                                                }
                                                                alt=""
                                                                className="w-4 h-4"
                                                            />
                                                            <div className="text-xs md:text-sm font-semibold pl-1">
                                                                Vào group salon
                                                            </div>
                                                        </div>
                                                    </RedirectLink>
                                                </div> */}
                                            </div>
                                            <RedirectLink
                                                redirect={item.redirectLink}
                                            >
                                                <div className="blog__title">
                                                    {item.title}
                                                </div>
                                                {item.subTitle ? (
                                                    <div className="blog__subTitle">
                                                        {item.subTitle}
                                                    </div>
                                                ) : null}
                                            </RedirectLink>
                                        </div>
                                    );
                                })}
                            </Swiper>
                            {isShowButtonSwiper && (
                                <>
                                    <Button
                                        className={classButtonPrev}
                                        shape="circle"
                                        disabled={isFirstSlide}
                                        onClick={goPrev}
                                    >
                                        <img src={vectorLeft} alt="left" />
                                    </Button>
                                    <Button
                                        className={classButtonNext}
                                        shape="circle"
                                        disabled={isLastSlide}
                                        onClick={goNext}
                                    >
                                        <img src={vectorRight} alt="right" />
                                    </Button>
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Layout30shineAngels;
