import axios from 'axios';

const baseDomain = process.env.REACT_APP_PRODUCT;

const instance = axios.create({
    baseURL: baseDomain
});

export default {
    getTopProduct() {
        return instance.get(
            `/Prod/api/haravan/favourite-product?collection_id=1002437805&fields=id,handle,images,product_type,title,variants`
        );
    },

    // TODO: update product group id
    getShopProduct() {
        return instance.get(
            `/api/v1/web/product-groups/616f79b510429c001dd42156/products`
        );
    },

    getNewProduct() {
        return instance.get(
            '/Prod/api/haravan/new-product?collection_id=1002437807&fields=id,handle,images,product_type,title,variants'
        );
    },
};
