import React, {FC, useEffect, useRef} from 'react';
import { Input, Form } from 'antd';
import iconPhone from 'images/icons/phone.svg';
import './styles.scss';

interface InputPhoneProps {
    value: any;
    handlePressEnter: Function;
    handleChangePhone: Function;
    handlePaste?: Function;
    error?: string;
}

const InputPhone: FC<InputPhoneProps> = ({
    value,
    handlePressEnter,
    handleChangePhone,
    handlePaste = () => {},
    error = '',
    ...props
}) => {
    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef) {
            // @ts-ignore
            inputRef.current.focus();
        }
    }, []);

    const classInput = error ? 'input_phone error_input' : 'input_phone';
    const statusForm = error ? 'error' : 'success';
    return (
        <Form.Item validateStatus={statusForm} help={error} className="content_input_phone">
            <Input
                prefix={
                    <div className='icon_phone'>
                        <img src={iconPhone} alt='Phone' />
                    </div>
                }
                ref={inputRef}
                type='tel'
                className={classInput}
                placeholder='VÍ DỤ: 0905 939 XXX'
                value={value}
                onChange={(e) => handleChangePhone(e)}
                onPressEnter={() => handlePressEnter()}
                onPaste={() => handlePaste()}
                {...props}
            />
        </Form.Item>
    );
};

export default InputPhone;
