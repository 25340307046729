export default {
    HOME_SCREEN: 'HOME_SCREEN',
    NEW_HOME_SCREEN: 'NEW_HOME_SCREEN',
    BOOKING_SELECT_SALON_SCREEN: 'BOOKING_SELECT_SALON_SCREEN',
    BOOKING_SELECT_SERVICE_SCREEN: 'BOOKING_SELECT_SERVICE_SCREEN',
    BOOKING_SELECT_STYLIST_TIME_SCREEN: 'BOOKING_SELECT_STYLIST_TIME_SCREEN',
    BOOKING_SELECT_EXTENSION_SCREEN: 'BOOKING_SELECT_EXTENSION_SCREEN',
    BOOKING_SUCCESS_SCREEN: 'BOOKING_SUCCESS_SCREEN',
    BOOKING_SAVING_LOCATION: 'BOOKING_SAVING_LOCATION',
    DISCOVER_SCREEN: 'DISCOVER_SCREEN',
    STYLE_MASTER_SCREEN: 'STYLE_MASTER_SCREEN',
    SYSTEM_SALON_SCREEN: 'SYSTEM_SALON_SCREEN',
    SALON_DETAIL_SCREEN: 'SALON_DETAIL_SCREEN',
    COMMON_SCREEN: 'COMMON_SCREEN',
    LOGIN_SCREEN: 'LOGIN_SCREEN',
    // Booking single screen
    BOOKING_SELECT_BOOKING_SINGLE_SCREEN:
        'BOOKING_SELECT_BOOKING_SINGLE_SCREEN',
    BOOKING_SELECT_DETAIL_SERVICE_SCREEN:
        'BOOKING_SELECT_DETAIL_SERVICE_SCREEN',
    LANDINGPAGE_30SHINESPA_HIGHTECH: 'LANDINGPAGE_30SHINESPA_HIGHTECH',
    LANDINGPAGE_30SHINESPA_LIGHTSMOOTH: 'LANDINGPAGE_30SHINESPA_LIGHTSMOOTH',
    LANDINGPAGE_30SHINESPA_DRFORSKIN: 'LANDINGPAGE_30SHINESPA_DRFORSKIN',
    LANDINGPAGE_30SHINESPA_NANOTECH: 'LANDINGPAGE_30SHINESPA_NANOTECH',
    LANDINGPAGE_30SHINESPA_SYSTEM: 'LANDINGPAGE_30SHINESPA_SYSTEM',

    BOOKING_CAMPAIGN_SCREEN: 'BOOKING_CAMPAIGN_SCREEN',
    BOOKING_CAMPAIGN_DETAIL_SCREEN: 'BOOKING_CAMPAIGN_DETAIL_SCREEN',
};
