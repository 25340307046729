import Client from './client/SavingLocationClient';
import CustomerViewService from './client/CustomerViewService';

const resources = 'api';

export default {
    getSavingLocation(id) {
        return CustomerViewService.get(
            `api/v1/app-customer/info?customerId=${id}`,
        );
    },

    updateSavingLocation(params) {
        const { customerId, ...otherParams } = params;
        return CustomerViewService.put(
            `/api/v1/app-customer/customer`,
            otherParams,
        );
    },

    getListCustomerAddress(phoneNumber) {
        return Client.get(
            `${resources}/booking-info/attribute?phoneNumber=${phoneNumber}`,
        );
    },

    getListSalonFilterCustomerAddress(phoneNumber, attrId, limit) {
        return Client.get(
            `${resources}/booking-info/salon-near-location?phoneNumber=${phoneNumber}&attrId=${attrId}&numOfSalon=${limit}`,
        );
    },

    saveCustomerName({ phone, name }) {
        const params = {
            name,
            phone,
        };
        return CustomerViewService.put(`api/v1/booking/customer`, params);
    },
};
