import Client from './client/AuthenticClient';
import CustomerViewServiceClient from './client/CustomerViewService';

const brandName = process.env.REACT_APP_BRAND_NAME;

export default {
    getOpt(data) {
        return Client.post(`otp/send`, { ...data, brandName });
    },
    validateOpt(data) {
        return CustomerViewServiceClient.post(`api/v1/auth/login-otp`, {
            ...data,
            brandName,
        });
    },
    checkIsNewLogin(data) {
        return CustomerViewServiceClient.post(`api/v1/auth/verify`, {
            ...data,
            brandName,
        });
    },
    login(data) {
        return CustomerViewServiceClient.post(`api/v1/auth/login`, {
            ...data,
            brandName,
        });
    },
    changePassword(data) {
        return CustomerViewServiceClient.post(`api/v1/auth/change-password`, {
            ...data,
            brandName,
        });
    },
};
