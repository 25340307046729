import { Reducer } from 'redux';
import {
    COMMON_ADD_LOADING,
    COMMON_REMOVE_LOADING,
    COMMON_SET_SPIN,
    COMMON_SET_SHOW_MODAL_LOGIN,
    COMMON_SET_TITLE_MODAL_LOGIN,
    COMMON_SET_IS_CLICK_MENU_BOOKED,
    COMMON_SET_IS_INAPP,
    COMMON_SET_LANGUAGE,
} from '../actionTypes/common';
import { Action } from '../actions/common';

export type IStateCommon = {
    isLoading: number;
    showSpin: boolean;
    showModalLogin: boolean;
    modalLoginTitle: any;
    isHandleMenuBooked: boolean;
    inApp: boolean;
    lang: string;
};

const initialState: IStateCommon = {
    isLoading: 0,
    showSpin: false,
    showModalLogin: false,
    modalLoginTitle: '',
    isHandleMenuBooked: false,
    inApp: false,
    lang: 'vi',
};

const common: Reducer<IStateCommon, Action> = (
    state = initialState,
    action: Action,
) => {
    switch (action.type) {
        case COMMON_ADD_LOADING: {
            return { ...state, isLoading: state.isLoading + 1 };
        }

        case COMMON_REMOVE_LOADING: {
            return {
                ...state,
                isLoading: state.isLoading > 0 ? state.isLoading - 1 : 0,
            };
        }

        case COMMON_SET_SPIN: {
            return {
                ...state,
                showSpin: action.value,
            };
        }

        case COMMON_SET_SHOW_MODAL_LOGIN: {
            return {
                ...state,
                showModalLogin: action.isShow,
            };
        }

        case COMMON_SET_TITLE_MODAL_LOGIN: {
            return {
                ...state,
                modalLoginTitle: action.title,
            };
        }

        case COMMON_SET_IS_CLICK_MENU_BOOKED: {
            return {
                ...state,
                isHandleMenuBooked: action.value,
            };
        }

        case COMMON_SET_IS_INAPP: {
            return {
                ...state,
                inApp: action.value,
            };
        }

        case COMMON_SET_LANGUAGE: {
            return {
                ...state,
                lang: action.value,
            };
        }

        default:
            return state;
    }
};

export default common;
