import React, { useEffect, useState } from 'react';
import { Modal, Checkbox, Button } from 'antd';
import { useCookies } from 'react-cookie';
import Link from 'antd/lib/typography/Link';
import { getQuery } from 'utils/BookingUltils';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import API from 'repository';

const ModalConfirmPolicy: React.FC<{
    isModalOpen: boolean;
    setIsModalOpen: Function;
    redirectBooking: Function;
    bookingPhone: number;
}> = ({ isModalOpen, setIsModalOpen, redirectBooking, bookingPhone }) => {
    const [cookies, setCookie] = useCookies(['bookingPhone']);
    const [checked, setChecked] = useState(true);
    const common = useSelector((state: any) => state.common);

    const clickModal = () => {
        if (!checked) return;
        // Set expires 3 years
        const cookieExpires = new Date(
            new Date().getTime() + 3600 * 1000 * 24 * 365 * 3,
        );
        if (bookingPhone) {
            setCookie('bookingPhone', bookingPhone, {
                expires: cookieExpires,
            });
            postPhoneNumberCheck(bookingPhone)
        }
        setIsModalOpen(false);
        redirectBooking();
    };

    const postPhoneNumberCheck = async (bookingPhone: number) => {
        try {
            const response = await API.postAgreeProtectPersonalInfo(
                {
                    phone: bookingPhone,
                    brandName: "MOYO"
                }
            );
        } catch (error) { }
    };

    // useEffect(() => {
    //     if (
    //         cookies?.security_policy === 'true' ||
    //         getQuery(window.location.search).inApp === '1' ||
    //         common.inApp
    //     ) {
    //         setIsModalOpen(false);
    //     } else {
    //         setIsModalOpen(true);
    //     }
    // }, []);
    const handleCancel = () => {
        setIsModalOpen(false);
        redirectBooking();
    };
    return (
        <>
            <Modal
                className="h-full modal_sercurity"
                style={{ borderRadius: '12px !important' }}
                visible={isModalOpen}
                closable={true}
                onCancel={handleCancel}
                maskClosable={false}
            >
                <div className="label" style={{ marginBottom: 15 }}>
                    <span className='mx-2'>
                        Xin phép sử dụng số điện thoại để đặt lịch
                    </span>
                </div>
                <div className="flex-1 overflow-y-auto content">
                    <p>
                        Moyo trân trọng thông báo chúng tôi đã cập nhật Chính sách
                        xử lý dữ liệu cá nhân và bảo mật thông tin người dùng theo
                        các quy định mới nhất của Nghị định số 13/2023/NĐ-CP về
                        Bảo vệ dữ liệu cá nhân.
                    </p>
                    <p>
                        Trong quá trình cung cấp dịch vụ,
                        sản phẩm tới Quý Khách hàng, Moyo cần xử lý dữ liệu cá nhân
                        của người dùng để có thể cung cấp dịch vụ một cách tốt.
                        Moyo luôn tôn trọng quyền riêng tư và dữ liệu cá nhân của tất
                        cả người dùng theo quy định của pháp luật Việt Nam.
                    </p>
                    <p>
                        Nội dung đầy đủ của Chính sách xử lý dữ liệu
                        cá nhân và bảo mật thông tin người dùng mới
                        được diễn giải chi tiết tại đây:
                    </p>
                    <p>
                        <ul
                            style={{
                                listStyleType: 'disc',
                                paddingLeft: 25,
                                marginBottom: 8,
                            }}
                        >
                            <li>
                                <strong>
                                    Chính sách xử lý dữ liệu cá nhân và bảo mật
                                    thông tin người dùng{' '}
                                    <Link
                                        href="/chinh-sach-bao-mat"
                                        target="_blank"
                                    >
                                        (link chi tiết)
                                    </Link>
                                    <br />
                                </strong>
                            </li>
                        </ul>
                        <p>
                            Ngoài ra, để đảm bảo chất lượng phục vụ và tăng sự
                            trải nghiệm cho khách hàng, Moyo xin phép được ghi
                            âm/ghi hình quá trình phục vụ của nhân sự. Toàn bộ
                            thông tin ghi âm. Các bản ghi âm và ghi hình sẽ được
                            bảo mật và chỉ được truy cập bởi những nhân viên cần thiết.
                        </p>
                        <p>
                            Việc chấp nhận nội dung điều chỉnh,
                            cập nhật này là điều kiện để Moyo
                            có thể tiếp tục cung cấp dịch vụ và
                            đảm bảo môi trường an toàn cũng như
                            quyền lợi cá nhân của tất cả người sử dụng.
                        </p>
                    </p>
                    <p>
                        Nếu có thắc mắc hoặc yêu cầu thêm thông tin,
                        xin hãy liên hệ với chúng tôi qua Hotline: <strong>1900.299.993</strong>.
                    </p>
                    <p>Chúng tôi luôn sẵn lòng hỗ trợ.</p>
                    <p> Trân trọng.</p>
                </div>
                <div className="group_checkbox">
                    <Checkbox
                        checked={checked}
                        onChange={(e) => setChecked(e.target.checked)}
                    >
                        Tôi đã đọc và đồng ý với{' '}
                        <strong>
                            Chính sách xử lý dữ liệu cá nhân và bảo mật thông
                            tin người dùng
                        </strong>{' '}
                        của Moyo
                    </Checkbox>
                </div>
                <div>
                    <Button
                        onClick={clickModal}
                        className={`button_submit ${checked ? 'active' : ''}`}
                    >
                        Đồng ý và tiếp tục
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default ModalConfirmPolicy;
