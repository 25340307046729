import React, { FC, useEffect, useState } from 'react';
import { ENUM_BACKGROUND_TAG_SERVICE } from 'utils/BookingUltils';
import iconChecked from 'images/icons/icon_checked_blue.svg';
import iconTime from 'images/icons/icon_time.svg';
import iconInfo from 'images/icons/ic_info.svg';
import iconTick from 'images/icons/ic_tick.svg';
import iconCamp from 'images/icons/campaign-icon.svg';
import api from 'repository';
import trackingConst from 'config/TrackingConst';
import { useSelector } from 'react-redux';
import KEY from '../../Const';
import { IService, IServiceGroup } from '../../types/Service';
import { ISelected } from '../../types/Selected';

const ItemServices: FC<{
    serviceGroup: IServiceGroup;
    chosenService: IService[];
    onSelectService: Function;
    trackingData: any;
    selected: ISelected;
    userId: any;
    onClickDetailService: Function;
    layoutDefault: boolean;
    gotoCampaignDetail: Function;
}> = ({
    serviceGroup,
    chosenService,
    trackingData,
    selected,
    userId,
    onSelectService,
    onClickDetailService,
    layoutDefault,
    gotoCampaignDetail,
}) => {
    const common = useSelector((state: any) => state.common);
    const [serviceActive, setServiceActive] = useState<IService | null>();
    const [
        serviceActiveInit,
        setServiceActiveInit,
    ] = useState<IService | null>();
    const [showError, setShowError] = useState(false);

    const postTracking = async (data: any) => {
        try {
            await api.postTrackingDynamic(data);
        } catch (e) {
            console.log(e);
            throw e;
        }
    };

    const handleClickDetailService = (service: any) => {
        if (serviceActiveInit?.hasDetail) {
            onClickDetailService(service);
        }
        // tracking
        const dataTracking: any = {
            pageId: trackingConst.Pages.BOOKING_SINGLE_PAGE,
            screenId: trackingConst.Screens.BOOKING_SELECT_SERVICE_SCREEN,
            tokenKey: trackingData.token,
            source: trackingData.source,
            utmSource: trackingData.utmSource,
            eventId: serviceActiveInit?.hasDetail
                ? trackingConst.Events.CLICK_SERVICE_HAS_DETAIL
                : trackingConst.Events.CLICK_SERVICE_NO_HAS_DETAIL,
            data: {
                phone: selected[KEY.PHONE],
                salonId: selected[KEY.SALON].id.toString(),
                chosenServices: JSON.stringify(chosenService),
                customerId: userId || 0,
            },
            extendData: {
                groupId: service.groupId.toString(),
            },
        };
        if (
            Object.prototype.hasOwnProperty.call(service, 'campaignId') &&
            service.campaignId
        ) {
            dataTracking.data.campaignId = service.campaignId;
        }

        postTracking(dataTracking);
    };

    const onErrorImage = (e: any) => {
        e.target.src =
            'https://storage.30shine.com/ResourceWeb/data/images/Booking/booking-anh-mac-dinh.jpg';
    };

    const getValueCheckGroupBox = (svGroup: IServiceGroup) => {
        return (
            chosenService.findIndex((service) =>
                svGroup.services
                    .map((item) => item.serviceId)
                    .includes(service.serviceId),
            ) >= 0
        );
    };
    const checkedGroup = getValueCheckGroupBox(serviceGroup);

    const onClickSelectedService = () => {
        if (checkedGroup) setServiceActive(null);
        if (serviceGroup?.services.length === 1) {
            if (!checkedGroup) setServiceActive(serviceGroup?.services[0]);
            onSelectService(serviceGroup?.services[0], !checkedGroup);
        } else {
            if (!serviceActive) {
                setShowError(true);
                return;
            }
            onSelectService(serviceActive, !checkedGroup);
        }
    };

    const getColorTagService = (index: number) => {
        switch (index) {
            case 0: {
                const color = ENUM_BACKGROUND_TAG_SERVICE.find(
                    (cate) => cate.categoryId === serviceActiveInit?.categoryId,
                );
                return color
                    ? `${color.background} ${color.color}`
                    : 'bg-[#FFE985] text-[#222]';
            }
            case 1:
                if (serviceActiveInit?.used === 1) {
                    // lần trước
                    if (serviceActiveInit?.selected === 1)
                        return 'bg-[#11B14B] text-white';

                    // từng dùng
                    return 'bg-[#F4933C] text-white';
                }
                return 'bg-[#11B14B] text-[#F0F8FF]';
            default:
                return 'bg-[#FFE985] text-[#222]';
        }
    };

    const activeGroupService = (service: IService) => {
        return (
            serviceActive?.serviceId === service.serviceId &&
            serviceGroup.services.length > 1
        );
    };

    const onHandleClickSelectedServiceVariant = (service: IService) => {
        const chosenVariant = chosenService.find(
            (serviceItem) => serviceItem.serviceId === service.serviceId,
        );
        if (serviceActive?.serviceId === service.serviceId) {
            setServiceActive(null);
        } else setServiceActive(service);
        onSelectService(service, chosenVariant === undefined, false);
    };

    useEffect(() => {
        //  lưu lại dv được set default từ api để lấy ảnh, tiêu đề
        const svDefault = serviceGroup.services.find(
            (sv) => sv.isDefaultService,
        );
        if (svDefault) {
            setServiceActiveInit(svDefault);
        }
    }, [serviceGroup]);

    useEffect(() => {
        if (chosenService.length) {
            const serviceGroupId = serviceGroup.services.map(
                (item) => item.serviceId,
            );
            const newServiceActive = chosenService.find((service) =>
                serviceGroupId.includes(service.serviceId),
            );

            if (newServiceActive) {
                setServiceActive(newServiceActive);
            } else {
                setServiceActive(null);
            }
        }
    }, [chosenService, serviceGroup]);

    useEffect(() => {
        if (serviceActive) {
            setShowError(false);
        }
    }, [serviceActive]);

    return (
        <div className="h-full flex flex-col gap-1.5 rounded-[10px] overflow-hidden pb-[14px] shadow-[0px_2px_10px_0px_rgba(0,0,0,0.1)] ">
            <div
                onClick={() => handleClickDetailService(serviceGroup)}
                role="presentation"
                className={`service__image relative ${
                    serviceActiveInit?.hasDetail ? 'cursor-pointer' : ''
                }`}
            >
                <img
                    src={
                        serviceActiveInit?.serviceImage?.length
                            ? serviceActiveInit?.serviceImage[0]
                            : ''
                    }
                    alt={serviceActiveInit?.name}
                    onError={(e) => {
                        onErrorImage(e);
                    }}
                    className="w-full"
                />
                {serviceActiveInit?.hasDetail ? (
                    <img
                        src={iconInfo}
                        alt={serviceActiveInit?.name}
                        className="absolute top-1 right-1 lg:hover:invert rounded-full cursor-pointer w-[26px] h-[26px]"
                    />
                ) : (
                    ''
                )}
            </div>
            <div
                className={`flex items-baseline justify-between gap-x-[3px] px-[5px] ${
                    serviceActiveInit?.hasDetail ? 'cursor-pointer' : ''
                }`}
                onClick={() => handleClickDetailService(serviceGroup)}
                role="presentation"
            >
                <div className="text-[#15397F] font-semibold line-clamp-2 pr-2 md:pr-4 text-sm">
                    {serviceActiveInit?.name}
                </div>

                <div className="flex-none flex items-center text-sm text-[#15397F]">
                    <img src={iconTime} alt="" className="mr-1" />
                    {serviceActiveInit?.timeServe}
                </div>
            </div>

            <div
                className={`text-[#5F5F5F] text-xs line-clamp line-clamp-3 min-h-[48px] px-[5px] ${
                    serviceActiveInit?.hasDetail ? 'cursor-pointer' : ''
                }`}
                onClick={() => handleClickDetailService(serviceActiveInit)}
                role="presentation"
            >
                {serviceActive?.subtitle || serviceActiveInit?.subtitle}
            </div>
            <div
                className={`mt-auto flex flex-wrap gap-[5px] px-[5px] min-h-[24px] ${
                    serviceActiveInit?.hasDetail ? 'cursor-pointer' : ''
                }`}
            >
                {serviceActiveInit &&
                serviceActiveInit?.campaignTags?.length > 0
                    ? serviceActiveInit.campaignTags.map((campaign) => (
                        <div
                            key={campaign.campaignId}
                            className="rounded-[3px] bg-[#F24A30] text-white text-xs  truncate  flex items-center gap-1 cursor-pointer"
                            onClick={() => {
                                gotoCampaignDetail(campaign);
                            }}
                            role="presentation"
                        >
                            <div className="flex w-[20%] m-[2px] items-center justify-center bg-white  rounded-[3px]">
                                <img
                                    src={iconCamp}
                                    className="p-[2px] pl-[3px]"
                                    alt=""
                                />
                            </div>
                            <span className="w-[80%]  truncate">
                                {campaign.desc}
                            </span>
                            <svg
                                width="15"
                                height="14"
                                viewBox="0 0 15 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    className="icon-tag-animation"
                                    d="M2.84978 3.91436C2.74352 3.80435 2.68473 3.65699 2.68606 3.50405C2.68739 3.3511 2.74873 3.20479 2.85689 3.09664C2.96504 2.98848 3.11135 2.92713 3.2643 2.9258C3.41725 2.92447 3.5646 2.98327 3.67462 3.08953L7.17462 6.58953C7.28094 6.69583 7.34211 6.83908 7.34537 6.98939C7.34863 7.1397 7.29373 7.28547 7.19212 7.39628L3.98378 10.8963C3.8792 11.0103 3.73361 11.0781 3.57903 11.0848C3.42445 11.0915 3.27355 11.0364 3.15953 10.9319C3.04551 10.8273 2.97771 10.6817 2.97103 10.5271C2.96436 10.3725 3.01937 10.2216 3.12395 10.1076L5.95487 7.01945L2.84978 3.91436Z"
                                    fill="#ffffff"
                                />
                                <path
                                    className="icon-tag-animation-2"
                                    opacity="0.5"
                                    d="M6.93299 3.91307C6.87728 3.85926 6.83284 3.7949 6.80226 3.72373C6.77169 3.65256 6.7556 3.57601 6.75493 3.49856C6.75425 3.4211 6.76901 3.34429 6.79834 3.2726C6.82768 3.20091 6.87099 3.13578 6.92576 3.08101C6.98053 3.02624 7.04566 2.98293 7.11735 2.95359C7.18904 2.92426 7.26585 2.90951 7.34331 2.91018C7.42076 2.91085 7.49731 2.92694 7.56848 2.95752C7.63965 2.98809 7.70401 3.03253 7.75782 3.08824L11.2578 6.58824C11.3641 6.69454 11.4253 6.83779 11.4286 6.9881C11.4318 7.13841 11.3769 7.28418 11.2753 7.39499L8.06699 10.895C8.01521 10.9514 7.95281 10.9972 7.88336 11.0295C7.81391 11.0618 7.73877 11.0802 7.66224 11.0835C7.5857 11.0868 7.50926 11.075 7.43728 11.0488C7.3653 11.0225 7.2992 10.9824 7.24274 10.9306C7.18628 10.8788 7.14058 10.8164 7.10824 10.7469C7.07589 10.6775 7.05755 10.6024 7.05424 10.5258C7.05094 10.4493 7.06274 10.3728 7.08898 10.3009C7.11522 10.2289 7.15537 10.1628 7.20716 10.1063L10.0381 7.01816L6.93299 3.91307Z"
                                    fill="#ffffff"
                                />
                            </svg>
                        </div>
                      ))
                    : null}
                {serviceActiveInit?.tags && serviceActiveInit?.tags?.length
                    ? serviceActiveInit.tags.map((tag, index) => (
                          <div
                              key={tag}
                              className={`rounded-[3px] ${getColorTagService(
                                  index,
                              )} text-xs py-1 px-[4px]`}
                              onClick={() =>
                                  handleClickDetailService(serviceGroup)
                              }
                              role="presentation"
                          >
                              {tag}
                          </div>
                      ))
                    : null}
            </div>

            <div className="px-[5px] flex gap-[5px]">
                {serviceGroup?.services.map((service) => (
                    <div
                        className={`flex-1 flex flex-col relative rounded-[5px] py-1 cursor-pointer ${
                            activeGroupService(service)
                                ? 'bg-[#F0F8FF]'
                                : 'lg:hover:bg-[#F0F8FF]'
                        }
                        ${
                            serviceGroup?.services.length === 1
                                ? ''
                                : 'border border-[#15397F]'
                        }
                        ${showError ? ' border border-animation' : ''}
                        `}
                        onClick={() =>
                            onHandleClickSelectedServiceVariant(service)
                        }
                        aria-hidden="true"
                        key={service.serviceId}
                    >
                        <div
                            className={`px-2 lg:px-3 text-[#15397F] text-[10px] lg:text-xs font-semibold text-center line-clamp-1 ${
                                activeGroupService(service) ? 'pl-3' : ''
                            }`}
                        >
                            {service.versionName}
                        </div>

                        <img
                            src={iconTick}
                            alt=""
                            className={`absolute top-[-1px] left-[-1px] transition-all rounded-l-[5px] w-[20px] lg:w-[24px] ${
                                activeGroupService(service)
                                    ? 'opacity-1'
                                    : 'opacity-0'
                            }`}
                        />
                        <div className="mt-auto text-sm font-semibold text-[#3D3D3D] text-center">
                            {service?.price ? service.price : ''}{' '}
                            {service?.oldPrice !== service?.price ? (
                                <span className="font-semibold text-[#5F5F5F] text-[#3D3D3D] line-through pl-1 lg:pl-2.5">
                                    {service?.oldPrice}
                                </span>
                            ) : null}
                        </div>
                    </div>
                ))}
            </div>
            <div
                className={`mx-[5px] cursor-pointer flex items-center justify-center border border-[#15397F] rounded-[5px] transition-all py-2 px-4 text-sm font-semibold ${
                    checkedGroup
                        ? 'bg-[#F0F8FF] text-[#15397F] lg:hover:bg-white lg:hover:text-[#111]'
                        : 'bg-[#15397F] text-white lg:hover:bg-[#F0F8FF] lg:hover:text-[#15397F]'
                }`}
                onClick={() => onClickSelectedService()}
                role="presentation"
            >
                <img
                    src={iconChecked}
                    alt="icon"
                    className={`w-[13px] mr-1.5 ${
                        checkedGroup ? 'block' : 'hidden'
                    }`}
                />
                {checkedGroup
                    ? common.lang === 'vi'
                        ? 'Đã thêm'
                        : 'Added'
                    : common.lang === 'vi'
                    ? 'Thêm dịch vụ'
                    : 'Add'}
            </div>
        </div>
    );
};

export default ItemServices;
