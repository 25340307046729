/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import '../index.scss';
import LayoutHeadTitle from '../headTitle';
import RedirectLink from '../redirectLink';
import Banner from '../banner';

const LayoutBlogMoyocare: FC<{ data: any }> = ({ data }) => {
    return (
        <div className="layout layout-blog">
            <div className="container">
                <LayoutHeadTitle data={data} />
                <div className="home_store_banner">
                    <Banner data={data} />
                </div>
                <div className="layout__blog flex justify-around mt-5 divide-x divide-[#D9D9D9]">
                    {data.list.length > 0 &&
                        data.list.map((item: any, index: number) => {
                            return (
                                <div className="flex-1 justify-center px-1">
                                    <RedirectLink redirect={item.redirectLink}>
                                        <div
                                            className="blog text-center flex flex-col"
                                            key={item.id}
                                        >
                                            <div
                                                className={`text-[#20489B] text-center font-bold text-[48px] leading-none  ${
                                                    index === 2
                                                        ? 'order-2'
                                                        : 'order-1'
                                                }`}
                                            >
                                                {item.textNum}
                                            </div>

                                            <div
                                                className={`text-[#20489B] text-center font-bold text-[24px] ${
                                                    index === 2
                                                        ? 'order-1'
                                                        : 'order-2'
                                                }`}
                                            >
                                                {item.title}
                                            </div>
                                            <div className="text-[#666] text-center text-base order-3">
                                                {item.subTitle}
                                            </div>
                                        </div>
                                    </RedirectLink>
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

export default LayoutBlogMoyocare;
