import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Navigation, Autoplay, Lazy, Pagination } from 'swiper/js/swiper.esm';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import CustomBuildSwiper from 'components/customSwiper/CustomBuildSwiper';
import { useMediaQuery } from 'react-responsive';
import useWindowSize from 'hooks/useWindowSize';
import CommonPicture from 'components/common/Picture';
import vectorLeft from 'images/icons/home_slide_prev.svg';
import vectorRight from 'images/icons/home_slide_next.svg';
import './style.scss';
import { Modal } from 'antd';
import YoutubePlayer from 'components/YoutubePLayer';
import InputPhone from './InputPhone';

const Item = ({ item, className, handleOnClick }) => {
    return item?.link ? (
        <Fragment>
            <a
                href={item.redirectLink}
                onClick={(e) => handleOnClick(e, item)}
                rel="noopener noreferrer"
            >
                <CommonPicture
                    link={item.link}
                    data-src={item.link}
                    className={`${className} swiper-lazy`}
                    alt=""
                />
            </a>
            <div className="swiper-lazy-preloader" />
        </Fragment>
    ) : null;
};

const paramsPC = {
    modules: [Navigation, Autoplay, Lazy, Pagination],
    lazy: true,
    autoplay: {
        delay: 5000,
        disableOnInteraction: false,
    },
    pagination: {
        el: '.pagination',
        clickable: true,
    },
    loop: true,
    preloadImages: false,
    draggable: true,
    renderPrevButton: () => (
        <div className="swiper-button-prev swiper-button-custom">
            <img src={vectorLeft} alt="" />
        </div>
    ),
    renderNextButton: () => (
        <div className="swiper-button-next swiper-button-custom">
            <img src={vectorRight} alt="" />
        </div>
    ),
};

const paramsMB = {
    modules: [Navigation, Autoplay, Lazy, Pagination],
    loop: true,
    lazy: true,
    autoplay: {
        delay: 5000,
        disableOnInteraction: false,
    },
    pagination: {
        el: '.pagination',
        clickable: true,
    },
    draggable: true,
    // navigation: {
    //     nextEl: '.swiper-button-next',
    //     prevEl: '.swiper-button-prev',
    //     clickable: true,
    // },
    // renderPrevButton: () => (
    //     <div className="swiper-button-prev swiper-button-custom">
    //         <img src={vectorLeft} alt="" />
    //     </div>
    // ),
    // renderNextButton: () => (
    //     <div className="swiper-button-next swiper-button-custom">
    //         <img src={vectorRight} alt="" />
    //     </div>
    // ),
};

const FakeBanner = [{ link: '', redirectLink: '' }];

const Container = ({ dataBanner }) => {
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const [visibleYoutubePlayer, setVisibleYoutubePlayer] = useState(false);
    const [youtubeId, setYoutubeId] = useState('');
    const [data, setData] = useState({ pc: FakeBanner, mb: FakeBanner });
    const size = useWindowSize();
    const history = useHistory();

    const [height, setHeight] = useState({
        mb: 200,
        pc: 300,
    });

    function setBanner() {
        const listBannerPc = [];
        const listBannerMb = [];
        for (let index = 0; index < dataBanner.length; index++) {
            const item = dataBanner[index];
            if (item.type === 'web') listBannerPc.push(item);
            if (item.type === 'mobile') listBannerMb.push(item);
        }
        setData({ pc: listBannerPc, mb: listBannerMb });
    }

    const handleOnClick = (e, item) => {
        e.preventDefault();
        if (item.addOn === 'youtube') {
            setYoutubeId(item.redirectLink);
            setVisibleYoutubePlayer(true);
            return;
        }
        if (!item.redirectLink) return;
        if (item.redirectLink[0] === '/') {
            history.push(item.redirectLink);
        } else {
            window.open(item.redirectLink);
        }
    };

    useEffect(() => {
        if (dataBanner.length > 0) setBanner();
    }, [dataBanner]);

    useEffect(() => {
        if (size.width > 0) {
            const width = size.width >= 955 ? 955 : size.width;
            setHeight({
                mb: 280,
                pc: (31 / 96) * width,
            });
        }
    }, [size.width]);

    return (
        <Fragment>
            {!isMobile ? (
                <CustomBuildSwiper {...paramsPC} key={data.pc.length}>
                    {data.pc.map((item, key) => {
                        return (
                            <div
                                key={key}
                                style={height ? { minHeight: height.pc } : {}}
                            >
                                <Item
                                    item={item}
                                    className="pc"
                                    key={key}
                                    height={height}
                                    handleOnClick={handleOnClick}
                                />
                            </div>
                        );
                    })}
                </CustomBuildSwiper>
            ) : (
                <Fragment>
                    <div className="banner-slogan">
                        Thư giãn 30 phút và bạn sẽ toả sáng
                    </div>
                    <CustomBuildSwiper {...paramsMB} key={data.mb.length}>
                        {data.mb.map((item, key) => {
                            return (
                                <div
                                    key={key}
                                    style={
                                        height ? { minHeight: height.mb } : {}
                                    }
                                >
                                    <Item
                                        item={item}
                                        className="mb"
                                        key={key}
                                        height={height}
                                        handleOnClick={handleOnClick}
                                    />
                                </div>
                            );
                        })}
                    </CustomBuildSwiper>
                </Fragment>
            )}
            <Modal
                visible={visibleYoutubePlayer}
                footer={false}
                destroyOnClose
                closable
                maskClosable
                onCancel={() => setVisibleYoutubePlayer(false)}
                className="banner-embed-youtube"
                width={!isMobile ? 1000 : 375}
                centered
            >
                <div className="player">
                    {youtubeId && (
                        <YoutubePlayer
                            id={youtubeId}
                            activeSlide={youtubeId}
                            videoId={youtubeId}
                            playerOptions={{ autoplay: 1, controls: 1 }}
                        />
                    )}
                </div>
            </Modal>
        </Fragment>
    );
};

function mapStateToProps(state) {
    const { dataBanner } = state;
    return { dataBanner };
}

export const ContainerSP = connect(mapStateToProps)(Container);

const Slide = () => {
    return (
        <div className="px-1 home__slide md:px-0">
            <ContainerSP />
            <div className="container">
                <div className="flex flex-col lg:flex-row md:items-center lg:justify-center gap-6 mt-[23px]">
                    <InputPhone />
                </div>
            </div>
        </div>
    );
};

export default Slide;
