import React, { FC, useEffect, createRef, useState } from 'react';
import { Input } from 'antd';
import useDebounce from 'hooks/useDebounce';
import BoxShadown from 'components/BoxShadown/BoxShadown';
import search from 'images/icons/search.svg';
import { connect } from 'react-redux';
import { removeUnicodeChar } from 'components/func/Function';
import trackingConst from 'config/TrackingConst';
import api from 'repository';
import ListProvince from './components/ListProvince';
import ListSalon from './components/ListSalon';
import { IProvince } from '../types/CityDistrict';
import { ISalon } from '../types/Salon';

const useOutsideAlerter = (
    ref1: any,
    ref2: any,
    callBackFunction: Function,
) => {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: any) {
            if (
                ref1.current &&
                !ref1.current.contains(event.target) &&
                ref2.current &&
                !ref2.current.contains(event.target)
            ) {
                callBackFunction();
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref1, ref2]);
};

const SearchBar: FC<{
    text: string;
    onChangeText: Function;
    phone: string;
    listProvince: IProvince[];
    listSalon: ISalon[];
    handleClickCity: Function;
    onChangeSalon: Function;
    userId: number;
    trackingData: any;
    handleClickInputSearch: Function;
}> = ({
    text,
    onChangeText,
    phone,
    listProvince,
    listSalon,
    handleClickCity,
    onChangeSalon,
    userId,
    trackingData,
    handleClickInputSearch,
}) => {
    const [isShowBoxFind, setIsShowBoxFind] = useState(false);
    const [listFilterCity, setListFilterCity] = useState<IProvince[]>(
        listProvince,
    );
    const [listFilterSalon, setListFilterSalon] = useState<ISalon[]>([]);

    const input = createRef<HTMLDivElement>();
    const divListSalon = createRef<HTMLDivElement>();
    const wapperClass = isShowBoxFind ? 'show' : 'close';
    const debouncedSearch = useDebounce(text, 500);

    async function postTracking(dataTracking: any) {
        try {
            api.postTrackingDynamic(dataTracking);
        } catch (e) {
            console.log(e.message);
        }
    }

    useOutsideAlerter(input, divListSalon, () => {
        if (isShowBoxFind) setIsShowBoxFind(!isShowBoxFind);
    });

    const onHandleClickSearch = () => {
        handleClickInputSearch();
        setIsShowBoxFind(true);
    };

    useEffect(() => {
        if (listProvince.length > 0) {
            setListFilterCity(listProvince);
        }
    }, [listProvince]);

    useEffect(() => {
        const search = removeUnicodeChar(
            debouncedSearch.trim(),
        ).toLocaleLowerCase();

        if (debouncedSearch) {
            const salons: ISalon[] = [...listSalon]
                .filter(
                    (i) =>
                        i.searchText.toLocaleLowerCase().includes(search) &&
                        i.id !== 269,
                )
                .sort((a, b) => {
                    if (a.searchText < b.searchText) {
                        return -1;
                    }
                    if (a.searchText > b.searchText) {
                        return 1;
                    }
                    return 0;
                });
            setListFilterSalon(salons);
            const provinces = [...listProvince].filter((province) =>
                province.searchText.toLocaleLowerCase().includes(search),
            );
            setListFilterCity(provinces);
        } else {
            setListFilterSalon([]);
            setListFilterCity(listProvince);
            handleClickCity({});
        }

        if (debouncedSearch.length) {
            // TRACKING
            const dataTracking = {
                pageId: trackingConst.Pages.BOOKING_SINGLE_PAGE,
                screenId: trackingConst.Screens.BOOKING_SELECT_SALON_SCREEN,
                eventId: trackingConst.Events.INPUT_SEARCH_SALON,
                tokenKey: trackingData.token,
                source: trackingData.source,
                utmSource: trackingData.utmSource,
                data: {
                    phone,
                    searchSalonText: search,
                    customerId: userId || 0,
                },
            };

            postTracking(dataTracking);
        }
    }, [debouncedSearch]);

    return (
        <div className="salon__search">
            <div className="flex w-full search" ref={input}>
                <Input
                    value={text}
                    className="w-full br-2 h3"
                    onChange={(e) => onChangeText(e)}
                    onClick={() => onHandleClickSearch()}
                    placeholder="Tìm kiếm salon theo tỉnh, thành phố, quận"
                    prefix={
                        <div
                            className="search__icon"
                            onClick={() => onHandleClickSearch()}
                            role="presentation"
                        >
                            <img src={search} alt="" />
                        </div>
                    }
                />
                <BoxShadown isShow={isShowBoxFind}>
                    <div
                        className={`${wapperClass} search__box w-full br-2`}
                        ref={divListSalon}
                    >
                        <ListProvince
                            list={listFilterCity}
                            onChange={handleClickCity}
                            handleOnShowBox={() => setIsShowBoxFind(false)}
                        />
                        {listFilterSalon.length > 0 && (
                            <div className="search__city">
                                <div className="city__title">Salon</div>
                                <ListSalon
                                    list={listFilterSalon}
                                    onChange={onChangeSalon}
                                    showNote={false}
                                />
                            </div>
                        )}
                        {!listFilterCity.length && !listFilterSalon.length && (
                            <div className="item padding-10 h2">
                                Không có dữ liệu
                            </div>
                        )}
                    </div>
                </BoxShadown>
            </div>
        </div>
    );
};
function mapStateToProps(state: any) {
    const { trackingData, user } = state;
    return {
        trackingData,
        listSalon: state.salon.listSalon,
        listProvince: state.salon.listProvince.data,
        userId: user.userId,
    };
}

export default connect(mapStateToProps)(SearchBar);
