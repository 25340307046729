export const scrollToSmoothly = (pos: number, time: number) => {
    const currentPos: number = window.pageYOffset;
    let start: any = null;
    window.requestAnimationFrame(function step(currentTime: any) {
        start = !start ? currentTime : start;
        const progress: number = currentTime - start;
        if (currentPos < pos) {
            window.scrollTo(
                0,
                ((pos - currentPos) * progress) / time + currentPos,
            );
        } else {
            window.scrollTo(
                0,
                currentPos - ((currentPos - pos) * progress) / time,
            );
        }
        if (progress < time) {
            window.requestAnimationFrame(step);
        } else {
            window.scrollTo(0, pos);
        }
    });
};
