import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import RedirectLink from '../redirectLink';
import '../index.scss';

const Banner: FC<{ data: any }> = ({ data }) => {
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

    let bannerText = '';
    if (data?.textInsideBanner) {
        const regex = /<script>(.*?)<\/script>/g;
        bannerText = data.textInsideBanner.replace(regex, '');
    }

    return (
        data.banner &&
        data.bannerMobile && (
            <div
                className={`layout__banner ${data.redirectLinkBanner ? 'hover' : ''
                    }`}
            >
                <RedirectLink redirect={data.redirectLinkBanner}>
                    <div className="banner__media">
                        <img
                            src={isMobile ? data.bannerMobile : data.banner}
                            alt=""
                        />
                    </div>
                </RedirectLink>
                <div
                    className={
                        data?.title === 'Moyo Shop'
                            ? ''
                            : 'banner__filter text-center'
                    }
                />
                {data.titleInsideBanner ||
                    data.textInsideBanner ||
                    data.buttonInsideBanner ? (
                    <div className="banner__wrapper">
                        {data.titleInsideBanner && (
                            <div className="banner__title">
                                {data.titleInsideBanner}
                            </div>
                        )}
                            {bannerText && (
                                <div
                                    className="mb-4 text-sm font-bold md:text-xl"
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{
                                        __html: bannerText,
                                    }}
                                />
                        )}
                        {data.buttonInsideBanner && data.redirectLinkButton && (
                            <RedirectLink redirect={data.redirectLinkButton}>
                                <div className="banner__button">
                                    {data.buttonInsideBanner}
                                </div>
                            </RedirectLink>
                        )}
                    </div>
                ) : (
                    ''
                )}
            </div>
        )
    );
};

export default Banner;
