import StaffClient from './client/StaffViewClient';
import StaffViewAllSalonClient from './client/StaffViewAllSalonClient';

export default {
    getRankings() {
        return StaffViewAllSalonClient.get(
            `?masterKey=${process.env.REACT_APP_STAFF_VIEW_MASTERKEY}&top=30`,
        );
    },
    getSalonRankings(salonId = 0) {
        return StaffClient.get(
            `?masterKey=${process.env.REACT_APP_STAFF_VIEW_MASTERKEY}&salonId=${salonId}`,
        );
    },
};
