import React, { FC } from 'react';
import TrackingConst from 'config/TrackingConst';
import API from 'repository';
import { connect } from 'react-redux';
import useWindowSize from 'hooks/useWindowSize';
import LayoutHeadTitleMoyo from '../title';
import '../../index.scss';


const LayoutBlock1: React.FC<{
    data: any;
    trackingData: any;
}> = ({ data, trackingData }) => {
    const size: any = useWindowSize();
    const isMobile = size.width < 768;
    const colLg = data.list.length === 3 ? 8 : 6;

    const postTracking = async (eventId: string) => {
        if (!eventId) return;
        try {
            const dataTracking = {
                pageId: TrackingConst.Pages.HOME_PAGE,
                screenId: TrackingConst.Pages.HOME_PAGE,
                eventId,
                tokenKey: trackingData.token,
                source: trackingData.source,
                utmSource: trackingData.utmSource,
                data: {
                    phone: trackingData.phoneNumber || '',
                    customerId: trackingData.customerId || 0,
                },
            };
            await API.postTrackingDynamic(dataTracking);
        } catch (e) {
            console.log(e.message);
        }
    };
    const createMarkup = (htmlCode: string) => {
        return { __html: htmlCode };
    };
    console.log(data, "data")
    return (
        <div className="layout layout-service">
            <div className="container">
                <LayoutHeadTitleMoyo data={data} />
                <div className='grid grid-cols-4 max-md:grid-cols-2 gap-4'>
                    {data.list &&
                        data.list.length > 0 &&
                        data.list.map((item: any, index: number) => {
                            return (
                                <div className=''>
                                    <div className='relative rounded-lg overflow-hidden'>
                                        <img
                                            className=''
                                            src={
                                                isMobile &&
                                                    item.linkImageMb
                                                    ? item.linkImageMb
                                                    : item.linkImage
                                            }
                                            alt=""
                                        />
                                        {index % 2 === 0 ?
                                            <div className='absolute z-10 bg-gradient-to-t from-[#EF4E24] from-10% bottom-0 w-full h-[50%] '></div>
                                            :
                                            <div className='absolute z-10 bg-gradient-to-t from-[#0f54ae] from-10% bottom-0 w-full h-[50%] '></div>
                                        }
                                    </div>
                                    <div className='text-center md:px-3 p-2'>
                                        <span className='font-semibold text-base !leading-3 max-md:text-sm'>{item.title}</span>
                                    </div>
                                </div>
                            )
                        })}
                </div>

            </div>
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        trackingData: state.trackingData,
    };
};

export default connect(mapStateToProps)(LayoutBlock1);
