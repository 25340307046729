import React, { FC, useEffect, useState, useRef } from 'react';
import { Skeleton } from 'antd';
import MySwiper from 'components/my-swiper/MySwiper';
import BoxShadown from 'components/BoxShadown/BoxShadown';
import trackingConst from 'config/TrackingConst';
import API from 'repository';
import { ISalon } from '../../types/Salon';
import ItemSalon from './ItemSalon';

const ListSalonSwiper: FC<{
    list: ISalon[];
    title: string;
    onChange: Function;
    isLoading: boolean;
    trackingData: any;
    phone: string;
    isGrid: boolean;
    isParking: boolean;
}> = ({
    list = [],
    title = '',
    onChange,
    isLoading,
    trackingData,
    phone,
    isGrid,
    isParking,
}) => {
    const [listSalon, setListSalon] = useState<ISalon[]>([]);
    const [width, setWidth] = useState(200);
    const categoryRef = useRef<any>(null);

    const postTracking = async (data: any) => {
        try {
            API.postTrackingDynamic(data);
        } catch (e) {
            console.log(e.message);
        }
    };

    const handleTrackingSwiper = (isNext: any) => {
        const dataTracking = {
            pageId: trackingConst.Pages.BOOKING_SINGLE_PAGE,
            screenId: trackingConst.Screens.BOOKING_SELECT_SALON_SCREEN,
            eventId: isNext
                ? trackingConst.Events.SWIPE_NEXT_SALON
                : trackingConst.Events.SWIPE_PREV_SALON,
            tokenKey: trackingData.token,
            source: trackingData.source,
            utmSource: trackingData.utmSource,
            data: {
                phone,
                customerId: trackingData.data || 0,
            },
        };

        postTracking(dataTracking);
    };

    const handleSlideChange = (swiper: any) => {
        handleTrackingSwiper(swiper.activeIndex > swiper.previousIndex);
    };

    const renderLoading = () => {
        const isShow = !list.length && isLoading;
        return (
            isShow && (
                <div className="flex">
                    <div
                        style={{
                            height: '245px',
                            width: '45%',
                            marginLeft: 10,
                        }}
                    >
                        <Skeleton paragraph={{ rows: 4 }} active />
                    </div>
                    <div
                        style={{
                            height: '245px',
                            width: '45%',
                            marginLeft: 10,
                        }}
                    >
                        <Skeleton paragraph={{ rows: 4 }} active />
                    </div>
                </div>
            )
        );
    };

    const initStateWidth = () => {
        const el = categoryRef.current;
        const { width } = el.getBoundingClientRect();
        const screen =
            document.documentElement.offsetWidth >= 1024 ? 'pc' : 'sp';
        let result = width;
        if (screen === 'pc') {
            setWidth(189.833);
        } else {
            result = (width - 16 - 22) / 1.8 + 1.78;
            setWidth(result);
        }
    };

    useEffect(() => {
        if (isParking) {
            const listSalonHasParking = [...list].filter(
                (item) => item.parking,
            );
            setListSalon(listSalonHasParking);
        } else {
            setListSalon(list);
        }
    }, [isParking, list]);

    useEffect(() => {
        initStateWidth();
    }, []);

    const classWrapper = listSalon.length > 0 ? 'show' : 'close';
    return (
        <div className="salon__category order-last" ref={categoryRef}>
            <div className="category">
                {title && (
                    <div className="category__title">
                        {title} ({listSalon.length})
                    </div>
                )}
                <BoxShadown isShow={list.length > 0}>
                    {listSalon.length > 0 ? (
                        <div className={classWrapper}>
                            <MySwiper
                                shouldSwiperUpdate
                                spaceBetween={16}
                                large={2.4}
                                medium={1.8}
                                small={1.8}
                                onSlideChange={handleSlideChange}
                            >
                                {listSalon.map(
                                    (item: ISalon, index: number) => {
                                        return (
                                            <div
                                                className="category__swiper"
                                                style={{
                                                    maxWidth: `${
                                                        width > 0
                                                            ? `${width}px`
                                                            : 'auto'
                                                    }`,
                                                }}
                                            >
                                                <ItemSalon
                                                    key={index}
                                                    item={item}
                                                    onChange={onChange}
                                                    index={index}
                                                    width={width}
                                                    isGrid={isGrid}
                                                />
                                            </div>
                                        );
                                    },
                                )}
                            </MySwiper>
                        </div>
                    ) : (
                        <div
                            className="content-center-middle"
                            style={{ height: 205 }}
                        >
                            <div>Không tìm thấy Salon phù hợp </div>
                        </div>
                    )}
                </BoxShadown>
                {renderLoading()}
            </div>
        </div>
    );
};
export default ListSalonSwiper;
