/* eslint-disable no-nested-ternary */
import React, { FC, memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { removeDuplicateSalon } from 'components/func/Function';
import ListSalonSwiper from './components/ListSalonSwiper';
import ListSalonCurrentPosition from './components/ListSalonCurrentPosition';
import ListSalonSelectedDistrict from './components/ListSalonSelectedDistrict';
import { ISalon } from '../types/Salon';
import FilterParking from './components/FilterParking';

interface IListCategorySalon {
    data: ISalon[];
    title: string;
    typeItem: 'grid' | 'list';
    typeData: 'listSlide' | 'findSalonNear' | 'findSalonProvince';
}

const ListDataSalon: FC<{
    data: IListCategorySalon[];
    trackingData: any;
    isLoading: boolean;
    onChangeSalon: Function;
    phone: string;
    cityActive: any;
    handleOnChangeDistrict: Function;
    isHideParking?: boolean;
    isShowSalonNear: boolean;
}> = ({
    data,
    trackingData,
    isLoading,
    onChangeSalon,
    phone,
    cityActive,
    handleOnChangeDistrict,
    isHideParking = false,
    isShowSalonNear,
}) => {
    const [listData, setListData] = useState<IListCategorySalon[]>(data);
    const [isParking, setIsParking] = useState<boolean>(false);

    useEffect(() => {
        const list = [...data].map((item) => {
            return {
                ...item,
                data: removeDuplicateSalon(item.data),
            };
        });
        setListData(list);
        setIsParking(false);
    }, [data]);

    useEffect(() => {
        if (isHideParking && isShowSalonNear) setIsParking(true);
    }, [isHideParking, isShowSalonNear]);

    return (
        <div className="flex flex-col">
            {!isHideParking && (
                <FilterParking
                    setIsChecked={setIsParking}
                    isChecked={isParking}
                    trackingData={trackingData}
                />
            )}
            {listData.length > 0 &&
                listData.map((item, index) =>
                    item.typeData === 'listSlide' ? (
                        <ListSalonSwiper
                            list={item.data}
                            title={item.title}
                            isLoading={isLoading}
                            onChange={onChangeSalon}
                            trackingData={trackingData}
                            phone={phone}
                            isGrid={item.typeItem === 'grid'}
                            key={index}
                            isParking={isParking}
                        />
                    ) : item.typeData === 'findSalonNear' ? (
                        <ListSalonCurrentPosition
                            key={index}
                            list={item.data}
                            onChange={onChangeSalon}
                            isParking={isParking}
                        />
                    ) : (
                        <ListSalonSelectedDistrict
                            key={index}
                            list={item.data}
                            onChange={onChangeSalon}
                            listDistrict={cityActive.listDistrict}
                            filterDistrict={cityActive.districtActive}
                            handleOnChangeDistrict={handleOnChangeDistrict}
                            isParking={isParking}
                        />
                    ),
                )}
        </div>
    );
};

function mapStateToProps(state: any) {
    return {
        trackingData: state.trackingData,
    };
}

export default connect(mapStateToProps)(ListDataSalon);
