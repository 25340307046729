import axios, { AxiosResponse } from 'axios';
import Cookies from 'js-cookie';

const http = require('http');
const https = require('https');

export default function getInstanceAxios(
    baseAPI: string,
    notAuthorization: boolean | undefined,
) {
    const instance = axios.create({
        baseURL: baseAPI,
        httpAgent: new http.Agent({ keepAlive: true }),
        httpsAgent: new https.Agent({ keepAlive: true }),
    });

    instance.interceptors.request.use(
        function (config) {
            const accessToken =
                Cookies.get('_cus_accessToken2') ||
                localStorage.getItem('accessToken');
            config.headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            };
            if (!notAuthorization) {
                config.headers.Authorization = accessToken
                    ? `Bearer ${accessToken}`
                    : '';
            }
            // @ts-ignore
            if (!notAuthorization && config.baseURL.includes('api-cms'))
                config.headers['x-from'] = accessToken ? 'web' : '';
            return config;
        },
        function (error) {
            return Promise.reject(error);
        },
    );

    instance.interceptors.response.use(
        function (response: AxiosResponse) {
            try {
                if (response.status !== 200)
                    return Promise.reject(response.data);
                return response.data;
            } catch (error) {
                return Promise.reject(error);
            }
        },
        function (error) {
            if (error && error.response) {
                const { status } = error.response;
                if (status === 401) {
                    localStorage.removeItem('accessToken');
                }
            }
            return Promise.reject(error);
        },
    );
    return instance;
}
