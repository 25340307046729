/* eslint-disable no-nested-ternary */
import React, { FC, useState, useEffect } from 'react';
import { ITimeBox } from '../../../types/DateTime';
import { ISelectedSalonBackUp } from '../../../types/Selected';
import SwiperTimeBox from './SwiperTimeBox';
import iconHome from 'images/fillGray/homeOutline.svg';
import { ISalonBackUP } from '../index';

const SalonBackUp: FC<{
    salon: ISalonBackUP;
    hours: ITimeBox[];
    onChangeTime: Function;
    selected: ISelectedSalonBackUp;
    listSalon: any;
}> = ({ salon, hours, onChangeTime, selected, listSalon }) => {
    const [nameSalon, setNameSalon] = useState('');

    useEffect(() => {
        // get name salon backup
        if (listSalon.length) {
            const name = listSalon.find(
                (item: any) => item.id === salon.salonId,
            );
            setNameSalon(name?.addressNew || '');
        }
    }, [listSalon]);

    return (
        <div className="item">
            <div className="item__head">
                <div className="flex item-center">
                    <img src={iconHome} alt="" className="head__icon" />
                    <div className="head__name">{nameSalon || salon.name}</div>
                </div>
                <div className="head__location">Cách {salon.distance}km</div>
            </div>
            <div className="item__box-time">
                <SwiperTimeBox
                    onChangeTime={onChangeTime}
                    hours={hours}
                    selected={selected}
                />
            </div>
        </div>
    );
};

export default SalonBackUp;
