import React from 'react';

const Picture = ({ link, className = '', alt = '', widthString = '' }) => {
    const pathWithNoExt = link.substr(0, link.lastIndexOf('.'))
    const ext = /[^.]+$/.exec(link)[0]
    return (
        <picture style={{ width: '100%' }}>
            {/* <source type="image/webp" srcSet={`${pathWithNoExt}${widthString}.webp`} /> */}
            <source type={`image/${ext === 'jpeg' ? 'jpg' : ext}`} srcSet={`${pathWithNoExt}${widthString}.${ext}`} />
            <img className={className} src={link} alt={alt} />
        </picture>
    );
};

export default Picture;