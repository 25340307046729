import React, { FC, useState, createRef, useRef, useEffect } from 'react';

interface Props {
    size: number;
    src: string;
    height?: number;
    alt?: string;
    style?: any;
}

const Image: FC<Props> = ({ size, src, height, alt, ...props }) => {
    const [h, setH] = useState<number>(height || 0);
    const el = createRef<HTMLDivElement>();
    const checkCache = useRef(false);
    useEffect(() => {
        if (el && el.current && !checkCache.current) {
            setH(el.current.offsetWidth / size);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onLoad = () => {
        checkCache.current = true;
        setH(0);
    };
    return (
        <div className='placehoder' style={{ height: h || 'inherit' }} ref={el} {...props}>
            <img
                className={h === 0 ? 'block w-full' : 'hidden w-full'}
                src={src}
                alt={alt || ''}
                onLoad={onLoad}
            />
        </div>
    );
};

export default Image;
