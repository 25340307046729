import { Affix, Input } from 'antd';
import React, { FC, useEffect, useRef, useState } from 'react';
import { removeVietnameseTonesLowCase } from 'utils/validator';
import icsearch from 'images/icons/search.svg';
import icclose from 'images/icons/close.svg';
import { IListService, IService, IServiceGroup } from '../types/Service';
import ListServiceCategorySearch from './ListServiceCategorySearch';
import { ISelected } from '../types/Selected';
import { useSelector } from 'react-redux';
import useScrollDir from 'hooks/useScrollDir';
import useScrollPosition from 'hooks/userScrollPosition';
import ListServiceCategorySearchSwiper from './ListServiceCategorySearchSwiper';

const SearchBar: FC<{
    setSelectService: Function;
    listService: IListService[];
    setInitialState: Function;
    ruleShowBooking?: Array<any>;
    setListServiceRule: Function;
    setListServicesShow: Function;
    selectService: any;
    listServicesShow: IListService[];
    setTottalServiceShow: Function;
    search: string;
    setSearch: Function;
    isShowListUsedServices: boolean;
    setIsShowListUsedServices: Function;
    selected: ISelected;
    listUsedServices: IListService[];
}> = ({
    setSelectService,
    listService,
    setInitialState,
    ruleShowBooking,
    setListServiceRule,
    setListServicesShow,
    selectService,
    listServicesShow,
    setTottalServiceShow,
    search,
    setSearch,
    isShowListUsedServices,
    setIsShowListUsedServices,
    selected,
    listUsedServices,
}) => {
    const common = useSelector((state: any) => state.common);
    const [inputError, setInputError] = useState(false);
    const [isAffix, setIsAffix] = useState(false);
    const scrollDir = useScrollDir();
    const scrollPosition = useScrollPosition();
    const refSearch = useRef<any>(null);

    // hàm sắp xếp rule về mặc định
    const handleRuleDefault = () => {
        // if(ruleShowBooking && ruleShowBooking.length >0) {
        //     let listArray:any =[];
        //     const filteredItems = ruleShowBooking.filter((item:any) => item.order === 1);
        //     const orderDetail = filteredItems.map((item:any) => item.orderDetail);

        //     if(orderDetail) {
        //         if(orderDetail.length > 0) {
        //             listArray = [1, ...orderDetail[0]];
        //         }
        //     }
        //     const sortedListService = listService.map((item:any) => ({ ...item }));
        //     sortedListService.sort((a :any, b:any) => {
        //         const orderA = listArray.indexOf(a.order);
        //         const orderB = listArray.indexOf(b.order);
        //         return orderA - orderB;
        //     });
        //     return sortedListService;
        // } else {
        //     return listService;
        // }
        // fix lại theo BA : rule mặc định là rule trả về từ BE
        return listService;
    };

    useEffect(() => {
        if (listService.length && search === '') {
            // trạng thái ban đầu set rule hiển thị là 1
            const defaultRuleValue = handleRuleDefault();
            // gán rule để hiển thị
            setListServicesShow(defaultRuleValue);
            setListServiceRule(defaultRuleValue);
        }
    }, [listService, search]);

    // tạo hàm thực hiện lọc chỉ có search, cả search và service, chỉ có service
    // chỉ có search
    function isDuplicate(element: any, array: any) {
        return array.some((item: any) => item === element);
    }

    const filterOnlySearch = (output: any) => {
        if (output && output.length > 0) {
            const servicesArray = listService
                .map((item) => item.serviceGroups)
                .flat()
                .map((item) => item.services)
                .flat();
            let combinedArray: IService[] = [];
            // ưu tiên lọc chính xác cụm từ search trước

            // lọc không chính xác theo tiêu đề
            const filteredArrayTitle = servicesArray.filter((service) =>
                output.every((item: string) => {
                    const lowerName = service.name?.toLowerCase() || '';
                    return lowerName.includes(item.toLowerCase());
                }),
            );
            // console.log(filteredArrayTitle, "filteredArrayTitle");
            filteredArrayTitle.forEach((element: IService) => {
                if (!isDuplicate(element, combinedArray)) {
                    combinedArray.push(element);
                }
            });

            // lọc theo giá
            const filteredArrayPrice = servicesArray.filter((service) =>
                output.every((item: string) => {
                    const price =
                        service?.oldPriceNum || service?.priceNum || '';
                    return price.toString().includes(item.toLowerCase());
                }),
            );

            filteredArrayPrice.forEach((element: IService) => {
                if (!isDuplicate(element, combinedArray)) {
                    combinedArray.push(element);
                }
            });

            // lọc theo id
            const filteredArrayIdService = servicesArray.filter((service) =>
                output.every((item: string) => {
                    const id = service?.serviceId || service?.priceNum || '';
                    return id.toString().includes(item.toLowerCase());
                }),
            );

            filteredArrayIdService.forEach((element: IService) => {
                if (!isDuplicate(element, combinedArray)) {
                    combinedArray.push(element);
                }
            });

            // lọc không chính xác theo miêu tả
            const filteredArraySubtitle = servicesArray.filter((service) =>
                output.every((item: string) => {
                    const lowerSubtitle = service.subtitle?.toLowerCase() || '';
                    const lowerName = service.name?.toLowerCase() || '';
                    return (
                        lowerSubtitle.includes(item.toLowerCase()) ||
                        lowerName.includes(item.toLowerCase())
                    );
                }),
            );
            // console.log(filteredArraySubtitle, "filteredArrayExacthy");
            filteredArraySubtitle.forEach((element: IService) => {
                if (!isDuplicate(element, combinedArray)) {
                    combinedArray.push(element);
                }
            });

            // lọc không phân biệt có dấu
            const filteredArray = servicesArray.filter((service) =>
                output.every((item: string) => {
                    const lowerSubtitle = removeVietnameseTonesLowCase(
                        service.subtitle,
                    );
                    const lowerName = removeVietnameseTonesLowCase(
                        service.name,
                    );
                    return (
                        lowerSubtitle.includes(
                            removeVietnameseTonesLowCase(item),
                        ) ||
                        lowerName.includes(removeVietnameseTonesLowCase(item))
                    );
                }),
            );
            // console.log(filteredArray, "filteredArray");
            filteredArray.forEach((element: IService) => {
                if (!isDuplicate(element, combinedArray)) {
                    combinedArray.push(element);
                }
            });
            // RULE HIỂN THỊ BOOKING
            // khi search không có dịch vụ thì hiển thị rule mặc định
            // gán rule để hiển thị
            if (combinedArray.length === 0)
                setListServiceRule(handleRuleDefault());
            else {
                const combinedArrayId = combinedArray.map(
                    (item) => item.serviceId,
                );
                const newListServiceRule = listService.map((cate) => ({
                    ...cate,
                    serviceGroups: cate.serviceGroups
                        .map((svGroup) => ({
                            ...svGroup,
                            services: svGroup.services.filter(
                                (sv) => !combinedArrayId.includes(sv.serviceId),
                            ),
                        }))
                        .filter((svGroup) => svGroup.services.length),
                }));
                setListServiceRule(newListServiceRule);
            }
            combinedArray = [...combinedArray].filter(
                (v, i, a) =>
                    a.findIndex((v2) => v2.serviceId === v.serviceId) === i,
            );
            const mergedArray: IServiceGroup[] = combinedArray.reduce(
                (result: IServiceGroup[], currentItem) => {
                    const existingGroup = result.find(
                        (group) => group.groupId === currentItem.groupId,
                    );

                    if (existingGroup) {
                        existingGroup.services.push(currentItem);
                    } else {
                        result.push({
                            groupId: currentItem.groupId,
                            groupName: currentItem.name,
                            services: [
                                {
                                    ...currentItem,
                                    isDefaultService: true,
                                },
                            ],
                        });
                    }

                    return result;
                },
                [],
            );

            const data: IListService[] = [
                {
                    categoryName: search,
                    order: 0,
                    listService: combinedArray,
                    serviceGroups: mergedArray,
                    title: '',
                },
            ];
            return data;
        }

        const data = [
            {
                categoryName: search,
                order: 0,
                listService: [],
                serviceGroups: [],
                title: search,
            },
        ];
        return data;
    };
    // chỉ có services
    const filterOnlyServices = (value: number[]) => {
        if (value && value.length > 0) {
            const filteredServices: IListService[] = value
                .map((order) =>
                    listService.find((service: any) => service.order === order),
                )
                .filter(Boolean) as IListService[];

            const remainingServices: IListService[] = listService.filter(
                (service: any) => !value.includes(service.order),
            );

            const sortedListServices: IListService[] = [...filteredServices];
            // thực hiện rule với việc lọc chỉ có service
            let listArray: any[] = [];
            if (ruleShowBooking && ruleShowBooking.length > 0) {
                const filteredItems = ruleShowBooking.filter(
                    (item: any) => item.order === value[0],
                );
                const orderDetail = filteredItems.map(
                    (item: any) => item.orderDetail,
                );
                listArray = orderDetail[0].filter(
                    (item: any) => !value.includes(item),
                );
                const sortedListService = listService
                    .filter((item: any) => listArray.includes(item.order))
                    .map((item: any) => ({ ...item }));

                sortedListService.sort((a: any, b: any) => {
                    const orderA = listArray.indexOf(a.order);
                    const orderB = listArray.indexOf(b.order);
                    return orderA - orderB;
                });
                // gán rule để hiển thị
                setListServiceRule(sortedListService);
                return sortedListServices;
            }

            setListServiceRule(remainingServices);
            return sortedListServices;
        }
        return listService;
    };
    const filterServicesSearch = (services: number[], output: any) => {
        // console.log(services, "services")
        const servicesArray = listService
            .map((item) => item.serviceGroups)
            .flat()
            .map((item) => item.services)
            .flat();
        // console.log(servicesArray, "servicesArray");
        const outputString: string = output.join(' ');
        // console.log(outputString);
        const combinedArray: IService[] = [];
        // ưu tiên lọc chính xác cụm từ search trước
        const filteredArrayExacthyTitle = servicesArray.filter((service) => {
            const lowerName = service.name?.toLowerCase() || '';
            return lowerName.includes(outputString.toLowerCase());
        });
        // console.log(filteredArrayExacthyTitle, "filteredArrayExacthyTitle");
        filteredArrayExacthyTitle.forEach((element: IService) => {
            if (!isDuplicate(element, combinedArray)) {
                combinedArray.push(element);
            }
        });
        // lọc không chính xác theo tiêu đề
        const filteredArrayTitle = servicesArray.filter((service) =>
            output.every((item: string) => {
                const lowerName = service.name?.toLowerCase() || '';
                return lowerName.includes(item.toLowerCase());
            }),
        );
        // console.log(filteredArrayTitle, "filteredArrayTitle");
        filteredArrayTitle.forEach((element: IService) => {
            if (!isDuplicate(element, combinedArray)) {
                combinedArray.push(element);
            }
        });
        // lọc không chính xác theo miêu tả
        const filteredArraySubtitle = servicesArray.filter((service) =>
            output.every((item: string) => {
                const lowerSubtitle = service.subtitle?.toLowerCase() || '';
                const lowerName = service.name?.toLowerCase() || '';
                return (
                    lowerSubtitle.includes(item.toLowerCase()) ||
                    lowerName.includes(item.toLowerCase())
                );
            }),
        );
        // console.log(filteredArraySubtitle, "filteredArrayExacthy");
        filteredArraySubtitle.forEach((element: IService) => {
            if (!isDuplicate(element, combinedArray)) {
                combinedArray.push(element);
            }
        });
        // lọc không phân biệt có dấu
        const filteredArray = servicesArray.filter((service) =>
            output.every((item: string) => {
                const lowerSubtitle = removeVietnameseTonesLowCase(
                    service.subtitle,
                );
                const lowerName = removeVietnameseTonesLowCase(service.name);
                return (
                    lowerSubtitle.includes(
                        removeVietnameseTonesLowCase(item),
                    ) || lowerName.includes(removeVietnameseTonesLowCase(item))
                );
            }),
        );
        // console.log(filteredArray, "filteredArray");
        filteredArray.forEach((element: IService) => {
            if (!isDuplicate(element, combinedArray)) {
                combinedArray.push(element);
            }
        });
        // const filteredArray = servicesArray.filter((item:any) => compareStrings(item.name, item.subtitle, search));
        // thêm rule hiển thị booking
        if (ruleShowBooking && ruleShowBooking.length > 0) {
            let list: number[] = [];
            if (services[0]) {
                const filteredItems = ruleShowBooking.filter(
                    (item) => item.order === services[0],
                );
                const orderDetail = filteredItems.map(
                    (item) => item.orderDetail,
                );
                const listSota: number[] = orderDetail[0].filter(
                    (item: any) => !services.includes(item),
                );
                list = [...services, ...listSota];
            }
            const sortedListService = listService
                .filter((item: any) => list.includes(item.order))
                .map((item: any) => ({ ...item }));

            sortedListService.sort((a, b) => {
                const orderA = list.indexOf(a.order);
                const orderB = list.indexOf(b.order);
                return orderA - orderB;
            });
            setListServiceRule(sortedListService);
        } else {
            setListServiceRule(listService);
        }

        const mergedArray: IServiceGroup[] = combinedArray.reduce(
            (result: IServiceGroup[], currentItem) => {
                const existingGroup = result.find(
                    (group) => group.groupId === currentItem.groupId,
                );

                if (existingGroup) {
                    existingGroup.services.push({
                        ...currentItem,
                    });
                } else {
                    result.push({
                        groupId: currentItem.groupId,
                        groupName: currentItem.name,
                        services: [
                            {
                                ...currentItem,
                            },
                        ],
                    });
                }

                return result;
            },
            [],
        );

        const data: IListService[] = [
            {
                categoryName: search,
                order: 0,
                listService: combinedArray,
                serviceGroups: mergedArray,
                title: '',
            },
        ];
        return data;
    };

    const handleOnChangeSearch = (value: string) => {
        //
        setSearch(value);
        setInputError(false);
        // setSelectService([]);
        // nếu như người dúng xóa hết text thì cũng callback về như k search
        if (!value) {
            setInitialState(true);
            // khi search không có dịch vụ thì hiển thị rule mặc định
            const defaultRuleValue = handleRuleDefault();
            // gán rule để hiển thị
            setListServicesShow(defaultRuleValue);
        } else {
            // setInitialState(false);
        }
    };

    const handleSubmitSearch = (event: any) => {
        setSelectService([]);
        // tách tiêu đề services thành 1 mảng
        const lowercaseTitles = listService.map(function (service) {
            return removeVietnameseTonesLowCase(service.title);
        });
        if (event.key === 'Enter') {
            if (isAffix) {
                window.scrollTo({
                    top: 50,
                    behavior: 'smooth',
                });
            }
            // logic lọc
            // set trạng thái ban đầu = false
            setInitialState(false);
            // kiểm tra xem có chứa các cụm từ trong dấu ngoăc không ?
            // Tách chuỗi thành các phần tử trong mảng
            if (search) {
                // check trường hợp gõ đúng tên dịch vụ và tìm kiếm
                let nameSake = false;
                const filteredServices: any = listService.filter(
                    (service: any) => {
                        if (
                            removeVietnameseTonesLowCase(service.title) ===
                                removeVietnameseTonesLowCase(search) &&
                            !search.startsWith('"') &&
                            !search.endsWith('"')
                        ) {
                            setSelectService([service.order]);
                            nameSake = true;
                            setSearch('"' + search + '"');
                            const filteredArray = filterOnlyServices([
                                service.order,
                            ]);
                            setListServicesShow(filteredArray);
                        }
                    },
                );
                if (!nameSake) {
                    const output = search
                        .match(/"([^"]+)"|\S+/g) // Tách chuỗi thành mảng các từ và các từ trong ngoặc kép
                        ?.map((str: any) =>
                            str.replace(/"/g, '').trim().toLowerCase(),
                        ) // Xử lý các phần tử trong mảng
                        .filter((str: any) => str !== ''); // Loại bỏ các phần tử trống
                    // Nếu chứa phần tử dịch vụ thì sẽ bật dịch vụ đó sáng và set dữ liệu dịch vụ đó đc chọn
                    // console.log(listService, "listService");
                    // Kiểm tra sự trùng lặp với trường "title" của các dịch vụ, nếu trùng thì sẽ cho cách dịch vụ đó sáng lên
                    const kafa: any[] = [];
                    if (output && output.length > 0) {
                        output.forEach((outputItem) => {
                            const foundService = listService.find(
                                (service: IListService) =>
                                    removeVietnameseTonesLowCase(
                                        service.title,
                                    ) ===
                                    removeVietnameseTonesLowCase(outputItem),
                            );
                            if (
                                foundService &&
                                !kafa.includes(foundService.order)
                            ) {
                                kafa.push(foundService.order);
                            }
                        });
                        setSelectService(kafa);
                    }
                    let foundMatch = output?.every((outputItem) => {
                        return lowercaseTitles.includes(
                            removeVietnameseTonesLowCase(outputItem),
                        );
                    });
                    foundMatch = foundMatch !== false;
                    if (foundMatch) {
                        setSelectService(kafa);
                        const filteredArray = filterOnlyServices(kafa);
                        setListServicesShow(filteredArray);
                    }
                    // chia trường hợp lọc tại đây: nếu có viết dịch vụ , chỉ search từ khóa
                    else if (kafa.length === 0) {
                        // TH từ khóa không chứa nhóm dịch vụ ta truyền mảng vào hàm filterOnlySearch
                        const filteredArray = filterOnlySearch(output);
                        // console.log(filteredArray, "filteredArray")
                        setListServicesShow(filteredArray);
                    } else {
                        const filteredOutput = output?.filter(
                            (item) =>
                                !lowercaseTitles.includes(
                                    removeVietnameseTonesLowCase(item),
                                ),
                        );
                        // TH từ khóa chứa nhóm dịch vụ ta truyền mảng service và mảng từ khóa search vào hàm filterOnlySearch
                        // console.log(filteredOutput, "filteredOutput")
                        const filteredArray = filterServicesSearch(
                            kafa,
                            filteredOutput,
                        );
                        setListServicesShow(filteredArray);
                    }
                }
            } else {
                setInitialState(true);
                setInputError(true);
            }
        }
    };

    const handleOnclickSearch = () => {
        setSelectService([]);
        // tách tiêu đề services thành 1 mảng
        const lowercaseTitles = listService.map(function (service) {
            return removeVietnameseTonesLowCase(service.title);
        });
        // logic lọc
        // set trạng thái ban đầu = false
        setInitialState(false);
        // kiểm tra xem có chứa các cụm từ trong dấu ngoăc không ?
        // Tách chuỗi thành các phần tử trong mảng
        if (search) {
            // check trường hợp gõ đúng tên dịch vụ và tìm kiếm
            let nameSake = false;
            const filteredServices: any = listService.filter((service: any) => {
                if (
                    removeVietnameseTonesLowCase(service.title) ===
                        removeVietnameseTonesLowCase(search) &&
                    !search.startsWith('"') &&
                    !search.endsWith('"')
                ) {
                    setSelectService([service.order]);
                    nameSake = true;
                    setSearch('"' + search + '"');
                    const filteredArray = filterOnlyServices([service.order]);
                    setListServicesShow(filteredArray);
                }
            });
            if (!nameSake) {
                const output = search
                    .match(/"([^"]+)"|\S+/g) // Tách chuỗi thành mảng các từ và các từ trong ngoặc kép
                    ?.map((str: any) =>
                        str.replace(/"/g, '').trim().toLowerCase(),
                    ) // Xử lý các phần tử trong mảng
                    .filter((str: any) => str !== ''); // Loại bỏ các phần tử trống
                // console.log(output, "output");
                // Nếu chứa phần tử dịch vụ thì sẽ bật dịch vụ đó sáng và set dữ liệu dịch vụ đó đc chọn
                // console.log(listService, "listService");
                // Kiểm tra sự trùng lặp với trường "title" của các dịch vụ, nếu trùng thì sẽ cho cách dịch vụ đó sáng lên
                const kafa: any[] = [];
                if (output && output.length > 0) {
                    output.forEach((outputItem) => {
                        const foundService = listService.find(
                            (service: IListService) =>
                                removeVietnameseTonesLowCase(service.title) ===
                                removeVietnameseTonesLowCase(outputItem),
                        );
                        if (
                            foundService &&
                            !kafa.includes(foundService.order)
                        ) {
                            kafa.push(foundService.order);
                        }
                    });
                    setSelectService(kafa);
                }
                let foundMatch = output?.every((outputItem) => {
                    return lowercaseTitles.includes(
                        removeVietnameseTonesLowCase(outputItem),
                    );
                });
                foundMatch = foundMatch !== false;
                if (foundMatch) {
                    setSelectService(kafa);
                    const filteredArray = filterOnlyServices(kafa);
                    setListServicesShow(filteredArray);
                }
                // chia trường hợp lọc tại đây: nếu có viết dịch vụ , chỉ search từ khóa
                else if (kafa.length === 0) {
                    // TH từ khóa không chứa nhóm dịch vụ ta truyền mảng vào hàm filterOnlySearch
                    const filteredArray = filterOnlySearch(output);
                    // console.log(filteredArray, "filteredArray")
                    setListServicesShow(filteredArray);
                } else {
                    const filteredOutput = output?.filter(
                        (item) =>
                            !lowercaseTitles.includes(
                                removeVietnameseTonesLowCase(item),
                            ),
                    );
                    // TH từ khóa chứa nhóm dịch vụ ta truyền mảng service và mảng từ khóa search vào hàm filterOnlySearch
                    // console.log(filteredOutput, "filteredOutput")
                    const filteredArray = filterServicesSearch(
                        kafa,
                        filteredOutput,
                    );
                    setListServicesShow(filteredArray);
                }
            }
        } else {
            setInitialState(true);
            setInputError(true);
        }
    };

    const onHandleClickShowServiceBefore = (value: boolean) => {
        const listServicesId: any = [];

        const listUsedServicesGroupId: any = [];

        listUsedServices.map((item) =>
            item.serviceGroups.map((svGroup) =>
                listUsedServicesGroupId.push(svGroup.groupId),
            ),
        );
        const newTitle =
            common.lang === 'vi'
                ? `"Các dịch vụ bạn đã dùng trước đây"`
                : `"Your used services"`;
        if (value) {
            setSearch(`${search ? `${search} ` : ''}${newTitle}`);
            setInitialState(false);
            const listServiceGroups: IListService[] = [...listUsedServices];

            if (search && listServiceGroups.length && listServicesShow.length) {
                const newListServiceShow = listServicesShow[0].serviceGroups.filter(
                    (svGroup) =>
                        !listUsedServicesGroupId.includes(svGroup.groupId),
                );
                listServiceGroups[0] = {
                    ...listServiceGroups[0],
                    serviceGroups: [
                        ...listServiceGroups[0].serviceGroups,
                        ...newListServiceShow,
                    ],
                };
            }

            listServiceGroups.map((item) =>
                item.serviceGroups.map((svGroup) =>
                    svGroup.services.map((sv) =>
                        listServicesId.push(sv.serviceId),
                    ),
                ),
            );
            setListServicesShow(listServiceGroups);
            const newListServiceRule = listService.map((cate) => ({
                ...cate,
                serviceGroups: cate.serviceGroups
                    .map((svGroup) => ({
                        ...svGroup,
                        services: svGroup.services.filter(
                            (sv) => !listServicesId.includes(sv.serviceId),
                        ),
                    }))
                    .filter((svGroup) => svGroup.services.length),
            }));
            setListServiceRule(newListServiceRule);
        } else {
            setSearch(search.replace(newTitle, ''));
            if (selectService.length) {
                const filteredArray = filterOnlyServices(selectService);

                const serviceGroupsData = filteredArray
                    .filter(
                        (item: any) =>
                            item?.serviceGroups && item?.serviceGroups?.length,
                    )
                    .map((item: any) => item.serviceGroups)
                    .flat();
                const data = [
                    {
                        categoryName: 'Kết quả tìm kiếm',
                        order: 0,
                        listService: serviceGroupsData,
                        serviceGroups: serviceGroupsData,
                        title: 'Kết quả tìm kiếm',
                    },
                ];
                setListServicesShow(data);
            } else {
                setListServicesShow(listService);
                setInitialState(true);
            }
        }

        setIsShowListUsedServices(value);
    };

    const handleClearSearch = () => {
        setInitialState(true);
        setSearch('');
        setSelectService([]);
        // gán rule để hiển thị
        setListServicesShow(listService);
        setIsShowListUsedServices(false);
    };

    useEffect(() => {
        if (listServicesShow && listServicesShow.length > 0) {
            const totalServices = listServicesShow.reduce(
                (total: any, item: any) =>
                    total + item?.serviceGroups?.length || 0,
                0,
            );
            setTottalServiceShow(totalServices);
        }
    }, [listServicesShow]);

    useEffect(() => {
        const isBottom = (el: any) => {
            return el && el?.getBoundingClientRect()?.bottom <= -10;
        };

        const trackScrolling = () => {
            const wrappedElement = document.getElementById('service-search');
            if (isAffix && isBottom(wrappedElement)) return;
            if (isBottom(wrappedElement)) {
                setIsAffix(true);
            } else {
                setIsAffix(false);
            }
        };
        window.addEventListener('scroll', trackScrolling);

        return () => window.removeEventListener('scroll', trackScrolling);
    }, []);

    return (
        <>
            <div
                id="service-search"
                className={`service-search px-2.5 ${
                    isAffix
                        ? 'bg-white py-2 shadow-[0px_-2px_10px_0px_rgba(0,0,0,0.1)]'
                        : ''
                }`}
            >
                <div className="search__input mb-4">
                    <Input
                        placeholder={
                            common.lang === 'vi'
                                ? 'Tìm kiếm dịch vụ, nhóm dịch vụ'
                                : 'Search for services'
                        }
                        suffix={
                            search && (
                                <div
                                    onClick={handleClearSearch}
                                    aria-hidden="true"
                                    className="cursor-pointer flex items-center"
                                >
                                    <img
                                        src={icclose}
                                        alt=""
                                        className="w-4 h-4 cursor-pointer"
                                    />
                                </div>
                            )
                        }
                        prefix={
                            <div
                                onClick={handleOnclickSearch}
                                aria-hidden="true"
                                className="cursor-pointer flex items-center"
                            >
                                <img src={icsearch} alt="" />
                            </div>
                        }
                        className={inputError ? 'input-error' : ''}
                        onChange={(e) => handleOnChangeSearch(e.target.value)}
                        value={search}
                        onKeyPress={handleSubmitSearch}
                    />
                    {inputError && (
                        <span className="search__error">
                            {common.lang === 'vi'
                                ? 'Bạn vui lòng điền đủ ký tự trước khi bấm tìm kiếm nhé'
                                : 'Please enter valid input'}
                        </span>
                    )}
                </div>
            </div>

            <div
                className={`service-search px-2.5 fixed left-1/2 -translate-x-1/2 z-[999] max-w-[500px] w-full bg-white py-2 shadow-[0px_-2px_10px_0px_rgba(0,0,0,0.1)] ${
                    isAffix && scrollDir === 'up'
                        ? 'top-0 animate-slideShow'
                        : 'top-[-30%] animate-slideHide'
                }`}
            >
                <div className="search__input mb-4">
                    <Input
                        placeholder={
                            common.lang === 'vi'
                                ? 'Tìm kiếm dịch vụ, nhóm dịch vụ'
                                : 'Search for services'
                        }
                        suffix={
                            search && (
                                <div
                                    onClick={handleClearSearch}
                                    aria-hidden="true"
                                    className="cursor-pointer flex items-center"
                                >
                                    <img
                                        src={icclose}
                                        alt=""
                                        className="w-4 h-4 cursor-pointer"
                                    />
                                </div>
                            )
                        }
                        prefix={
                            <div
                                onClick={handleOnclickSearch}
                                aria-hidden="true"
                                className="cursor-pointer flex items-center"
                            >
                                <img src={icsearch} alt="" />
                            </div>
                        }
                        className={inputError ? 'input-error' : ''}
                        onChange={(e) => handleOnChangeSearch(e.target.value)}
                        value={search}
                        onKeyPress={handleSubmitSearch}
                    />
                    {inputError && (
                        <span className="search__error">
                            {common.lang === 'vi'
                                ? 'Bạn vui lòng điền đủ ký tự trước khi bấm tìm kiếm nhé'
                                : 'Please enter valid input'}
                        </span>
                    )}
                </div>
            </div>
        </>
    );
};

export default SearchBar;
