import React, { FC, Fragment, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import {
    removeDuplicateSalon,
    distanceBetween,
    setQuery,
} from 'utils/BookingUltils';
import API from 'repository';
import trackingConst from 'config/TrackingConst';
import direct from 'images/icons/navigate-d8.svg';
import './styles.scss';
import { IDataModalSalonOff, ISalon } from '../../types/Salon';

interface IProps extends RouteComponentProps {
    visible: boolean;
    data: IDataModalSalonOff;
    setVisible: Function;
    isCloseable?: boolean;
    trackingData: any;
    phoneBooking: string;
    listSalon: ISalon[];
    onChangeSalon: Function;
    selected: any;
}

const ModalSalonOff: FC<IProps> = ({
    visible,
    data,
    setVisible,
    isCloseable = true,
    trackingData,
    phoneBooking,
    listSalon,
    onChangeSalon,
    selected,
    ...props
}) => {
    const { history } = props;
    const { salonId, salonBackups, message, screenId } = data;

    const findSalonByIds = (ids: number[]): ISalon[] => {
        const listSalons = listSalon.filter((item: ISalon) =>
            ids.includes(item.id),
        );
        const selectedSalon = [...listSalon].find(
            (item: ISalon) => salonId === item.id,
        );
        const point: { longitude: number; latitude: number } = {
            longitude: selectedSalon ? selectedSalon.longitude : 0,
            latitude: selectedSalon ? selectedSalon.latitude : 0,
        };
        const list = removeDuplicateSalon(listSalons);
        return list
            .map((item: ISalon) => {
                return {
                    ...item,
                    distance: distanceBetween(
                        { longitude: item.longitude, latitude: item.latitude },
                        point,
                    ),
                };
            })
            .sort((a: ISalon, b: ISalon) => {
                return a.distance - b.distance;
            });
    };

    const listSalonBackups = findSalonByIds(salonBackups);

    // TRACKING
    const postTracking = async (event: any, salonBackUp?: any) => {
        try {
            const dataTracking: any = {
                pageId: trackingConst.Pages.BOOKING_SINGLE_PAGE,
                screenId,
                eventId:
                    event === 1
                        ? trackingConst.Events.SHOW_POPUP_SALON_OFF
                        : event === 2
                        ? trackingConst.Events.CLOSE_POPUP_SALON_OFF
                        : trackingConst.Events
                              .CLICK_SELECT_SALON_BACKUP_POPUP_SALON_OFF,
                tokenKey: trackingData.token,
                source: trackingData.source,
                utmSource: trackingData.utmSource,
                data: {
                    phone: phoneBooking,
                    customerId: trackingData.customerId || 0,
                    salonId: salonId,
                    salonIdBackUp: salonBackUp
                        ? salonBackUp
                        : salonBackups.length > 0
                        ? JSON.stringify(salonBackups)
                        : 0,
                },
            };
            API.postTrackingDynamic(dataTracking);
        } catch (e) {
            console.log(e.message);
        }
    };

    const onHandleClickSalon = (item: ISalon) => {
        postTracking(3, item.id);
        setVisible();
        onChangeSalon(item);
        const params = setQuery(selected, {
            salonId: 0,
        });
        history.push({
            pathname: '/booking',
            search: params,
        });
        // history.replace({
        //     pathname: '/booking',
        //     search: params,
        // });
    };

    const closeModal = () => {
        setVisible();
        postTracking(2);
    };

    const handleGotoSalonScreen = () => {
        setVisible();
        postTracking(2);
    };

    const renderSalonBackup = () => {
        return (
            <>
                <div className="h3" style={{ margin: '5px 0', fontSize: 14 }}>
                    Các lựa chọn khác (nếu có):
                </div>
                {listSalonBackups.map((item: ISalon) => {
                    return (
                        <div
                            key={item.id}
                            onClick={() => onHandleClickSalon(item)}
                            className="item padding-5 flex box-salon-off"
                            role="presentation"
                        >
                            <div className="padding-5">
                                <div className="h3 bold">{item.addressNew}</div>
                                {item.distance > 0 && (
                                    <div className="distance">
                                        <img src={direct} alt="" />
                                        <span>Cách {item.distance} km</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })}
            </>
        );
    };

    const renderButtonToSalonScreen = () => {
        return (
            <div
                onClick={handleGotoSalonScreen}
                className="padding-10 w-full bg-30s text-center f-oswald pointer mg-top-10"
            >
                Quay lại trang chọn salon
            </div>
        );
    };

    useEffect(() => {
        if (salonId > 0 && visible) {
            postTracking(1);
        }
    }, [visible, salonId]);

    return (
        <Modal
            visible={visible}
            className="modal-salon-backup"
            onCancel={closeModal}
            closable={isCloseable}
            maskClosable={isCloseable}
            width={480}
            footer={null}
            title="THÔNG BÁO"
        >
            <Fragment>
                <div className="message h3 text-center">{message}</div>
                {salonBackups && salonBackups.length > 0
                    ? renderSalonBackup()
                    : renderButtonToSalonScreen()}
            </Fragment>
        </Modal>
    );
};

const mapStateToProps = (state: any) => {
    const { trackingData, salon } = state;
    return {
        trackingData,
        listSalon: salon.listSalon,
    };
};

export default withRouter(connect(mapStateToProps)(ModalSalonOff));
