import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import { setBanner } from 'actions/banner';
import trackingConst from 'config/TrackingConst';
import API from 'repository';
import LayoutService from 'components/layout/service';
import LayoutSalonAddress from 'components/layout/salonAddress';
import LayoutShop from 'components/layout/shop';
import LayoutBlog from 'components/layout/blog';
import LayoutMedia from 'components/layout/media';
import LayoutVideoSocial from 'components/layout/videoSocial';
import Layout30shineAngels from 'components/layout/30shineAngels';
import LayoutBlogMoyocare from 'components/layout/30shinecare';
import LayoutHeadContent from 'components/layout/headContent';
import LayoutServiceGrid from 'components/layout/serviceGrid';
import LayoutBlock1 from 'components/layout/moyoLayout/type1';
import MoyoServiceLayout from 'components/layout/moyoLayout/service';
import MoyoServiceLayout2 from 'components/layout/moyoLayout/sercice2';
import MoyoSlide from 'components/layout/moyoLayout/moyoSlide';

import Banner from './banner';
import BookingInformation from './bookingInfomation';
import LoadingSkeleton from './loading';
import './index.scss';

const Home: React.FC<{
    trackingData: any;
    userPhone: string;
    dataBanner: any;
    setDataBanner: Function;
}> = ({ trackingData, userPhone, dataBanner, setDataBanner }) => {
    const [homepageConfig, setHomepageConfig] = useState<any>([]);
    const getHomePageConfig = async () => {
        try {
            const data: any = await API.getHomepageConfig();
            setHomepageConfig(data);
        } catch (er) {
            console.log(er);
        }
    };
    useEffect(() => {
        getHomePageConfig();
    }, []);
    // const size: any = useWindowSize();
    // const isMobile = size.width < 768;

    async function postTracking(eventId: string) {
        try {
            const dataTracking = {
                pageId: trackingConst.Pages.HOME_PAGE,
                screenId: trackingConst.Screens.HOME_SCREEN,
                eventId,
                tokenKey: trackingData.token,
                source: trackingData.source,
                utmSource: trackingData.utmSource,
                data: {
                    phone: trackingData.phoneNumber || '',
                    customerId: trackingData.customerId || 0,
                },
            };
            await API.postTrackingDynamic(dataTracking);
        } catch (e) {
            console.log(e.message);
        }
    }

    const fetchBanner = async () => {
        try {
            const response = await API.getBanner();
            if (response) {
                setDataBanner(response);
            }
        } catch (er) {
            console.log(er);
        }
    };

    const renderLayout = (item: any) => {
        const key = `home-block-${item.order}`;
        switch (item.layout) {
            case 'service':
                return <LayoutService data={item} key={key} />;
            case 'shop':
                return <LayoutShop data={item} key={key} />;
            case 'blog':
                return <LayoutBlog data={item} key={key} />;
            case 'media':
                return <LayoutMedia data={item} key={key} />;
            case 'videoSocial':
                return <LayoutVideoSocial data={item} key={key} />;
            case '30shineAngels':
                return <Layout30shineAngels data={item} key={key} />;
            case 'salonAddress':
                return <LayoutSalonAddress data={item} key={key} />;
            case '30shinecare':
                return <LayoutBlogMoyocare data={item} key={key} />;
            case 'head':
                return <LayoutHeadContent data={item} key={key} />;
            case 'serviceGrid':
                return <LayoutServiceGrid data={item} key={key} />;
            case 'moyoBlock1':
                return <LayoutBlock1 data={item} key={key} />;
            case 'serviceRelax':
                return <MoyoServiceLayout data={item} key={key} />;
            case 'serviceRecover':
                return <MoyoServiceLayout2 data={item} key={key} />;
            case 'moyoSlide':
                return <MoyoSlide data={item} key={key} />;
            default:
                break;
        }
    };

    useEffect(() => {
        postTracking(trackingConst.Events.GO_TO_HOME_PAGE);
        if (dataBanner.length === 0) {
            fetchBanner();
        }
        $('html, body').animate({ scrollTop: 0 }, 0);
    }, []);

    return (
        <>
            <div className="home">
                <Banner />
                <BookingInformation />
                {homepageConfig.length > 0 ? (
                    homepageConfig.map((item: any) => {
                        return renderLayout(item);
                    })
                ) : (
                    <div className="container">
                        <LoadingSkeleton />
                    </div>
                )}
            </div>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        trackingData: state.trackingData,
        userPhone: state.user.phoneNumber,
        dataBanner: state.dataBanner,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setDataBanner: (value: any) => dispatch(setBanner(value)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
