import React, { FC, useEffect, useState } from 'react';
import { removeVietnameseTones } from 'utils/validator';
import { IProvince } from '../types/CityDistrict';

type RegionType = {
    id: number;
    name: string;
    cities: IProvince[];
};

const ListRegions: FC<{
    onClickCityInRegion: Function;
    listProvince: IProvince[];
}> = ({ onClickCityInRegion, listProvince }) => {
    const [list, setList] = useState<RegionType[]>([]);

    const getTitleRegion = (regionId: number) => {
        let title = '';
        switch (regionId) {
            case 1:
                title = 'Miền Bắc';
                break;
            case 2:
                title = 'Miền Trung';
                break;
            case 3:
                title = 'Miền Nam';
                break;
            default:
                title = 'Moyo có mặt trên các tỉnh thành:';
                break;
        }
        return title;
    };

    useEffect(() => {
        if (listProvince.length) {
            const newList: RegionType[] = Object.values(
                listProvince.reduce((acc: any, el: any) => {
                    acc[el?.vungMienId] = acc[el?.vungMienId] || {
                        id: el?.vungMienId || 0,
                        name: getTitleRegion(el?.vungMienId || 0),
                        cities: [],
                    };
                    if (el?.totalSalon > 0) {
                        acc[el.vungMienId].cities.push(el);
                    }
                    return acc;
                }, {}),
            );
            setList(
                newList
                    .sort((a, b) => a.id - b.id)
                    .map((item) => ({
                        ...item,
                        cities: item.cities.sort((a, b) => {
                            const aCityName = removeVietnameseTones(a.cityName);
                            const bCityName = removeVietnameseTones(b.cityName);
                            if (aCityName < bCityName) {
                                return -1;
                            }
                            if (aCityName > bCityName) {
                                return 1;
                            }
                            return 0;
                        }),
                    })),
            );
        }
    }, [listProvince]);

    return (
        <div className="salon__regions min-height-455">
            {list.length > 0 &&
                list.map((region, index: number) => (
                    <div className="regions" key={index}>
                        {region.name ? (
                            <div className="regions__title show">
                                {region.name}
                            </div>
                        ) : null}
                        <div className="regions__cities show">
                            {region.cities.map((city, index: number) => (
                                <div
                                    className="cities__name pointer"
                                    onClick={() => onClickCityInRegion(city)}
                                    key={index}
                                    role="presentation"
                                >
                                    {city.cityName}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
        </div>
    );
};

export default ListRegions;
