import React, {
    useEffect,
    useState,
    FC,
    ReactNode,
    useRef,
    Fragment,
} from 'react';

const BoxShadown: FC<{ children: ReactNode; isShow: boolean }> = ({
    children,
    isShow,
}) => {
    const [show, setShow] = useState(isShow);
    const time = useRef<any>();
    useEffect(() => {
        if (!isShow) {
            time.current = setTimeout(() => {
                setShow(false);
            }, 500);
        } else {
            if (time) clearTimeout(time.current);
            setShow(true);
        }
    }, [isShow]);

    return <Fragment>{show && children}</Fragment>;
};

export default BoxShadown;
