import React, { FC, useEffect, useState } from 'react';
import moment from 'moment';
import 'moment/locale/vi';

const ItemBoxTimeGB: FC<{
    hourData: any;
    setListSlotSelected: Function;
    listSlotSelected: any;
    isFull: boolean;
}> = ({ hourData, setListSlotSelected, listSlotSelected, isFull }) => {
    const handleOnClick = (
        bookHourId: number,
        subHourId: number,
        secondSubHourId: number,
        indexSlot: number,
    ) => {
        const newId = Number(`${secondSubHourId}${indexSlot}`);

        if (listSlotSelected?.find((item: any) => item?.index === newId)) {
            const newListSelected = listSlotSelected.filter(
                (item: any) => item.index !== newId,
            );
            setListSlotSelected(newListSelected);
        } else {
            if (isFull) return;
            updateList({
                bookHourId,
                subHourId,
                secondSubHourId,
                index: newId,
            });
        }
    };

    const updateList = (newList: any) => {
        if (newList) {
            setListSlotSelected([
                ...listSlotSelected,
                {
                    bookHourId: newList.bookHourId,
                    subHourId: newList.subHourId,
                    secondSubHourId: newList.secondSubHourId,
                    index: newList.index,
                },
            ]);
        }
    };

    useEffect(() => {
        if (listSlotSelected) {
        }
    }, [listSlotSelected]);
    return (
        <>
            {hourData && (
                <div className="flex flex-col items-center justify-center pt-2 pb-4 border-2 rounded-md">
                    <div className="text-lg font-medium oswald">
                        {moment(hourData?.hourFrame, 'HH:mm:ss').format(
                            'HH:mm',
                        )}
                    </div>
                    <div className="text-xs text-[#BABABA] font-semibold">
                        {hourData.totalEmpty} chỗ
                    </div>
                    <div className="flex flex-col items-center justify-center mt-2">
                        {Array.from(
                            { length: hourData.totalEmpty },
                            (_, index) => (
                                <div className="relative py-1">
                                    <img
                                        className="w-[50px] h-[50px]"
                                        key={index}
                                        onClick={() =>
                                            handleOnClick(
                                                hourData.hourId,
                                                hourData.subHourId,
                                                hourData.secondSubHourId,
                                                index,
                                            )
                                        }
                                        src={
                                            listSlotSelected?.find(
                                                (item: any) =>
                                                    item?.index ===
                                                    Number(
                                                        `${hourData.secondSubHourId}${index}`,
                                                    ),
                                            )
                                                ? '/IconGroupBooking-2.png'
                                                : '/IconGroupBooking-1.png'
                                        }
                                    />
                                    <div
                                        className="absolute top-[0px] right-[5px]"
                                        style={{
                                            display: listSlotSelected?.find(
                                                (item: any) =>
                                                    item?.index ===
                                                    Number(
                                                        `${hourData.secondSubHourId}${index}`,
                                                    ),
                                            )
                                                ? ''
                                                : 'none',
                                        }}
                                    >
                                        <div className="bg-[#20489B] rounded-full w-[20px] h-[20px] flex items-center justify-center">
                                            <span className="text-sm text-white">
                                                {listSlotSelected.findIndex(
                                                    (item: any) =>
                                                        item?.index ===
                                                        Number(
                                                            `${hourData.secondSubHourId}${index}`,
                                                        ),
                                                ) + 1}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ),
                        )}
                    </div>
                    </div>
                )}
            </>
        );
    };

export default ItemBoxTimeGB;
