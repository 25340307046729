import StoreClient from './client/StoreClient';

export default {
    // api old home
    getLookBookHomepage() {
        return StoreClient.get('/web/v3/configs/lookbooks.json');
    },
    // end api old home
    getDataHomeLookBook() {
        return StoreClient.get('/web/v3/configs/home-lookbooks.json');
    },
};
