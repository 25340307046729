import Client from './client/StyleMasterClient';

export default {
    getStyleMaster() {
        return Client.get(`api//stylemaster/top-like-in-month-pagesize-1000`);
    },
    getListStyleMaster(params) {
        const stringParams = params ? `?${params}` : '';
        return Client.get(
            `api/appcustomer/stylemaster/top-like-in-month${stringParams}`,
            {
                ...params,
            },
        );
    },
    getStyleMasterMonth() {
        return Client.get(`api/stylemaster/top-like-three-month`);
    },
    setStyleMasterLiked(params) {
        return Client.put(`api/appcustomer/stylemaster/update-vote`, {
            ...params,
        });
    },
    getListLikedStyleMaster(params) {
        const stringParams = params ? `?${params}` : '';
        return Client.get(
            `api/appcustomer/stylemaster/top-nearly-like-post${stringParams}`,
            {
                ...params,
            },
        );
    },

    getListHistoryStyleMaster(params) {
        return Client.get(`api/stylemaster/post-in-month`, {
            params,
        });
    },
    getListLikedHistoryStyleMaster(params) {
        const stringParams = params ? `?${params}` : '';
        return Client.get(
            `api/stylemaster/top-nearly-like-post-v2${stringParams}`,
            {
                ...params,
            },
        );
    },
    setLikedStyleMaster(params) {
        return Client.put(`api/stylemaster/update-vote-v2`, {
            ...params,
        });
    },
};
