import React, { FC, useState, useRef } from 'react';
import Swiper from 'react-id-swiper';
import classNames from 'classnames';
import vectorLeft from 'images/icons/Vector_left.svg';
import vectorRight from 'images/icons/Vector_right.svg';
import { Button } from 'antd';
import './index.scss';
import '../index.scss';
import LayoutHeadTitleMoyo from '../moyoLayout/title';

const LayoutMedia: FC<{ data: any }> = ({ data }) => {
    const swiperRef = useRef<any>(null);
    const [isFirstSlide, setIsFirstSlide] = useState<boolean>(true);
    const [isLastSlide, setIsLastSlide] = useState<boolean>(false);

    const goNext = (ref: any, lastSlide: Function, firstSlide: Function) => {
        if (ref.current && ref.current.swiper) {
            ref.current.swiper.slideNext();
            firstSlide(ref.current.swiper.isBeginning);
            lastSlide(ref.current.swiper.isEnd);
        }
    };

    const goPrev = (ref: any, lastSlide: Function, firstSlide: Function) => {
        if (ref.current && ref.current.swiper) {
            ref.current.swiper.slidePrev();
            firstSlide(ref.current.swiper.isBeginning);
            lastSlide(ref.current.swiper.isEnd);
        }
    };

    const handleSlideChange = (
        ref: any,
        lastSlide: Function,
        firstSlide: Function,
    ) => {
        lastSlide(ref.current.swiper.isEnd);
        firstSlide(ref.current.swiper.isBeginning);
    };

    const classButtonPrev = classNames('swiper_button button_prev', {
        swiper_button_disable: isFirstSlide,
    });

    const classButtonNext = classNames('swiper_button button_next', {
        swiper_button_disable: isLastSlide,
    });

    const paramsSwiper = {
        spaceBetween: 30,
        breakpoints: {
            768: {
                slidesPerView:
                    data.slidePerViewPC > 0 ? data.slidePerViewPC : 4,
                spaceBetween: 30,
            },
            0: {
                slidesPerView:
                    data.slidePerViewMB > 0 ? data.slidePerViewMB : 2.3,
                spaceBetween: 16,
            },
        },
        on: {
            slideChange: () =>
                handleSlideChange(swiperRef, setIsLastSlide, setIsFirstSlide),
            touchMove: () =>
                handleSlideChange(swiperRef, setIsLastSlide, setIsFirstSlide),
            init: () =>
                handleSlideChange(swiperRef, setIsLastSlide, setIsFirstSlide),
        },
        draggable: true,
    };

    const isShowButtonSwiper =
        data.list.length > Math.round(data.slidePerViewPC);

    return (
        <div className="layout layout-media">
            <div className="container">
                <LayoutHeadTitleMoyo data={data} />

                {data.list.length > 0 && (
                    <div className="relative media__wrapper">
                        <Swiper {...paramsSwiper} ref={swiperRef}>
                            {data.list.map((item: any) => (
                                <a
                                    className="relative block pointer"
                                    key={item.id}
                                    target="_blank"
                                    rel="noreferrer"
                                    href={item.redirectLink}
                                >
                                    <div className="wrapper__img pointer aspect-[9/16] overflow-hidden">
                                        <img src={item.linkImage} alt="" />
                                    </div>
                                    <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                                        <img
                                            src="/video-social-play.png"
                                            alt=""
                                        />
                                    </div>
                                </a>
                            ))}
                        </Swiper>
                        {isShowButtonSwiper && (
                            <>
                                <Button
                                    className={classButtonPrev}
                                    shape="circle"
                                    disabled={isFirstSlide}
                                    onClick={() =>
                                        goPrev(
                                            swiperRef,
                                            setIsLastSlide,
                                            setIsFirstSlide,
                                        )
                                    }
                                >
                                    <img src={vectorLeft} alt="left" />
                                </Button>
                                <Button
                                    className={classButtonNext}
                                    shape="circle"
                                    disabled={isLastSlide}
                                    onClick={() =>
                                        goNext(
                                            swiperRef,
                                            setIsLastSlide,
                                            setIsFirstSlide,
                                        )
                                    }
                                >
                                    <img src={vectorRight} alt="right" />
                                </Button>
                            </>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default LayoutMedia;
