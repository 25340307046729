import React, { FC, memo } from 'react';
import { Switch } from 'antd';
import check from 'images/fillWhite/check.svg';
import close from 'images/fillGray/close.svg';
import trackingConst from 'config/TrackingConst';
import api from 'repository';

const FilterParking: FC<{
    setIsChecked: Function;
    isChecked: boolean;
    trackingData: any;
}> = memo(({ setIsChecked, isChecked, trackingData }) => {
    const postTracking = (data: any) => {
        try {
            api.postTrackingDynamic(data);
        } catch (e) {
            console.log(e.message);
        }
    };

    const onChangeSwitch = (value: boolean) => {
        setIsChecked(value);

        // TRACKING
        const dataTracking = {
            pageId: trackingConst.Pages.BOOKING_SINGLE_PAGE,
            screenId: trackingConst.Screens.BOOKING_SELECT_SALON_SCREEN,
            eventId: trackingConst.Events.SWITCH_CAR_PARKING,
            tokenKey: trackingData.token,
            source: trackingData.source,
            utmSource: trackingData.utmSource,
            data: {
                customerId: trackingData.customerId || 0,
            },
        };

        postTracking(dataTracking);
    };

    return (
        <div className="salon__box-switch order-2">
            <div className="box-switch">
                <span className="box-switch__text">
                    Có chỗ đậu ô tô{' '}
                    <span className="text__gray">(Có thể mất phí)</span>
                </span>
                <Switch
                    className="box-switch__button"
                    onClick={(e) => onChangeSwitch(e)}
                    checked={isChecked}
                    checkedChildren={
                        <div className="box-switch__checked content-center-middle">
                            <img src={check} alt="" />
                        </div>
                    }
                    unCheckedChildren={
                        <div className="box-switch__unChecked content-center-middle">
                            <img src={close} alt="" />
                        </div>
                    }
                />
            </div>
        </div>
    );
});

export default FilterParking;
