import React from 'react';
import SalonItem from './SalonItem';

const GroupSalon: React.FC<{
    title: string;
    salons: any;
    bodyClass: string;
}> = ({ title, salons, bodyClass }) => {
    return salons.length > 0 ? (
        <div className="flex flex-col items-center justify-center">
            <div className="text-center font-semibold text-base bg-[#EF4E24] rounded-md w-max py-1 px-2 mb-4">
                {title}
            </div>
            <div className={bodyClass}>
                {salons.map((salon: any) => (
                    <SalonItem salon={salon} />
                ))}
            </div>
        </div>
    ) : null;
};

export default GroupSalon;
