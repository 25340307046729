/* eslint-disable no-nested-ternary */
import React, { FC, useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { Modal, Button, Input } from 'antd';
import { setQuery } from 'utils/BookingUltils';
import { validatePhone } from 'utils/validator';
import iconClose from 'images/icons/close.svg';
import iconCongratulation from 'images/icons/icon-congratulation.png';
import { formatPhone } from 'utils/NumberUtils';
import trackingConst from 'config/TrackingConst';
import './style.scss';
import API from 'repository';
import KEY from '../../Const';

interface Props extends RouteComponentProps {
    trackingData: any;
    role: any;
}

interface IModalBooking extends Props {
    visible: boolean;
    userPhone: any;
    setShowModalBooking: Function;
    selected: any;
    onChangeSelectedPhone: Function;
    getRoleMember: Function;
    inApp: boolean;
    userName: string;
}

const ModalBooking: FC<IModalBooking> = ({
    visible,
    userPhone,
    setShowModalBooking,
    selected,
    onChangeSelectedPhone,
    getRoleMember,
    inApp,
    userName,
    ...props
}) => {
    const common = useSelector((state: any) => state.common);
    const [phone, setPhone] = useState({ value: '', error: '' });
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState<number>(0);
    const [name, setName] = useState<string>('');
    const [nameError, setNameError] = useState<string>('');
    const [isCancelBookingName, setIsCancelBookingName] = useState(false);
    const { trackingData, role, history } = props;

    const postTracking = (data: any) => {
        try {
            API.postTrackingDynamic(data);
        } catch (e) {
            console.log(e.message);
        }
    };

    const handleTracking = (type: any) => {
        // tracking
        const params = {
            pageId: trackingConst.Pages.BOOKING_SINGLE_PAGE,
            screenId:
                selected.step === 1
                    ? trackingConst.Screens.BOOKING_SELECT_SALON_SCREEN
                    : selected.step === 2
                    ? trackingConst.Screens.BOOKING_SELECT_SERVICE_SCREEN
                    : trackingConst.Screens
                          .BOOKING_SELECT_BOOKING_SINGLE_SCREEN,
            eventId:
                type === 'GO_HOME'
                    ? trackingConst.Events.GO_TO_HOME_IN_POPUP_INPUT_PHONE
                    : type === 'NAME'
                    ? trackingConst.Events
                          .CLICK_BOOKING_IN_POPUP_INPUT_CUSTOMER_NAME
                    : type === 'CANCEL_INPUT_NAME'
                    ? trackingConst.Events
                          .CLICK_CANCEL_BOOKING_IN_POPUP_INPUT_CUSTOMER_NAME
                    : trackingConst.Events.CLICK_BOOKING_IN_POPUP_INPUT_PHONE,
            tokenKey: trackingData.token,
            source: trackingData.source,
            utmSource: trackingData.utmSource,
            data: {
                salonId: selected[KEY.SALON]
                    ? selected[KEY.SALON].id.toString()
                    : '',
                customerId: trackingData.customerId || 0,
                phone: phone ? phone.value.replace(/\./g, '') : '',
            },
        };
        postTracking(params);
    };

    const handleCancelBookingName = () => {
        if (step === 1) {
            setIsCancelBookingName(true);
            setShowModalBooking(false);
            handleTracking('CANCEL_INPUT_NAME');
        }
    };

    const handleChangePhone = (e: any) => {
        const { value } = e.target;
        const reg = new RegExp('^[.0-9]+$');
        if (reg.test(value) || !value) {
            if (!value) setPhone({ error: '', value: '' });
            if (value[0] === '0' && value[1] !== '0') {
                setPhone({ error: '', value: formatPhone(value) });
            } else {
                setPhone({
                    error: 'Bạn nhập số điện thoại hợp lệ giúp chúng mình nhé!',
                    value: formatPhone(value),
                });
            }
        }
    };

    const validateName = (value: string) => {
        const regexName = /[A-Za-z|à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ|è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ|ì|í|ị|ỉ|ĩ|ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ|ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ|ỳ|ý|ỵ|ỷ|ỹ|đ|À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ|È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ|Ì|Í|Ị|Ỉ|Ĩ|Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ|Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ|Ỳ|Ý|Ỵ|Ỷ|Ỹ|Đ|\s]+$/g;
        if (value.match(regexName)) return '';
        return 'Bạn nhập tên hợp lệ giúp chúng mình nhé!';
    };

    const handleChangeName = (e: any) => {
        let { value } = e.target;
        value = value.replace(/ + /g, ' ');
        // const regexName = /[^A-Za-z|à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ|è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ|ì|í|ị|ỉ|ĩ|ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ|ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ|ỳ|ý|ỵ|ỷ|ỹ|đ|À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ|È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ|Ì|Í|Ị|Ỉ|Ĩ|Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ|Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ|Ỳ|Ý|Ỵ|Ỷ|Ỹ|Đ|\s]/g;
        // value = value.replace(regexName, '');
        setNameError(validateName(value));
        setName(value);
    };

    const handleGoToHome = () => {
        props.history.push('/');
        handleTracking('GO_HOME');
    };

    const handleClickButtonBooking = async () => {
        const customerPhone = phone.value.replace(/\./g, '');
        const errorPhone = validatePhone(phone.value);
        handleTracking('');
        if (errorPhone) {
            setPhone((prevState) => ({ ...prevState, error: errorPhone }));
        } else {
            onChangeSelectedPhone(customerPhone);
            const params = setQuery(selected, { phone: customerPhone });
            history.replace({
                pathname: '/booking',
                search: params,
            });
        }
    };

    const onSubmitBookingName = async () => {
        handleTracking('NAME');
        if (nameError) {
            return;
        }
        if (!name) {
            setIsCancelBookingName(true);
            setShowModalBooking(false);
            return;
        }
        try {
            setLoading(true);
            let customerPhone = '';
            if (
                selected[KEY.PHONE] &&
                selected[KEY.PHONE] !== '0' &&
                selected[KEY.PHONE].length === 10
            ) {
                customerPhone = selected[KEY.PHONE];
            } else if (phone.value.length > 0) {
                customerPhone = phone.value.replace(/\./g, '');
            }
            const errorPhone = validatePhone(customerPhone);
            if (!errorPhone) {
                const data = await API.saveCustomerName({
                    phone: customerPhone,
                    name,
                });
                getRoleMember();
                setShowModalBooking(false);
                setIsCancelBookingName(false);
            }
        } catch (e) {
            handleCancelBookingName();
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (userPhone && phone.value === '') {
            handleChangePhone({ target: { value: userPhone } });
        }
    }, [userPhone]);

    useEffect(() => {
        // TRACKING
        if (visible) {
            const data = {
                pageId: trackingConst.Pages.BOOKING_SINGLE_PAGE,
                screenId:
                    parseInt(selected.step, 10) === 1
                        ? trackingConst.Screens.BOOKING_SELECT_SALON_SCREEN
                        : parseInt(selected.step, 10) === 2
                        ? trackingConst.Screens.BOOKING_SELECT_SERVICE_SCREEN
                        : trackingConst.Screens
                              .BOOKING_SELECT_BOOKING_SINGLE_SCREEN,
                eventId:
                    step === 0
                        ? trackingConst.Events.SHOW_POPUP_INPUT_PHONE
                        : trackingConst.Events.SHOW_POPUP_INPUT_CUSTOMER_NAME,
                tokenKey: trackingData.token,
                source: trackingData.source,
                utmSource: trackingData.utmSource,
                data: {
                    phone: selected[KEY.PHONE],
                    salonId: selected[KEY.SALON]
                        ? selected[KEY.SALON].id.toString()
                        : '',
                    customerId: trackingData.customerId || 0,
                },
            };
            postTracking(data);
        }
    }, [visible, step]);

    useEffect(() => {
        if (role && role.fetched) {
            const isAuthen = localStorage.getItem('accessToken');
            const isUserBooking = userPhone === selected[KEY.PHONE];
            if (!userName && isAuthen && isUserBooking) {
                setShowModalBooking(true);
                if (validatePhone(selected[KEY.PHONE])) {
                    setStep(0);
                } else {
                    setStep(1);
                }
            } else {
                setShowModalBooking(false);
            }
        }
    }, [role, selected[KEY.PHONE], userPhone, userName]);

    return (
        <Modal
            className="modal-booking-v2"
            visible={visible}
            footer={null}
            onCancel={handleCancelBookingName}
            closable={false}
            centered
            width={375}
        >
            {step === 0 && (
                <div>
                    <div className="modal-booking_title">
                        Chào mừng bạn đến với Moyo
                        <img
                            src={iconCongratulation}
                            alt=""
                            className="inline"
                        />
                    </div>
                    <div className="modal-booking_description">
                        {selected[KEY.SKINNER_ID] > 0
                            ? 'Bạn nhập số điện thoại để book skinner bạn vừa chọn nhé!'
                            : 'Bạn vui lòng nhập Số Điện Thoại để tiếp tục đặt lịch'}
                    </div>
                    <Input
                        className={`modal-booking_input-phone ${
                            phone.error ? 'error' : ''
                        }`}
                        type="tel"
                        placeholder="Ví dụ: 0912234xxx"
                        value={phone.value}
                        maxLength={20}
                        onChange={handleChangePhone}
                        onPressEnter={handleClickButtonBooking}
                        autoFocus={!inApp}
                    />
                    <span className="phone-error">{phone.error}</span>
                    <Button
                        onClick={handleClickButtonBooking}
                        className="button-booking"
                        loading={loading}
                        disabled={loading}
                    >
                        TIẾP TỤC
                    </Button>
                    <Button
                        className="link_to_home"
                        onClick={handleGoToHome}
                        type="link"
                        block
                    >
                        Quay lại trang chủ
                    </Button>
                </div>
            )}
            {step === 1 && (
                <div>
                    <div
                        className="close-button"
                        onClick={handleCancelBookingName}
                    >
                        <img src={iconClose} alt="" />
                    </div>
                    <div className="modal-booking_title">
                        Chào mừng bạn đến với Moyo
                        <img
                            src={iconCongratulation}
                            alt=""
                            className="inline"
                        />
                    </div>
                    <div className="modal-booking_description">
                        Bạn cho chúng chúng mình biết tên để tiện xưng hô bạn nha!
                    </div>
                    <Input
                        className={`modal-booking_input-name ${
                            nameError ? 'error' : ''
                        }`}
                        type="text"
                        placeholder="Tên bạn là..."
                        value={name}
                        maxLength={20}
                        onChange={handleChangeName}
                        onPressEnter={onSubmitBookingName}
                        autoFocus={!inApp}
                    />
                    <span className="phone-error">{nameError}</span>
                    <Button
                        onClick={onSubmitBookingName}
                        className="button-booking"
                        loading={loading}
                        disabled={loading}
                    >
                        ĐẶT LỊCH NGAY
                    </Button>
                    <Button
                        className="link_to_home"
                        onClick={handleCancelBookingName}
                        type="link"
                        block
                    >
                        Bỏ qua
                    </Button>
                </div>
            )}
        </Modal>
    );
};

const mapStateToProps = (state: any) => {
    const { trackingData, role, common, user } = state;
    return {
        trackingData,
        role,
        inApp: common.inApp,
        userName: user.userName,
    };
};

export default withRouter(connect(mapStateToProps)(ModalBooking));
