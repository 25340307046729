import React, { FC } from 'react';
import { Skeleton } from 'antd';
import ItemServices from './ItemServices';
import { IListService } from '../../types/Service';

const ListCategoriesServices: FC<{
    isLoading: boolean;
    item: IListService;
    selected: any;
    onClickDetailService: Function;
    onSelectService: Function;
    trackingData: any;
    userId: any;
    isSearchResult: boolean;
    layoutDefault: boolean;
    gotoCampaignDetail: Function;
}> = ({
    isLoading,
    item,
    onClickDetailService,
    selected,
    onSelectService,
    trackingData,
    userId,
    isSearchResult,
    layoutDefault,
    gotoCampaignDetail,
}) => {
    return (
        <>
            {isLoading ? (
                <div className="service">
                    <Skeleton paragraph={{ rows: 3, width: '100%' }} />
                </div>
            ) : (
                <>
                    <div className="bg-white">
                        {item.serviceGroups?.length > 0 && (
                            <div
                                className={`service p-2.5 ${
                                    isSearchResult ? 'pt-0' : 'pt-[15px]'
                                } mb-[15px]`}
                            >
                                {!isSearchResult && (
                                    <div className="service__category text-[#20489B] flex flex-wrap items-baseline gap-y-2.5 pb-2.5">
                                        <div className="text-base font-semibold pr-2.5">
                                            {item.categoryName}
                                        </div>
                                        <div className="text-sm font-medium">
                                            {item.title}
                                        </div>
                                    </div>
                                )}

                                {item.serviceGroups?.length ? (
                                    <div className="grid grid-cols-2 gap-4">
                                        {item.serviceGroups.map((service) => {
                                            return (
                                                <ItemServices
                                                    serviceGroup={service}
                                                    chosenService={
                                                        selected.chosenService
                                                    }
                                                    onClickDetailService={
                                                        onClickDetailService
                                                    }
                                                    onSelectService={
                                                        onSelectService
                                                    }
                                                    selected={selected}
                                                    trackingData={trackingData}
                                                    userId={userId}
                                                    layoutDefault={
                                                        layoutDefault
                                                    }
                                                    key={service.groupId}
                                                    gotoCampaignDetail={gotoCampaignDetail}
                                                />
                                            );
                                        })}
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        )}
                    </div>
                </>
            )}
        </>
    );
};

export default ListCategoriesServices;
