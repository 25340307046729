import React, { useState, useEffect, Fragment } from 'react';
import { withRouter } from 'react-router';
import { formatPhone } from 'utils/NumberUtils';
import { notification } from 'antd';
import API from 'repository';
import { connect, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import trackingConst from 'config/TrackingConst';
import './style.scss';
import ModalConfirmPolicy from 'components/ModalConfirmPolicy';
import { useCookies } from 'react-cookie';
import { getQuery } from 'utils/BookingUltils';

const Placeholder = 'Nhập SĐT để đặt lịch';

const InputPhone = (props) => {
    const {
        trackingData,
        userId,
        memberType,
        agreeProtectPersonalInfo,
    } = props;
    const [phone, setPhone] = useState('');
    const [cookies, setCookie] = useCookies(['bookingPhone']);
    const [showModalConfirmPolicy, setShowModalConfirmPolicy] = useState(false);
    const common = useSelector((state) => state.common);
    const [memberClass, setMemberClass] = useState('');

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const addClass = () => {
        const el = document.getElementsByClassName('my-input')[0];
        el.classList.add('phone-null');
    };
    const removeClass = () => {
        const el = document.getElementsByClassName('phone-null')[0];
        if (el) el.classList.remove('phone-null');
    };

    const showMessage = (message) => {
        notification.close('my-notifice');
        const config = {
            message: 'Lỗi',
            description: message,
            duration: 3,
            className: 'error',
            key: 'my-notifice',
        };
        addClass();
        return notification.error(config);
    };

    const onchangeData = (e) => {
        const { value } = e.target;
        const _phone = formatPhone(value);

        if (_phone.length < 13) {
            removeClass();
            setPhone(_phone);
        }
    };

    const postTracking = async () => {
        const dataTracking = {
            pageId: trackingConst.Pages.HOME_PAGE,
            screenId: trackingConst.Screens.HOME_SCREEN,
            eventId: trackingConst.Events.CLICK_BUTTON_BOOKING,
            tokenKey: trackingData.token,
            source: trackingData.source,
            utmSource: trackingData.utmSource,
            data: {
                phone: phone ? phone.replace(/\./g, '') : '',
                customerId: userId || 0,
            },
        };
        try {
            API.postTrackingDynamic(dataTracking);
        } catch (e) {
            console.log(e.message);
        }
    };

    const handleBoongKing = () => {
        const _phone = phone.replace(/\./g, '');
        if (_phone.length === 10 && _phone[0] === '0' && _phone[1] !== '0') {
            if (
                cookies?.bookingPhone === _phone ||
                agreeProtectPersonalInfo ||
                getQuery(window.location.search).inApp === '1' ||
                common.inApp
            ) {
                setShowModalConfirmPolicy(false);
            } else {
                setShowModalConfirmPolicy(true);
                return;
            }
            props.history.push(`/booking?phone=${_phone}&salonId=0&step=0`);
        } else if (_phone.length !== 10) {
            showMessage('Bạn vui lòng nhập đủ 10 số điện thoại nhé!');
        } else {
            showMessage(
                'Bạn vui lòng nhập số điện thoại hợp lệ giúp chúng mình nhé!',
            );
        }
        postTracking();
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 13) handleBoongKing();
    };

    const setValuePhone = (value) => {
        onchangeData({ target: { value } });
    };

    useEffect(() => {
        if (props.phone) {
            setValuePhone(props.phone);
        }
    }, [props.phone]);

    useEffect(() => {
        switch (memberType) {
            case 2:
                setMemberClass('Silver home__form-input');
                break;
            case 3:
                setMemberClass('Gold home__form-input');
                break;
            case 4:
                setMemberClass('Diamond home__form-input');
                break;
            default:
                setMemberClass('home__form-input');
                break;
        }
    }, [memberType]);

    return (
        <Fragment>
            {/* <div className="home__form-input "> */}
            <div className="home__form-input" id="book-now">
                <div className="form-input__slogan">
                    <div className="font-bold uppercase md:text-lg font-['Segoe UI']">
                        TẬN HƯỞNG KHÔNG GIAN GỘI THẾ HỆ MỚI
                    </div>
                    <div className="py-0.5 md:text-lg">
                        Đặt lịch nhanh - Không cần chờ!
                    </div>
                </div>
                <div className="flex mt-1 form-input__form">
                    <div className="form__input">
                        <input
                            placeholder={Placeholder}
                            value={phone}
                            size="large"
                            onChange={onchangeData}
                            onKeyDown={onKeyDown}
                            type="tel"
                            className="my-input"
                        />
                    </div>
                    <div
                        className="form__button btn-action content-center-middle"
                        onClick={handleBoongKing}
                        role="presentation"
                        style={{ background: '#EF4E24' }}
                    >
                        <div className="btn-booking" style={{ color: '#FFF' }}>
                            ĐẶT LỊCH NGAY
                        </div>
                    </div>
                </div>
            </div>
            <ModalConfirmPolicy
                isModalOpen={showModalConfirmPolicy}
                setIsModalOpen={(value) => setShowModalConfirmPolicy(value)}
                redirectBooking={() => {
                    const phoneConvert = phone.replace(/\./g, '');
                    props.history.push(
                        `/booking?phone=${phoneConvert}&salonId=0&step=0`,
                    );
                }}
                bookingPhone={phone.replace(/\./g, '')}
            />
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    const { user, trackingData } = state;
    return {
        phone: user.phoneNumber,
        trackingData,
        userId: user.userId,
        memberType: user.memberType,
        agreeProtectPersonalInfo: user.agreeProtectPersonalInfo,
    };
};

export default withRouter(connect(mapStateToProps)(InputPhone));
