import { Checkbox } from 'antd';
import React from 'react';
import { UpsaleServicesType, IService } from '../../types/Service';

const ServiceUpSale: React.FC<{
    showDetail?: boolean;
    serviceUpSale: UpsaleServicesType;
    onSelectService: Function;
    chosenService: IService[];
}> = ({
    showDetail = false,
    serviceUpSale,
    onSelectService,
    chosenService,
}) => {
    const getValueCheckBox = (idService: any) => {
        return (
            chosenService.findIndex(
                (service: any) => service.serviceId === idService,
            ) >= 0
        );
    };

    const checked = getValueCheckBox(serviceUpSale.serviceId);

    const onChange = () => {
        onSelectService(serviceUpSale, !checked);
    };

    return (
        <Checkbox checked={checked} onChange={onChange}>
            <div className="pr-[14px]">
                <div
                    className={`text-[#20489B] text-sm font-semibold ${
                        showDetail ? '' : 'line-clamp-1'
                    }`}
                >
                    {serviceUpSale.title}
                </div>
                <div
                    className={`text-[#5F5F5F] text-xs ${
                        showDetail ? '' : 'line-clamp-1'
                    }`}
                >
                    {serviceUpSale.subtitle}
                </div>
            </div>

            <div className="text-[#61646B] font-semibold">
                +{serviceUpSale.price}
            </div>
        </Checkbox>
    );
};

export default ServiceUpSale;
