import React, { FC, memo } from 'react';
import iconArrowLeft from 'images/icons/icon_arrow_left_blue.svg';
import iconHome from 'images/icons/HomeFilled.svg';
import { IService } from '../../types/Service';
import './styles.scss';

const TopNavigator: FC<{
    onChangeStep: Function;
    listService: IService[];
    step: number;
    paramUrl: any;
}> = memo(({ onChangeStep, step, paramUrl }) => {
    const { inApp, utm_source } = paramUrl;
    const appSources = ['tikiapp', 'rn_ios', 'rn_android'];

    const getTitle = () => {
        switch (step) {
            case 0: {
                return 'Đặt lịch giữ chỗ';
            }
            case 1: {
                return 'Chọn salon';
            }
            case 2: {
                return 'Chọn dịch vụ';
            }
            case 4: {
                return 'Ưu đãi từ Moyo';
            }
            case 5: {
                return 'Chi tiết ưu đãi';
            }
            default: {
                return 'Hoàn tất';
            }
        }
    };

    const onClickHome = () => {
        const message = {
            type: 'booking_home',
            message: 'click_home',
        };
        if (utm_source === 'tikiapp') {
            // @ts-ignore
            my.postMessage(JSON.stringify(message));
        }
        if (utm_source === 'rn_android' || utm_source === 'rn_ios') {
            // @ts-ignore
            window.ReactNativeWebView.postMessage(JSON.stringify(message));
        }
    };

    return (
        <div
            key={step}
            className="navigator flex justify-between py-3 px-2.5 pointer"
        >
            {step > 0 && (
                <img
                    src={iconArrowLeft}
                    alt=""
                    onClick={() => {
                        onChangeStep(false);
                    }}
                    role="presentation"
                />
            )}
            {step === 0 && inApp && appSources.includes(utm_source) ? (
                <img
                    src={iconHome}
                    alt=""
                    role="presentation"
                    onClick={() => onClickHome()}
                />
            ) : null}
            <span className="text-center text-[#20489B]">{getTitle()}</span>
            {step === 2 ? (
                <div className="text-[#20489B] text-sm absolute right-[2px]">
                    1K = 1000đ
                </div>
            ) : (
                ''
            )}
        </div>
    );
});

export default TopNavigator;
