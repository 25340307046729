import React, { FC, memo } from 'react';
import { ISelected, ISelectedSalonBackUp } from '../../types/Selected';
import './styles.scss';

const Button: FC<{
    step: number;
    onChangeStep: Function;
    dataBooking: ISelected;
    selectedSalonBackUp: ISelectedSalonBackUp;
    allowChange: boolean;
}> = memo(({ step = 0, onChangeStep, dataBooking, selectedSalonBackUp }) => {
    const handleChangeStep = (isNext: boolean) => {
        onChangeStep(isNext);
    };

    const title =
        // eslint-disable-next-line no-nested-ternary
        step === 0 ? 'Hoàn tất' : step === 4 ? 'Áp dụng ưu đãi' : '';
    const inactive: boolean = 
        (step === 0 &&
            dataBooking.bookHours.length != dataBooking.groupBooking) ||
        dataBooking.salon.id === 0 ||
        (step === 4 && dataBooking.chosenCampaign.length === 0);

    return (
        <>
            <div className="button-affix">
                <div
                    className={`flex space-between text-center content-step ${
                        step === 0 ? 'time-line' : ''
                    } ${step === 0 && inactive ? '' : 'time-line--active'}`}
                >
                    <div
                        className={`right button-next pointer ${
                            inactive ? 'btn-inactive' : ''
                        }`}
                        onClick={() => handleChangeStep(true)}
                        role="presentation"
                    >
                        <span>{title}</span>
                    </div>
                    {step === 0 && (
                        <span className="sub-description">
                            Đặt lịch nhanh không cần chờ
                        </span>
                    )}
                </div>
            </div>
        </>
    );
});

export default Button;
