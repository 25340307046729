import { Reducer } from 'redux';
import {
    SALON_SET_PROVINCE,
    SALON_SET_ALL_SALON,
    SALON_SET_TOP_SALON,
    SALON_SET_TOP_SALON_BOOKING,
} from '../actionTypes/salon';
import { Action } from '../actions/salon';

export type IDistrict = {
    districtId: number;
    districtName: string;
    totalSalon: number;
};

export type IProvince = {
    districtModels: IDistrict[];
    cityName: string;
    cityId: number;
    searchText: string;
    totalSalon: number;
};

export interface IRegions {
    name: string;
    cities: IProvince[];
}

export interface IListProvince {
    vungMien: IRegions[];
    data: IProvince[];
}

export type IStateSalon = {
    listProvince: IListProvince;
    listSalon: Array<ISalon>;
    topSalon: Array<ISalon>;
    topSalonInBooking: Array<ISalon>;
};

export type ISalon = {
    id: number;
    name: string;
    addressNew: string;
    src: string;
    searchText: string;
    cityId: number;
    districtId: number;
    parking: boolean;
    latitude: number;
    longitude: number;
    distance: number;
    openingDate: string;
    isOpen: boolean;
    images: string;
    salonHot: boolean;
    salonOff: boolean;
    salonTrial: boolean;
    isPreviousSalon: boolean;
    isSalonBackup: boolean;
};

const initialState = {
    listProvince: { vungMien: [], data: [] },
    listSalon: [],
    topSalon: [],
    topSalonInBooking: [],
};

const salon: Reducer<IStateSalon, Action> = (
    state = initialState,
    action: Action,
) => {
    switch (action.type) {
        case SALON_SET_PROVINCE: {
            return { ...state, listProvince: action.payload };
        }

        case SALON_SET_ALL_SALON: {
            return { ...state, listSalon: action.payload };
        }

        case SALON_SET_TOP_SALON: {
            return { ...state, topSalon: action.payload };
        }

        case SALON_SET_TOP_SALON_BOOKING: {
            return { ...state, topSalonInBooking: action.payload };
        }

        default:
            return state;
    }
};

export default salon;
