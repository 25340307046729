import React, { useState, useEffect, useRef } from 'react';
import { Html5Qrcode } from 'html5-qrcode';
import { PictureOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Input, Modal, notification } from 'antd';
import API from 'repository';
import useWindowSize from 'hooks/useWindowSize';
import icScan from 'images/icons/icon_scan.svg';

const ScanQR: React.FC<{ getReward: Function }> = ({ getReward }) => {
    const html5QrCodeRef = useRef<any>(null);
    const { width = 0 } = useWindowSize();
    const [outPut, setOutPut] = useState('');
    const [noti, setNoti] = useState('');

    const [isScanning, setIsScanning] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [camera, setCamera] = useState(false);

    const [notiScan, setNotiScan] = useState('');
    const [isModalWarningOpen, setIsModalWarningOpen] = useState(false);

    const handleCancelScanQR = () => {
        if (html5QrCodeRef.current) {
            html5QrCodeRef.current
                .stop()
                .then(() => {
                    setIsScanning(false);
                })
                .catch((err: any) => {
                    console.log(err);
                });
        }
    };

    const handleOnChangeCampaignCode = (e: any) => {
        setOutPut(e.target.value);
        switch (e.target.value) {
            case '':
            case '0':
                setNoti('');
                if (isScanning) {
                    setIsModalOpen(false);
                    handleCancelScanQR();
                }
                break;
            default:
                setNoti('');
                if (isScanning) {
                    setIsModalOpen(false);
                    handleCancelScanQR();
                }
                break;
        }
    };

    // nút áp dụng
    const onApplyCampaign = async () => {
        if (!outPut) return;
        try {
            const response: any = await API.postCode({
                code: outPut,
            });
            handleCancelScanQR();

            if (response.code === 200) {
                setOutPut('');
                setNoti('');
                notification.success({
                    message: 'Thông báo!',
                    description: <>Lấy mã thành công!</>,
                    className: 'success',
                });
                getReward();
            } else {
                setNoti(response.message);
            }
        } catch (error) {
            setNoti('');
            notification.error({
                message: 'Thông báo!',
                description: <>Có lỗi xảy ra, bạn vui lòng thử lại sau!</>,
                className: 'error',
            });
        }
    };

    // bat dau quet ma trên pc

    const startScanning = () => {
        Html5Qrcode.getCameras()
            .then((devices) => {
                // lay camera
                if (devices && devices.length) {
                    setCamera(true);
                    html5QrCodeRef.current = new Html5Qrcode('reader');
                    const qrCodeSuccessCallback = (
                        decodedText: any,
                        decodedResult: any,
                    ) => {
                        setOutPut(decodedResult.decodedText);
                        html5QrCodeRef.current.stop().catch((err: any) => {
                            console.log(err);
                        });
                        setIsScanning(false);
                        setIsModalOpen(false);
                        onApplyCampaign();
                    };

                    const qrboxFunction = function (
                        viewfinderWidth: any,
                        viewfinderHeight: any,
                    ) {
                        const minEdgePercentage = 0.6;
                        const minEdgeSize = Math.min(
                            viewfinderWidth,
                            viewfinderHeight,
                        );
                        const qrboxWidth = Math.floor(
                            minEdgeSize * minEdgePercentage,
                        );
                        const qrboxHeight = Math.floor(qrboxWidth * (1 / 1)); // Chiều rộng nhỏ hơn chiều cao (theo tỷ lệ 1:2)
                        return {
                            width: qrboxWidth,
                            height: qrboxHeight,
                        };
                    };
                    if (width > 1100) {
                        html5QrCodeRef.current
                            .start(
                                { facingMode: 'environment' },
                                {
                                    fps: 10,
                                    qrbox: qrboxFunction,
                                    aspectRatio: 1 / 1.5,
                                },
                                qrCodeSuccessCallback,
                            )
                            .then(() => {
                                setIsScanning(true);
                            })
                            .catch((err: any) => {
                                console.log(err);
                            });
                    } else {
                        html5QrCodeRef.current
                            .start(
                                { facingMode: 'environment' },
                                {
                                    fps: 10,
                                    qrbox: qrboxFunction,
                                    aspectRatio: 2 / 1,
                                },
                                qrCodeSuccessCallback,
                            )
                            .then(() => {
                                setIsScanning(true);
                            })
                            .catch((err: any) => {
                                console.log(err);
                            });
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const closeModalScanQR = () => {
        setIsModalOpen(false);
        handleCancelScanQR();
    };

    //input ảnh mã
    const handleFileInputChange = (event: any) => {
        const file = event.target.files[0];
        const html5QrCode = new Html5Qrcode('reader');

        html5QrCode
            .scanFile(file, true)
            .then((decodedText) => {
                setNoti('');
                setOutPut(decodedText);
                setIsScanning(false);
                setIsModalOpen(false);
                onApplyCampaign();
            })
            .catch((err2) => {
                setIsScanning(false);
                setIsModalOpen(false);
                closeModalScanQR();
                setNotiScan(
                    'Ảnh bạn đã chọn không chứa mã QR, mã bị nhỏ hoặc mờ. Bạn vui lòng chọn một ảnh khác hoặc sử dụng chức năng quét bằng máy ảnh.',
                );
                setIsModalWarningOpen(true);
            })
            .finally(() => {
                html5QrCode.clear();
            });
    };

    useEffect(() => {
        return () => {
            if (html5QrCodeRef.current && html5QrCodeRef.current?.stop()) {
                html5QrCodeRef.current.stop().catch((err: any) => {
                    console.log(err);
                });
            }
        };
    }, []);

    useEffect(() => {
        if (isModalOpen) {
            startScanning();
        }
    }, [isModalOpen]);

    return (
        <div>
            <Modal
                visible={isModalWarningOpen}
                footer={null}
                className="modal-mission"
                closable={false}
                centered
                width={400}
                onCancel={() => setIsModalWarningOpen(false)}
            >
                <div className="text-center uppercase font-semibold text-xl">
                    Thông báo
                </div>

                <div className="text-center py-2 text-sm">{notiScan}</div>

                <div className="flex justify-center">
                    <div
                        onClick={() => setIsModalWarningOpen(false)}
                        className="bg-[#20489B] font-semibold rounded px-2 py-1 "
                    >
                        ĐỒNG Ý
                    </div>
                </div>
            </Modal>
            <div className="scanner flex justify-center items-center relative">
                <Modal
                    visible={isModalOpen}
                    footer={null}
                    className="modal-scanner"
                    closable={false}
                    centered
                    width={500}
                    onCancel={() => closeModalScanQR()}
                >
                    <div id="reader" />

                    <div
                        className={`content-scanner  ${
                            isScanning ? 'hidden' : ''
                        }`}
                    >
                        <div className="text-black text-center">
                            {camera
                                ? 'Căn chỉnh mã QR trong khung để quét'
                                : 'Chọn ảnh có mã QR trong thiết bị để quét'}
                        </div>
                        <div className="flex justify-center">
                            <label
                                htmlFor="qr-input-file"
                                className="custom-file-label"
                            >
                                <span className="flex gap-1 items-center justify-center">
                                    <PictureOutlined className="icon" />
                                    Tải ảnh QR
                                </span>
                                <input
                                    className="hidden"
                                    type="file"
                                    id="qr-input-file"
                                    accept="image/*"
                                    onChange={(e) => handleFileInputChange(e)}
                                />
                            </label>
                        </div>
                        <div className="flex justify-center">
                            <button
                                className="scan-cancel text-white"
                                onClick={() => closeModalScanQR()}
                                type="submit"
                            >
                                Tắt
                            </button>
                        </div>
                    </div>
                </Modal>
            </div>
            <div className="campaign-screen__getCode">
                <Input.Group compact>
                    <Input
                        className="code__input"
                        style={{ width: 'calc(100% - 100px)' }}
                        allowClear
                        placeholder="Nhập mã khuyến mại"
                        onChange={(e) => handleOnChangeCampaignCode(e)}
                        onPressEnter={() => onApplyCampaign()}
                        value={outPut}
                    />
                    <Button
                        type="primary"
                        className="code__btnApply"
                        onClick={() => onApplyCampaign()}
                        disabled={!outPut}
                    >
                        ÁP DỤNG
                    </Button>
                </Input.Group>
                <div
                    className={`items-center gap-1 py-1 ${
                        noti ? 'flex' : 'hidden'
                    }`}
                >
                    <ExclamationCircleFilled style={{ color: '#E54D3E' }} />
                    <span className="text-[#E54D3E] text-xs font-be-pro">
                        {noti}
                    </span>
                </div>
                <Button
                    type="primary"
                    className="code__btnScan gap-1"
                    onClick={() => setIsModalOpen(true)}
                    loading={isScanning}
                >
                    <img src={icScan} alt="" /> QUÉT MÃ QR
                </Button>
            </div>
        </div>
    );
};

export default ScanQR;
