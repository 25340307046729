import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { notification, Modal } from 'antd';
import API from 'repository';
import { validatePhone } from 'utils/validator';
import arrowRight from 'images/icons/arrowRight.svg';
import { LoadingOutlined } from '@ant-design/icons';
import { formatPhone } from '../../utils/NumberUtils';
import './style.scss';

import InputPhone from '../inputPhoneV2';
import ButtonBooking from '../buttonBooking';

const CustomModal = (props) => {
    const [isBooked, setIsBooked] = useState(1);
    const [phone, setPhone] = useState({
        value: '',
        error: '',
    });
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const getDetailBooking = () => {
        const phoneFormat = phone.value.replace(/\./g, '');
        const error = phone.error ? phone.error : validatePhone(phoneFormat);
        if (!loading) {
            if (error) {
                setPhone((prevState) => ({
                    ...prevState,
                    error,
                }));
                // API.postError({ message: error });
            } else {
                API.checkBooking({ phone: phoneFormat }, setLoading(true))
                    .then((response) => {
                        if (response.customer.isBooked) {
                            props.setVisible(false);
                            history.push(
                                `/booking/success?bookingId=${response.bookingCurrent.bookingId}`,
                            );
                        } else {
                            setIsBooked(0);
                        }
                        setLoading(false);
                    })
                    .catch((error) => {
                        setIsBooked(0);
                        setLoading(false);
                    });
            }
        }
    };

    function showMessage(value) {
        // bỏ
        const config = {
            message: 'Thông Báo!',
            description: value,
            duration: 3,
            className: 'error',
        };
        API.postError({ message: config.value });
        return notification.error(config);
    }

    const onchangePhone = (e) => {
        const { value } = e.target;
        if (!value) setPhone({ error: '', value: '' });
        if (value[0] == '0') {
            setPhone({ error: '', value: formatPhone(value) });
        } else {
            setPhone({
                error: 'Bạn nhập số điện thoại hợp lệ giúp chúng mình nhé!',
                value: formatPhone(value),
            });
        }
    };

    useEffect(() => {
        if (props.phone)
            setPhone({ value: formatPhone(props.phone), error: '' });
        setIsBooked(1);
    }, [props.phone, props.visible]);

    const onCancel = () => {
        if (props.visible) {
            props.setVisible(false);
        }
        setPhone({ value: '', error: '' });
        setIsBooked(1);
    };

    const handleKeyPressEnter = () => {
        getDetailBooking();
    };

    const handleClickBooking = () => {
        history.push(`/booking?phone=${phone.value.replace(/\./g, '')}`);
        props.setVisible(false);
    };

    return (
        <Modal
            className="modal-checkbooking"
            visible={props.visible}
            destroyOnClose
            footer={null}
            onCancel={onCancel}
        >
            <div className="title">Thông tin đặt lịch</div>
            {isBooked === 0 && (
                <div className="notfound-booking">
                    <span className="alert">Bạn chưa đặt lịch!</span>
                    <div className="booking">
                        <div onClick={handleClickBooking}>
                            Ấn vào đây để đặt ngay
                        </div>
                        <img className="icon_next" src={arrowRight} alt="" />
                    </div>
                </div>
            )}
            <div className="sub-title">Nhập số điện thoại xem lại lịch đặt</div>
            <InputPhone
                value={phone.value}
                handleChangePhone={(e) => onchangePhone(e)}
                handlePressEnter={handleKeyPressEnter}
                error={phone.error}
            />
            <ButtonBooking
                text="XEM LẠI LỊCH ĐẶT"
                handleClick={getDetailBooking}
            />
            {loading ? <LoadingOutlined className="loading" /> : ''}
        </Modal>
    );
};

export default CustomModal;
