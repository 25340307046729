import React from 'react';
import CampaignItem from './CampaignItem';

const ListCampaign: React.FC<{
    handleGotoCampaignDetail: Function;
    list: CampaignType[];
    onClickChosenCampaign: Function;
    children?: any;
    chosenCampaign: CampaignType[];
}> = ({
    handleGotoCampaignDetail,
    list,
    onClickChosenCampaign,
    children,
    chosenCampaign,
}) => {
    return (
        <div className="campaign-screen__body">
            {children}
            {list.length ? (
                list.map((item) => (
                    <CampaignItem
                        key={item.campaignId}
                        handleGotoCampaignDetail={() =>
                            handleGotoCampaignDetail(item)
                        }
                        data={item}
                        onClickChosenCampaign={(campaign: CampaignType) =>
                            onClickChosenCampaign(campaign)
                        }
                        chosenCampaign={chosenCampaign}
                    />
                ))
            ) : (
                <div className="text-center text-[#787878] text-sm italic">
                    Không có ưu đãi
                </div>
            )}
        </div>
    );
};

export default ListCampaign;
