/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import React, { FC, useEffect, useState, memo } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { notification, Skeleton } from 'antd';
import { distanceBetween } from 'components/func/Function';
import API from 'repository';
import $ from 'jquery';
import {
    fetchProvince,
    fetchAllSalon,
    fetchTopSalonIbBooking,
} from 'actions/salon';
import trackingConst from 'config/TrackingConst';
import close from 'images/icons/close.svg';
import useDebounce from 'hooks/useDebounce';

import { IRegion, IProvince } from '../types/CityDistrict';
import { IListSaveAddress, ISalon } from '../types/Salon';
import { AccessLocationType, IValue } from '../types/Selected';
import ListRegions from './ListRegions';
import ListDataSalon from './ListDataSalon';
import SearchBar from './SearchBar';
import ListAddress from './ListAddress';
import './styles.scss';
import BotMessage from '../components/BotMessage';
import iconRight from 'images/icons/chevronRight.svg';

export interface especiallySalon {
    data: ISalon[];
    title: string;
}

interface IListCategorySalon {
    data: ISalon[];
    title: string;
    typeItem: 'grid' | 'list';
    typeData: 'listSlide' | 'findSalonNear' | 'findSalonProvince';
}

const SalonScreen: FC<{
    onChangeFilter: Function;
    setIsSelectedSalonNear: Function;
    isSelectedSalonNear: AccessLocationType;
    phone: string;
    trackingData: any;
    userId: any;
    listProvince: IProvince[];
    listRegions: IRegion[];
    listSalon: ISalon[];
    actions: any;
    checkSalonOff: Function;
    ref: any;
}> = ({
    onChangeFilter,
    setIsSelectedSalonNear,
    isSelectedSalonNear,
    phone = '',
    trackingData,
    userId,
    listProvince,
    listRegions,
    listSalon,
    actions,
    checkSalonOff,
}) => {
    // data search
    const [searchValue, setSearchValue] = useState('');
    const [cityActive, setCityActive] = useState({
        id: 0,
        name: '',
        listDistrict: [],
        districtActive: {
            id: 0,
            name: 'Tất cả Quận/Huyện',
        },
    });

    // data salon dia chi da luu
    const [dataSavedAddress, setDataSavedAddress] = useState<{
        listAddress: any;
        idSelectAddress: number;
    }>({
        listAddress: [],
        idSelectAddress: 0,
    });

    // data salon den gan day
    const [listSalonHasCame, setListSalonHasCame] = useState<
        IListCategorySalon[]
    >([]);

    // data filter salon
    const [dataResultSalon, setDataResultSalon] = useState<
        IListCategorySalon[]
    >([]);

    const [isShowSalonNear, setIsShowSalonNear] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isShowBotMessage, setIsShowBotMessage] = useState<boolean>(false);
    const [isShowAllSalonHasParking, setIsShowAllSalonHasParking] = useState(
        false,
    );
    const [timeOnScreen, setTimeOnScreen] = useState(0);
    const { listAddress, idSelectAddress } = dataSavedAddress;
    const debouncedSearch = useDebounce(searchValue, 500);
    const common = useSelector((state: any) => state.common);

    const getSalon = async () => {
        if (listSalon.length === 0) {
            const listSalonApi = await actions.fetchAllSalon();
            return Promise.resolve(listSalonApi);
        }
        return Promise.resolve(listSalon);
    };

    const postTracking = async (dataTracking: any) => {
        try {
            API.postTrackingDynamic(dataTracking);
        } catch (e) {
            console.log(e.message);
        }
    };

    const handleSetDataSalon = (
        list: IListCategorySalon[],
        typeItem: 'grid' | 'list',
        typeData: 'listSlide' | 'findSalonNear' | 'findSalonProvince',
    ) => {
        const listData: IListCategorySalon[] = list.map((item) => {
            return {
                ...item,
                typeItem,
                typeData,
            };
        });
        if (listData.length > 0) {
            setDataResultSalon(listData);
        } else {
            setDataResultSalon([]);
        }
    };

    const getSalonHasCame = async (listSalon: ISalon[]) => {
        setIsLoading(true);
        try {
            if (!phone.length || !phone) throw new Error('Phone invalid!');
            const res: any = await API.getSalonHasCameNew({
                customerPhone: phone,
            });
            if (res?.data) {
                const sections = res?.data.map((item: any, index: number) => {
                    const ids = Array.from(new Set(item.data));
                    const salons = listSalon
                        .filter((salon: any) => ids.includes(salon.id))
                        .map((salon: any) => {
                            return {
                                ...salon,
                                isPreviousSalon: !index,
                            };
                        })
                        .sort(function (a, b) {
                            return ids.indexOf(a.id) - ids.indexOf(b.id);
                        });
                    return {
                        title: item.title,
                        data: salons,
                    };
                });
                if (sections[0] && sections[0].data.length === 0) {
                    setListSalonHasCame([]);
                    throw new Error('Salon empty');
                }
                setListSalonHasCame(sections);
            }
        } catch (e) {
            setListSalonHasCame([]);
        }
        setIsLoading(false);
    };

    const getProvince = async () => {
        if (listProvince.length === 0) {
            await actions.fetchProvince();
        }
    };

    const onChange = (e: any) => {
        const { value } = e.target;
        setSearchValue(value);
    };

    const handleOnChangeDistrict = (value: IValue) => {
        let list: ISalon[] = [];
        if (value.id) {
            list = [...listSalon].filter(
                (i: ISalon) => i.districtId === value.id,
            );
        } else {
            list = [...listSalon].filter(
                (i: ISalon) => i.cityId === cityActive.id,
            );
        }
        const litsCategorySalon: IListCategorySalon[] = [
            {
                data: list,
                title: '',
                typeItem: 'list',
                typeData: 'findSalonProvince',
            },
        ];
        setCityActive((prevState) => ({
            ...prevState,
            districtActive: {
                id: value.id,
                name: value.name,
            },
        }));
        setDataResultSalon(litsCategorySalon);

        // TRACKING
        const dataTracking = {
            pageId: trackingConst.Pages.BOOKING_SINGLE_PAGE,
            screenId: trackingConst.Screens.BOOKING_SELECT_SALON_SCREEN,
            eventId: trackingConst.Events.CLICK_SELECT_PROVINCE,
            tokenKey: trackingData.token,
            source: trackingData.source,
            utmSource: trackingData.utmSource,
            data: {
                phone,
                provinceId: value.id,
                customerId: userId || 0,
            },
        };

        postTracking(dataTracking);
    };

    const handleClickCity = (value: IProvince) => {
        const item = [...listProvince].find(
            (province) => province.cityId === value.cityId,
        );

        if (item) {
            if (item.totalSalon > 0) {
                const filterSalon = [...listSalon].filter(
                    (salon) => salon.cityId === value.cityId,
                );
                const litsCategorySalon: IListCategorySalon[] = [
                    {
                        data: filterSalon,
                        title: '',
                        typeItem: 'list',
                        typeData: 'findSalonProvince',
                    },
                ];
                const listDistrictOfCity: any = item.districtModels;
                setSearchValue(value.cityName);
                setCityActive({
                    id: value.cityId,
                    name: value.cityName,
                    listDistrict: listDistrictOfCity,
                    districtActive: {
                        id: 0,
                        name: 'Tất cả Quận/Huyện',
                    },
                });
                setDataSavedAddress((prevState) => ({
                    ...prevState,
                    idSelectAddress: 0,
                }));
                setIsShowSalonNear(false);
                setDataResultSalon(litsCategorySalon);
                $('html, body').animate({ scrollTop: 0 }, 300);
            } else {
                notification.error({
                    message: 'Thông báo',
                    description: 'Hiện tại không có salon nào ở đây',
                    className: 'error',
                    key: 'no-salon',
                    duration: 3,
                });
            }
        } else {
            setCityActive({
                id: 0,
                name: '',
                listDistrict: [],
                districtActive: {
                    id: 0,
                    name: 'Tất cả Quận/Huyện',
                },
            });
        }
    };

    const onChangeSalon = async (value: ISalon) => {
        // TRACKING
        const dataTracking = {
            pageId: trackingConst.Pages.BOOKING_SINGLE_PAGE,
            screenId: trackingConst.Screens.BOOKING_SELECT_SALON_SCREEN,
            eventId: trackingConst.Events.CLICK_SELECT_SALON,
            tokenKey: trackingData.token,
            source: trackingData.source,
            utmSource: trackingData.utmSource,
            data: {
                phone,
                salonId: value.id.toString(),
                customerId: userId || 0,
            },
        };

        postTracking(dataTracking);
        try {
            const data = await Promise.resolve(
                checkSalonOff(
                    value.id,
                    trackingConst.Screens.BOOKING_SELECT_SALON_SCREEN,
                ),
            );
            if (!data) {
                onChangeFilter(value);
            }
        } catch (e) {
            console.log(e.message);
        }
    };

    const getSalonNear = async () => {
        setIsLoading(true);
        const commonDataTracking = {
            pageId: trackingConst.Pages.BOOKING_SINGLE_PAGE,
            screenId: trackingConst.Screens.BOOKING_SELECT_SALON_SCREEN,
            eventId: '',
            tokenKey: trackingData.token,
            source: trackingData.source,
            utmSource: trackingData.utmSource,
        };
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const point: { longitude: number; latitude: number } = {
                    longitude: position.coords.longitude,
                    latitude: position.coords.latitude,
                };
                const _list: ISalon[] = [...listSalon].map((item: ISalon) => {
                    return {
                        ...item,
                        distance: distanceBetween(
                            {
                                longitude: item.longitude,
                                latitude: item.latitude,
                            },
                            point,
                        ),
                    };
                });
                const data = _list.filter((i) => i.id !== 269);
                if (!data.length) {
                    notification.error({
                        message: 'Thông Báo',
                        description:
                            'Không có salon nào gần bạn, vui lòng thử lại sau!',
                        duration: 3,
                        className: 'error',
                        key: 'no-salon',
                    });

                    return;
                }
                data.sort((a, b) => {
                    return a.distance - b.distance;
                });
                const salonNear = data.length > 10 ? data.splice(0, 30) : data;

                try {
                    setSearchValue('');
                    setCityActive({
                        id: 0,
                        name: '',
                        listDistrict: [],
                        districtActive: {
                            id: 0,
                            name: 'Tất cả Quận/Huyện',
                        },
                    });
                    setIsShowSalonNear(true);
                    setDataSavedAddress((prevState) => ({
                        ...prevState,
                        idSelectAddress: 0,
                    }));
                    const litsCategorySalon: IListCategorySalon[] = [
                        {
                            data: salonNear,
                            title: '',
                            typeItem: 'list',
                            typeData: 'findSalonNear',
                        },
                    ];

                    setDataResultSalon(litsCategorySalon);
                    // Tracking location
                    API.postTrackingLocation({
                        customerPhone: phone,
                        longitude: point.longitude,
                        latitude: point.latitude,
                    });
                } catch (e) {
                    console.log('Tracking error!', e);
                }

                // tracking
                const dataTracking = {
                    ...commonDataTracking,
                    eventId: trackingConst.Events.ALLOW_ACCESS_LOCATION,
                };
                postTracking(dataTracking);
            },
            () => {
                const config = {
                    message: '',
                    description:
                        'Bạn chưa cho phép quyền truy cập vị trí, bạn mở quyền hoặc tìm theo tỉnh thành nhé!',
                    duration: 3,
                    className: 'error error-location',
                    key: 'location',
                    closeIcon: (
                        <div className="error-location__icon">
                            <img src={close} alt="" />
                        </div>
                    ),
                };
                setDataSavedAddress((prevState) => ({
                    ...prevState,
                    idSelectAddress: 0,
                }));
                notification.open(config);
                // tracking
                const params = {
                    ...commonDataTracking,
                    eventId: trackingConst.Events.DENIED_ACCESS_LOCATION,
                };
                postTracking(params);
            },
        );
        setIsLoading(false);
        // TRACKING

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        navigator.permissions &&
            navigator.permissions
                .query({ name: 'geolocation' })
                .then((permissionStatus) => {
                    if (permissionStatus.state === 'granted') {
                        // allowed
                    } else if (permissionStatus.state === 'prompt') {
                        // prompt - not yet grated or denied
                        const params = {
                            ...commonDataTracking,
                            eventId:
                                trackingConst.Events
                                    .SHOW_PROMPT_ALLOW_ACCESS_LOCATION,
                        };
                        postTracking(params);
                    } else {
                        // denied
                    }
                });

        // TRACKING
        const dataTracking = {
            pageId: trackingConst.Pages.BOOKING_SINGLE_PAGE,
            screenId: trackingConst.Screens.BOOKING_SELECT_SALON_SCREEN,
            eventId: trackingConst.Events.CLICK_FIND_SALON_NEAR,
            tokenKey: trackingData.token,
            source: trackingData.source,
            utmSource: trackingData.utmSource,
            data: {
                customerId: userId || 0,
            },
        };
        postTracking(dataTracking);
    };

    const getListCustomerAddress = async (userPhone: string) => {
        try {
            const _data: any = await API.getListCustomerAddress(userPhone);
            setDataSavedAddress({
                ...dataSavedAddress,
                listAddress: _data.attribute,
            });
        } catch (e) {
            console.log(e);
        }
    };

    const getListSalonFilterAddress = async (
        phone: string,
        id: number,
        limit: number,
    ) => {
        try {
            const _data: any = await API.getListSalonFilterCustomerAddress(
                phone,
                id,
                limit,
            );
            const _list: ISalon[] = [];
            [...listSalon].filter((d) =>
                _data.salon.map((item: any) => {
                    if (d.id === item.salonId) {
                        const data = {
                            ...d,
                            distance: Math.round(item.distance * 10) / 10,
                        };
                        _list.push(data);
                    }
                }),
            );
            _list.sort((a, b) => a.distance - b.distance);
            let title: string = '';
            // eslint-disable-next-line array-callback-return
            listAddress.filter((item: IListSaveAddress) => {
                if (item.attrId === id) {
                    title = item.attrName;
                }
            });
            const listData: IListCategorySalon[] = [];
            const lists: any = {
                data: _list,
                title: `Salon gần ${title}`,
            };

            if (id !== 0) {
                listData.push(lists);
                handleSetDataSalon(listData, 'grid', 'listSlide');
            }
        } catch (e) {
            console.log(e);
        }
    };

    const setActiveListAddress = (id: number) => {
        setIsShowBotMessage(false);
        setDataSavedAddress({
            ...dataSavedAddress,
            idSelectAddress: idSelectAddress === id ? 0 : id,
        });
        setIsShowSalonNear(false);
        setSearchValue('');
        setCityActive({
            id: 0,
            name: '',
            listDistrict: [],
            districtActive: {
                id: 0,
                name: 'Tất cả Quận/Huyện',
            },
        });
        if (idSelectAddress !== id) {
            getListSalonFilterAddress(phone, id, 5);
        }
    };

    const onHandleClickSeeMoreSalonOto = () => {
        let filterSalon: ISalon[] = [];
        if (listSalonHasCame[0]?.data.length > 0) {
            const point: { longitude: number; latitude: number } = {
                longitude: listSalonHasCame[0]?.data[0].longitude,
                latitude: listSalonHasCame[0]?.data[0].latitude,
            };
            const listIdHasCame = listSalonHasCame[0]?.data.map(
                (item) => item.id,
            );

            filterSalon = [...listSalon]
                .map((item: ISalon) => {
                    return {
                        ...item,
                        distance: distanceBetween(
                            {
                                longitude: item.longitude,
                                latitude: item.latitude,
                            },
                            point,
                        ),
                    };
                })
                .sort((a, b) => {
                    return a.distance - b.distance;
                })
                .map((item) => {
                    return {
                        ...item,
                        distance: 0,
                        hasCame: listIdHasCame.includes(item.id),
                    };
                });
        }
        const litsCategorySalon: IListCategorySalon[] = [
            {
                data: filterSalon,
                title: '',
                typeItem: 'list',
                typeData: 'findSalonNear',
            },
        ];
        setIsShowSalonNear(true);
        setDataResultSalon(litsCategorySalon);
    };

    useEffect(() => {
        if (isSelectedSalonNear.isAllowAccessLocation) {
            setIsSelectedSalonNear();
            getSalonNear();
        }
        if (isSelectedSalonNear.isDeniedAccessLocation) {
            setIsSelectedSalonNear();
            setIsShowBotMessage(true);
        }
    }, [isSelectedSalonNear]);

    useEffect(() => {
        getProvince();
        getSalon().then((list) => {
            getSalonHasCame(list);
        });
        getListCustomerAddress(phone);
    }, [phone]);

    useEffect(() => {
        if (
            !debouncedSearch &&
            idSelectAddress === 0 &&
            cityActive.id === 0 &&
            !isShowSalonNear
        ) {
            handleSetDataSalon(listSalonHasCame, 'grid', 'listSlide');
            setIsShowAllSalonHasParking(true);
        } else {
            setIsShowAllSalonHasParking(false);
        }
    }, [
        debouncedSearch,
        idSelectAddress,
        cityActive.id,
        isShowSalonNear,
        listSalonHasCame,
    ]);

    useEffect(() => {
        const myInterval = setTimeout(() => {
            setTimeOnScreen((prevState) => prevState + 1);
        }, 1000);
        return () => {
            clearInterval(myInterval);
        };
    });

    return (
        <div className="salon-screen">
            {isShowBotMessage && (
                <div className="px-2 pb-3 fade-in">
                    <BotMessage
                        type="normal"
                        message="Bạn đang khóa quyền truy cập vị trí, bạn tìm salon  theo thành phố bên dưới nhé!"
                    />
                </div>
            )}
            <SearchBar
                text={searchValue}
                onChangeText={onChange}
                phone={phone}
                handleClickCity={handleClickCity}
                onChangeSalon={onChangeSalon}
                handleClickInputSearch={() => {
                    setIsShowBotMessage(false);
                }}
            />
            {isLoading ? (
                <div className="salon__loading">
                    <Skeleton active title={false} paragraph={{ rows: 3 }} />
                </div>
            ) : (
                <>
                    {!isShowSalonNear ? (
                        <ListAddress
                            data={dataSavedAddress}
                            isLoading={isLoading}
                            getSalonNear={getSalonNear}
                            phone={phone}
                            setActiveListAddress={setActiveListAddress}
                        />
                    ) : null}
                    <div
                        className={`salon ${
                            idSelectAddress === 0
                                ? 'min-height-455'
                                : 'min-height-220'
                        }`}
                    >
                        {!dataResultSalon.length ? (
                            listProvince.length > 0 ? (
                                <ListRegions
                                    onClickCityInRegion={handleClickCity}
                                    listProvince={listProvince}
                                />
                            ) : null
                        ) : (
                            <>
                                {isShowAllSalonHasParking && (
                                    <div
                                        className="order-2 mb-1 shadow cursor-pointer salon__box-switch"
                                        onClick={() => {
                                            onHandleClickSeeMoreSalonOto();
                                        }}
                                    >
                                        <div className="box-switch">
                                            <div className="box-switch__text">
                                                Tìm chỗ đậu ô tô{' '}
                                                <span className="text__gray">
                                                    (Có thể mất phí)
                                                </span>
                                            </div>
                                            <div>
                                                <img src={iconRight} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <ListDataSalon
                                    data={dataResultSalon}
                                    isLoading={false}
                                    onChangeSalon={onChangeSalon}
                                    phone={phone}
                                    cityActive={cityActive}
                                    handleOnChangeDistrict={
                                        handleOnChangeDistrict
                                    }
                                    isHideParking={isShowAllSalonHasParking}
                                    isShowSalonNear={isShowSalonNear}
                                />
                            </>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};
const mapStateToProps = (state: any) => {
    const { trackingData, user } = state;
    return {
        trackingData,
        userPhone: user.phoneNumber,
        userId: user.userId,
        listSalon: state.salon.listSalon,
        listProvince: state.salon.listProvince.data,
        listRegions: state.salon.listProvince.vungMien,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        actions: {
            ...bindActionCreators(
                {
                    fetchProvince,
                    fetchAllSalon,
                    fetchTopSalonIbBooking,
                },
                dispatch,
            ),
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(SalonScreen));
