import { removeDuplicateSalon } from 'components/func/Function';
import React, { FC, useEffect, useState } from 'react';
import { ISalon } from '../../types/Salon';
import ItemSalon from './ItemSalon';

const ListSalon: FC<{
    list: ISalon[];
    onChange: Function;
    isSalonNear?: boolean;
    showNote?: boolean;
    isParking?: boolean;
}> = ({
    list,
    onChange,
    isSalonNear = false,
    showNote = true,
    isParking = false,
    ...props
}) => {
    const [listSalon, setListSalon] = useState<ISalon[]>([]);

    useEffect(() => {
        const removeDuplicateListSalon = removeDuplicateSalon(list);
        if (isParking) {
            const listSalonHasParking = [...removeDuplicateListSalon].filter(
                (item) => item.parking,
            );
            setListSalon(listSalonHasParking);
        } else {
            setListSalon(removeDuplicateListSalon);
        }
    }, [isParking, list]);

    return (
        <>
            {props.children}
            {listSalon.length > 0 && (
                <div className="salon__list order-last">
                    {listSalon.map((item: ISalon, index: number) => {
                        return (
                            <ItemSalon
                                key={index}
                                item={item}
                                onChange={onChange}
                                index={index}
                                isGrid={false}
                                isSalonNear={isSalonNear}
                                showNote={showNote}
                            />
                        );
                    })}
                </div>
            )}
        </>
    );
};

export default ListSalon;
