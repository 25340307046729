import shineIcon from 'images/icons/30shine.svg';
import trackingConst from 'config/TrackingConst';

export const listMenu = {
    mb: [
        {
            title: 'Trang chủ',
            url: '/',
            // icon: serviceIcon,
            eventId: trackingConst.Events.HEADER_CLICK_SERVICE,
        },
        {
            title: 'Hệ thống salon',
            url: '/system-salon',
            class: '',
            eventId: trackingConst.Events.HEADER_CLICK_SYSTEM_SALON,
        },
        {
            title: 'Nhượng quyền',
            url: 'https://franchise.moyo.vn/66e2afa9b1a8ef001284bf6e',
            to: true,
            class: '',
            eventId: trackingConst.Events.HEADER_CLICK_FRANCHISE,
        },
    ],
    pc: [
        {
            title: 'Trang chủ',
            url: '/',
            // icon: serviceIcon,
            eventId: trackingConst.Events.HEADER_CLICK_SERVICE,
        },
        {
            title: 'Hệ thống salon',
            url: '/system-salon',
            class: '',
            eventId: trackingConst.Events.HEADER_CLICK_SYSTEM_SALON,
        },
        {
            title: 'Nhượng quyền',
            url: 'https://franchise.moyo.vn/66e2afa9b1a8ef001284bf6e',
            to: true,
            class: '',
            eventId: trackingConst.Events.HEADER_CLICK_FRANCHISE,
        },
    ],
};
