import React from 'react';
import ReactIdSwiperCustom from 'react-id-swiper/lib/ReactIdSwiper.custom';
import { Swiper } from 'swiper/js/swiper.esm';

const CustomBuildSwiper = (_) => {
    return (
        <ReactIdSwiperCustom Swiper={Swiper} {..._}>
            {_.children}
        </ReactIdSwiperCustom>
    );
};

export default CustomBuildSwiper;