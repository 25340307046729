import { Reducer } from 'redux';
// import { useCookies } from 'react-cookie';
// import { setCookieFunc } from 'components/func/Function';
import { USER_SET } from '../actionTypes/user';
import { Action } from '../actions/user';

export type IStateUser = {
    userId: number;
    phoneNumber: string;
    userName: string;
    email: string;
    avatar: string;
    dateOfBirth: string;
    monthOfBirth: string;
    yearOfBirth: string;
    accountBalance: number;
    membershipName: string;
    memberProductId: string;
    changePassword: boolean;
};

const initialState = {
    userId: 0,
    phoneNumber: '',
    userName: '',
    email: '',
    avatar: '',
    dateOfBirth: '',
    monthOfBirth: '',
    yearOfBirth: '',
    accountBalance: 0,
    membershipName: '',
    memberProductId: '',
    changePassword: false,
};

const user: Reducer<IStateUser, Action> = (
    state = initialState,
    action: Action,
) => {
    switch (action.type) {
        case USER_SET: {
            if (action.payload) {
                localStorage.setItem('user', JSON.stringify(action.payload));
                return action.payload;
            }
            return initialState;
        }

        default:
            return state;
    }
};

export default user;
