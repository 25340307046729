/* eslint-disable no-nested-ternary */
import React, { FC, useState, useEffect, useRef } from 'react';
import API from 'repository';
import moment from 'moment';
import 'moment/locale/vi';
import $ from 'jquery';
import { Skeleton, Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import Swiper from 'react-id-swiper';
import trackingConst from 'config/TrackingConst';
import { useSelector } from 'react-redux';
import KEY from '../../Const';
import { IRole, ISelected } from '../../types/Selected';
import { IHour, ITimeBox } from '../../types/DateTime';
import { IService } from '../../types/Service';
import ItemBoxTime from './components/ItemBoxTime';

interface TypeData {
    isLoading: boolean;
    listGroupHours: TypeGroupHours[];
}

interface TypeGroupHours {
    name: string;
    hours: ITimeBox[];
}

const paramSwiper = {
    spaceBetween: 8,
    slidesPerView: 4.2,
    breakpoints: {
        1200: {
            spaceBetween: 8,
            slidesPerView: 5.2,
        },
        100: {
            spaceBetween: 8,
            slidesPerView: 4.2,
        },
    },
};

const BoxTime: FC<{
    setListIconHotTime: Function;
    onChangeSelected: Function;
    role: IRole;
    selected: ISelected;
    setStylistBusy: Function;
    isStylistBusy: number;
    hasClickNextStep: number;
    trackingData?: any;
    userId?: any;
    setSalonBusy: Function;
    isSalonBusy: number;
    setIsLoadingHour: Function;
    isLoadingHour: boolean;
    isClickDateBook: boolean;
    setReception: Function;
    allowChange: boolean;
}> = ({
    setListIconHotTime,
    onChangeSelected,
    role,
    selected,
    setStylistBusy,
    isStylistBusy = 1,
    hasClickNextStep,
    trackingData,
    userId,
    setSalonBusy,
    isSalonBusy,
    setIsLoadingHour,
    isLoadingHour,
    isClickDateBook,
    setReception,
    allowChange,
}) => {
    const [data, setData] = useState<TypeData>({
        isLoading: false,
        listGroupHours: [],
    });
    const dfList = useRef<TypeGroupHours[]>([]);
    const swiperRef = useRef<any>(null);

    const [indexTimeBoxHighLight, setIndexTimeBoxHighLight] = useState<any>({
        indexGrHour: -1,
        indexHour: -1,
    });

    // phục vụ cho tracking
    const prevSelected = useRef<any>(selected);
    const isDidMounted = useRef<boolean>(false);

    const [isFirstSlide, setIsFirstSlide] = useState(true);
    const [isLastSlide, setIsLastSlide] = useState(false);

    const [isFreeToday, setIsFreeToday] = useState<boolean>(true);

    const scrollToSelectTime = () => {
        const element = document.querySelector('#box-time') as HTMLDivElement;
        const offset = 200;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;
        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
        });
    };

    async function postTracking(data: any) {
        try {
            await API.postTrackingDynamic(data);
        } catch (e) {
            console.log(e.message);
        }
    }

    // tracking
    const formatHourDataTracking = (groupHours: any) => {
        const result: any = [];
        groupHours.forEach((groupHours: any) => {
            groupHours.hours.forEach((hour: any) => {
                result.push({
                    hourGroup: groupHours.name,
                    hour: hour.hour,
                    hourFrame: hour.hourFrame,
                    isFree: hour.isFree,
                });
            });
        });
        return result;
    };

    function onChangeTime(hour: ITimeBox, grHour: any) {
        if (hour.receptionName && hour.receptionName) {
            setReception({
                name: hour.receptionName,
                avatar: hour.receptionAvatar,
            });
        }

        const newhour: IHour = {
            name: grHour.name,
            bookHourId: hour.hourId,
            subHourId: hour.subHourId,
            secondSubHourId: hour.secondSubHourId,
            hour: hour.hour,
            hourFrame: hour.hourFrame,
        };
        const newGrHours = [{
            bookHourId: hour.hourId,
            secondSubHourId: hour.secondSubHourId,
            subHourId: hour.subHourId
        }];
        onChangeSelected(KEY.BOOK_HOURS, newGrHours)
        onChangeSelected(KEY.HOUR, newhour);
    }

    async function getHoursGroupByDate(
        data: any,
        listChosenService: IService[],
    ) {
        setIsLoadingHour(true);
        let availableHour = 0;

        try {
            setListIconHotTime([]);
            const response: any = await API.getHoursGroupByDate(data);
            // set default reception
            if (response.data.receptionAvatar && response.data.receptionName) {
                setReception({
                    name: response.data.receptionName,
                    avatar: response.data.receptionAvatar,
                });
            }

            dfList.current = response.data.hourGroup;
            const dataTracking = {
                pageId: trackingConst.Pages.BOOKING_SINGLE_PAGE,
                screenId:
                    trackingConst.Screens.BOOKING_SELECT_BOOKING_SINGLE_SCREEN,
                eventId: trackingConst.Events.GET_HOURS_GROUP_BY_DATE,
                tokenKey: trackingData.token,
                source: trackingData.source,
                utmSource: trackingData.utmSource,
                data: {
                    phone: selected[KEY.PHONE],
                    salonId: selected[KEY.SALON].id.toString(),
                    chosenServices: JSON.stringify(listChosenService),
                    dateBook: selected[KEY.DATE_BOOK],
                    stylistId: selected[KEY.STYLIST].id.toString(),
                    hoursAvailable: selected[KEY.AVAILABLE_HOURS].toString(),
                    hours: JSON.stringify(
                        formatHourDataTracking(dfList.current),
                    ),
                    customerId: userId || 0,
                },
            };
            postTracking(dataTracking);
            setIsFreeToday(response.data.lastHourWork.isFreeToday);
        } catch (error) {
            dfList.current = [];
            console.log(error);
        } finally {
            if (
                dfList.current.length > 0 &&
                !dfList.current.some(
                    (grHour: any) =>
                        grHour.hours &&
                        grHour.hours.some((hour: any) => hour.isFree),
                )
            ) {
                if (data.stylistId === 0) {
                    const count = isSalonBusy > 0 ? -1 : isSalonBusy - 1;
                    setSalonBusy(count);
                    setStylistBusy(1);
                } else {
                    const count = isStylistBusy > 0 ? -1 : isStylistBusy - 1;
                    setStylistBusy(count);
                }
                $('#box-time').animate({ scrollTop: 0 }, 300);
            } else {
                const count = isSalonBusy > 0 ? isSalonBusy + 1 : 1;
                setSalonBusy(count);
                const newCount = isStylistBusy > 0 ? isStylistBusy + 1 : 1;
                setStylistBusy(newCount);
            }

            dfList.current = dfList.current.map((grHour: any) => {
                const newListHours = grHour.hours.map((hour: any) => {
                    const timeInBox = moment(selected.dateBook, 'DD-MM-YYYY')
                        .add(parseInt(hour.hour.split('h')[0]), 'hours')
                        .add(parseInt(hour.hour.split('h')[1]), 'minutes');

                    return {
                        ...hour,
                        isFree:
                            trackingData.utmSource === 'book_at_salon'
                                ? hour.isFree
                                : hour.isFree
                                ? !timeInBox.isBefore(moment(new Date()))
                                : hour.isFree,
                    };
                });
                return { ...grHour, hours: newListHours };
            });

            setIsLoadingHour(false);
            setData({
                listGroupHours: dfList.current,
                isLoading: false,
            });

            let newhour: any = null;

            dfList.current.forEach((grHour: any) => {
                grHour.hours.forEach((hour: any) => {
                    if (hour.isFree) {
                        availableHour += 1;
                        // quet booking tu qr chon san gio
                        if (
                            !newhour &&
                            trackingData.utmSource === 'book_at_salon'
                        ) {
                            newhour = hour;
                            onChangeTime(hour, grHour);
                        }
                    }
                });
            });
            onChangeSelected(KEY.AVAILABLE_HOURS, availableHour);
        }
        prevSelected.current = selected;
        isDidMounted.current = true;
    }

    function fetch(listChosenService: IService[]) {
        const serviceIDs = listChosenService.map(
            (service) => service.serviceId,
        );
        const params = {
            stylistId: selected[KEY.STYLIST].id,
            salonId: selected[KEY.SALON].id,
            bookDate: selected[KEY.DATE_BOOK],
            listServiceId: serviceIDs,
            customerPhone: selected[KEY.PHONE],
        };
        if (params.salonId > 0 && (listChosenService.length > 0 || allowChange))
            getHoursGroupByDate(params, listChosenService);
    }

    // tracking
    const trackingClickTimeOff = () => {
        const dataTracking = {
            pageId: trackingConst.Pages.BOOKING_SINGLE_PAGE,
            screenId:
                trackingConst.Screens.BOOKING_SELECT_BOOKING_SINGLE_SCREEN,
            eventId: trackingConst.Events.CLICK_TIME_OFF,
            tokenKey: trackingData.token,
            source: trackingData.source,
            utmSource: trackingData.utmSource,
            data: {
                phone: selected[KEY.PHONE],
                salonId: selected[KEY.SALON].id.toString(),
                chosenServices: JSON.stringify(selected[KEY.CHOSEN_SERVICES]),
                dateBook: selected[KEY.DATE_BOOK],
                stylistId: selected[KEY.STYLIST].id.toString(),
                hoursAvailable: selected[KEY.AVAILABLE_HOURS].toString(),
                customerId: userId || 0,
            },
        };

        postTracking(dataTracking);
    };

    const trackingClickTime = () => {
        const dataTracking = {
            pageId: trackingConst.Pages.BOOKING_SINGLE_PAGE,
            screenId:
                trackingConst.Screens.BOOKING_SELECT_BOOKING_SINGLE_SCREEN,
            eventId: trackingConst.Events.CLICK_SELECT_TIME,
            tokenKey: trackingData.token,
            source: trackingData.source,
            utmSource: trackingData.utmSource,
            data: {
                phone: selected[KEY.PHONE],
                salonId: selected[KEY.SALON].id.toString(),
                chosenServices: JSON.stringify(selected[KEY.CHOSEN_SERVICES]),
                dateBook: selected[KEY.DATE_BOOK],
                stylistId: selected[KEY.STYLIST].id.toString(),
                hoursAvailable: selected[KEY.AVAILABLE_HOURS].toString(),
                customerId: userId || 0,
            },
        };

        postTracking(dataTracking);
    };

    function onClickChangeTime(hour: ITimeBox, grHour: any) {
        if (hour.isFree) {
            onChangeTime(hour, grHour);
            // tracking
            trackingClickTime();
        } else {
            trackingClickTimeOff();
        }
    }

    function onHandleExpiredTime(secondSubHourId: number) {
        let newListGroupHours = [];

        newListGroupHours = data.listGroupHours.map((grHour: any) => {
            const newGrHour = { ...grHour };
            newGrHour.hours = grHour.hours.map((hour: any) => {
                if (hour.secondSubHourId === secondSubHourId) {
                    return {
                        ...hour,
                        isFree: false,
                    };
                }
                return hour;
            });
            return newGrHour;
        });

        setData({ isLoading: false, listGroupHours: newListGroupHours });

        // tracking
        trackingClickTimeOff();
    }

    function ScrollToTimeAvaiable() {
        const { listGroupHours } = data;
        let indexTimeBox = listGroupHours.findIndex(
            (grHour: TypeGroupHours) =>
                grHour && grHour.hours.some((hour: any) => hour.isFree),
        );
        indexTimeBox = indexTimeBox - 1 >= 0 ? indexTimeBox - 1 : 0;
        if (swiperRef.current) {
            swiperRef.current.swiper.update();
            swiperRef.current.swiper.slideTo(indexTimeBox, 500);
        }
    }

    const handleSlideChange = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            setIsFirstSlide(swiperRef.current.swiper.isBeginning);
            setIsLastSlide(swiperRef.current.swiper.isEnd);
        }
    };

    const goNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
            setIsFirstSlide(swiperRef.current.swiper.isBeginning);
            setIsLastSlide(swiperRef.current.swiper.isEnd);
        }
    };

    const goPrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
            setIsFirstSlide(swiperRef.current.swiper.isBeginning);
            setIsLastSlide(swiperRef.current.swiper.isEnd);
        }
    };

    const classButtonPrev = classNames('swiper_button button_prev', {
        swiper_button_disable: isFirstSlide,
    });

    const classButtonNext = classNames('swiper_button button_next', {
        swiper_button_disable: isLastSlide,
    });

    useEffect(() => {
        if (hasClickNextStep && !selected[KEY.HOUR].bookHourId) {
            const { listGroupHours } = data;
            const indexGrHour = listGroupHours.findIndex(
                (grHour: TypeGroupHours) =>
                    grHour && grHour.hours.some((hour: any) => hour.isFree),
            );
            if (indexGrHour !== -1) {
                const indexHour = listGroupHours[indexGrHour].hours.findIndex(
                    (hour: any) => hour.isFree,
                );
                setIndexTimeBoxHighLight({
                    indexGrHour,
                    indexHour,
                });
            }

            // scroll for mobile
            scrollToSelectTime();

            const indexPrevGrHour = indexGrHour - 1 >= 0 ? indexGrHour - 1 : 0;
            if (swiperRef.current) {
                swiperRef.current.swiper.update();
                swiperRef.current.swiper.slideTo(indexPrevGrHour, 500);
            }
        } else {
            setIndexTimeBoxHighLight({ indexGrHour: -1, indexHour: -1 });
        }
    }, [hasClickNextStep]);

    useEffect(() => {
        ScrollToTimeAvaiable();
    }, [data.listGroupHours]);

    useEffect(() => {
        fetch(selected[KEY.CHOSEN_SERVICES]);
    }, [
        selected[KEY.STYLIST].id,
        selected[KEY.SALON].id,
        selected[KEY.DATE_BOOK],
        allowChange,
    ]);

    useEffect(() => {
        if (!isFreeToday && !isClickDateBook) {
            setSalonBusy(1);
            const newDate = moment(selected[KEY.DATE_BOOK], 'DD-MM-YYYY')
                .add(1, 'days')
                .format('DD-MM-YYYY');
            onChangeSelected(KEY.DATE_BOOK, newDate);
        }
    }, [isFreeToday, isClickDateBook]);

    const hasShowTextNote =
        indexTimeBoxHighLight.indexGrHour !== -1 &&
        indexTimeBoxHighLight.indexGrHour;

    return (
        <>
            <div className="box-time" id="box-time">
                <div className={`${isLoadingHour ? 'hide-time' : 'relative'}`}>
                    <Swiper
                        {...paramSwiper}
                        ref={swiperRef}
                        on={{ slideChange: handleSlideChange }}
                    >
                        {data.listGroupHours.map(
                            (grHour: TypeGroupHours, indexGr: number) => {
                                return (
                                    <div
                                        key={grHour.name}
                                        className="box-time_gr"
                                    >
                                        {grHour.hours &&
                                            grHour.hours.map(
                                                (
                                                    hour: ITimeBox,
                                                    indexHour: any,
                                                ) => {
                                                    const {
                                                        bookHourId,
                                                        subHourId,
                                                        secondSubHourId,
                                                    } = selected[KEY.HOUR];
                                                    const isCheck = !!(
                                                        bookHourId ===
                                                            hour.hourId &&
                                                        subHourId ===
                                                            hour.subHourId &&
                                                        secondSubHourId ===
                                                            hour.secondSubHourId
                                                    );
                                                    return (
                                                        <ItemBoxTime
                                                            key={hour.hour}
                                                            grHour={grHour}
                                                            isCheck={isCheck}
                                                            bookingDate={
                                                                selected[
                                                                    KEY
                                                                        .DATE_BOOK
                                                                ]
                                                            }
                                                            timeBox={hour}
                                                            onHandleExpiredTime={
                                                                onHandleExpiredTime
                                                            }
                                                            onChangeTime={
                                                                onClickChangeTime
                                                            }
                                                            isHighLight={
                                                                indexGr ===
                                                                    indexTimeBoxHighLight.indexGrHour &&
                                                                indexHour ===
                                                                    indexTimeBoxHighLight.indexHour
                                                            }
                                                        />
                                                    );
                                                },
                                            )}
                                    </div>
                                );
                            },
                        )}
                    </Swiper>
                    <Button
                        className={classButtonPrev}
                        shape="circle"
                        disabled={isFirstSlide}
                        icon={<LeftOutlined className="swiper_button_icon" />}
                        onClick={goPrev}
                    />
                    <Button
                        className={classButtonNext}
                        shape="circle"
                        disabled={isLastSlide}
                        icon={<RightOutlined className="swiper_button_icon" />}
                        onClick={goNext}
                    />
                </div>
                {/* {hasShowTextNote ? (
                    <div className=" color-red-f46969 text-sm mt-1.5">
                        Mời bạn chọn giờ cắt để tiếp tục đặt lịch
                    </div>
                ) : null} */}
                {isLoadingHour && (
                    <Skeleton paragraph={{ rows: 5 }} loading active />
                )}
            </div>
        </>
    );
};
export default BoxTime;
