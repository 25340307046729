import { Checkbox } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

const CampaignItem: React.FC<{
    handleGotoCampaignDetail: Function;
    data: CampaignType;
    onClickChosenCampaign: Function;
    chosenCampaign: CampaignType[];
}> = ({
    handleGotoCampaignDetail,
    data,
    onClickChosenCampaign,
    chosenCampaign,
}) => {
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if (data.campaignId) {
            const isChecked = chosenCampaign.find(
                (item) => item.campaignId === data.campaignId,
            );
            setChecked(!!isChecked);
        }
    }, [JSON.stringify(chosenCampaign)]);

    return (
        <div
            className={`campaign-screen__item ${
                data?.valid ? '' : 'campaign-screen__item-inactive'
            } mb-2 flex`}
        >
            <div
                className="item__content flex"
                onClick={() => handleGotoCampaignDetail()}
                role="presentation"
            >
                <img
                    src={data.image}
                    alt=""
                    className="item__image cursor-pointer"
                />
                <div className="item__text">
                    <div className="item__title cursor-pointer">
                        {data.name}
                    </div>
                    <div className="item__expire cursor-pointer">
                        HSD: {moment(data.expire).format('DD/MM/YYYY')}
                    </div>
                </div>
            </div>
            <div className="item__select">
                <Checkbox
                    checked={checked}
                    disabled={!data?.valid}
                    onChange={() => {
                        if (data?.valid) onClickChosenCampaign(data);
                    }}
                />
                <div
                    className="item__condition cursor-pointer"
                    onClick={() => handleGotoCampaignDetail()}
                    role="presentation"
                >
                    Điều kiện
                </div>
            </div>
            {!data?.valid && data.message ? (
                <div className="item__message">{data.message}</div>
            ) : null}
        </div>
    );
};

export default CampaignItem;
