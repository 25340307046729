/* eslint-disable no-nested-ternary */
import React, { FC, useRef, useState } from 'react';
import useWindowSize from 'hooks/useWindowSize';
import Swiper from 'react-id-swiper';
import { Link } from 'react-scroll';
import { Button } from 'antd';
import CommonPicture from 'components/common/Picture';
import classNames from 'classnames';
import '../index.scss';
import vectorLeft from 'images/icons/Vector_left.svg';
import vectorRight from 'images/icons/Vector_right.svg';
import LayoutHeadTitle from '../headTitle';
import RedirectLink from '../redirectLink';
import Banner from '../banner';

const LayoutBlog: FC<{ data: any }> = ({ data }) => {
    const swiperRef = useRef<any>(null);
    const [isFirstSlide, setIsFirstSlide] = useState<boolean>(true);
    const [isLastSlide, setIsLastSlide] = useState<boolean>(false);
    const size: any = useWindowSize();
    const isMobile = size.width < 768;

    const paramsSwiper = {
        spaceBetween: 20,
        breakpoints: {
            600: {
                slidesPerView:
                    data.slidePerViewPC > 0
                        ? data.slidePerViewPC
                        : data.list.length < 4
                        ? data.list.length
                        : 4,
                spaceBetween: 20,
            },
            0: {
                slidesPerView:
                    data.slidePerViewMB > 0 ? data.slidePerViewMB : 1.3,
                spaceBetween: 16,
            },
        },
        draggable: true,
        freeMode: true,
    };

    const handleSlideChange = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            setIsFirstSlide(swiperRef.current.swiper.isBeginning);
            setTimeout(() => {
                setIsLastSlide(swiperRef.current.swiper.isEnd);
            }, 500);
        }
    };

    const handleTouchChange = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            setIsFirstSlide(swiperRef.current.swiper.isBeginning);
            setIsLastSlide(swiperRef.current.swiper.isEnd);
        }
    };

    const handleSlideInit = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            setIsFirstSlide(swiperRef.current.swiper.isBeginning);
            setIsLastSlide(swiperRef.current.swiper.isEnd);
        }
    };

    const goNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
            setIsFirstSlide(swiperRef.current.swiper.isBeginning);
            setIsLastSlide(swiperRef.current.swiper.isEnd);
        }
    };

    const goPrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
            setIsFirstSlide(swiperRef.current.swiper.isBeginning);
            setIsLastSlide(swiperRef.current.swiper.isEnd);
        }
    };

    const classButtonPrev = classNames('swiper_button button_prev', {
        swiper_button_disable: isFirstSlide,
    });

    const classButtonNext = classNames('swiper_button button_next', {
        swiper_button_disable: isLastSlide,
    });

    const isShowButtonSwiper =
        data.list.length > Math.round(data.slidePerViewPC);
    const VND = new Intl.NumberFormat('vi-VN', {
        style: 'currency',
        currency: 'VND',
    });

    return (
        <div
            className={`layout layout-blog ${
                data?.bg_custom ? 'bg_custom bg_custom_1' : ''
            }`}
        >
            <div className="container">
                <LayoutHeadTitle data={data} />
                <div className="home_store_banner">
                    <Banner data={data} />
                </div>
                <div className="mb-4 layout__blog">
                    {data.list.length > 0 && (
                        <>
                            <Swiper
                                ref={swiperRef}
                                {...paramsSwiper}
                                on={{
                                    init: handleSlideInit,
                                    touchMove: handleTouchChange,
                                    slideChange: handleSlideChange,
                                }}
                            >
                                {data.list.map((item: any) => {
                                    return (
                                        <div
                                            className={`blog ${
                                                data.title === 'Ưu đãi'
                                                    ? 'uu_dai'
                                                    : ''
                                            }`}
                                            key={item.id}
                                        >
                                            <RedirectLink
                                                redirect={item.redirectLink}
                                            >
                                                <div className="mb-2 md:mb-4 blog__media">
                                                    <CommonPicture
                                                        link={
                                                            isMobile &&
                                                            item.linkImageMb
                                                                ? item.linkImageMb
                                                                : item.linkImage
                                                        }
                                                        width-string="-480x480"
                                                        alt="combo service"
                                                    />
                                                </div>
                                            </RedirectLink>
                                            <RedirectLink
                                                redirect={item.redirectLink}
                                            >
                                                <div
                                                    className="font-semibold text-[#262626] text-sm md:text-lg"
                                                    style={{
                                                        textAlign:
                                                            item?.price ||
                                                            item?.priceList
                                                                ? 'left'
                                                                : 'center',
                                                    }}
                                                >
                                                    <span>{item.title}</span>
                                                </div>

                                                <div className="blog__subTitle">
                                                    {item.subTitle}
                                                    {item?.price && (
                                                        <>
                                                            <span className="price">
                                                                {VND.format(
                                                                    item?.price ||
                                                                        0,
                                                                )}
                                                            </span>
                                                            <span className="oldPrice">
                                                                {VND.format(
                                                                    item?.oldPrice ||
                                                                        0,
                                                                )}
                                                            </span>
                                                        </>
                                                    )}
                                                    {item?.priceList && (
                                                        <span className="price">
                                                            {item.priceList}
                                                        </span>
                                                    )}
                                                </div>
                                            </RedirectLink>
                                        </div>
                                    );
                                })}
                            </Swiper>
                            {isShowButtonSwiper && data.list.length > 3 && (
                                <>
                                    <Button
                                        className={classButtonPrev}
                                        shape="circle"
                                        disabled={isFirstSlide}
                                        onClick={goPrev}
                                    >
                                        <img src={vectorLeft} alt="left" />
                                    </Button>
                                    <Button
                                        className={classButtonNext}
                                        shape="circle"
                                        disabled={isLastSlide}
                                        onClick={goNext}
                                    >
                                        <img src={vectorRight} alt="right" />
                                    </Button>
                                </>
                            )}
                        </>
                    )}
                </div>
                <div className="py-2 text-center">
                    <Link
                        to="book-now"
                        smooth
                        duration={500}
                        className="uppercase w-28 bg-[#20489B] text-white font-bold rounded-md min-w-28 px-6 py-2 hover:opacity-80 hover:text-white"
                    >
                        Đặt lịch ngay
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default LayoutBlog;
