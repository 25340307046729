import React, { FC, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import API from 'repository';
import Swiper from 'react-id-swiper';
import { useHistory } from 'react-router-dom';
import trackingConst from 'config/TrackingConst';
import bookmark from 'images/icons/Bookmark.svg';
import navigate from 'images/icons/navigate-booking.svg';
import arrowright from 'images/icons/chevronRight.svg';
import iconLeft from 'images/fillWhite/arrowLeft.svg';
import iconRight from 'images/fillWhite/arrowRight.svg';
import iconHome from 'images/fillGray/homeOutline.svg';
import iconWork from 'images/fillGray/work.svg';
import { IListSaveAddress } from '../types/Salon';

const listIconLocation: any = {
    0: iconHome,
    1: iconWork,
    2: bookmark,
};

const ListAddress: FC<{
    data: any;
    isLoading: boolean;
    getSalonNear: Function;
    phone: string;
    setActiveListAddress: Function;
    trackingData: any;
    userPhone: string;
}> = ({
    data,
    isLoading,
    getSalonNear,
    setActiveListAddress,
    phone,
    trackingData,
    userPhone,
}) => {
    const { listAddress, idSelectAddress } = data;
    const [isFirstSlide, setIsFirstSlide] = useState(true);
    const [isLastSlide, setIsLastSlide] = useState(false);
    const [activeSlide, setActiveSlide] = useState(0);
    const swiperRef = useRef<any>(null);
    const history = useHistory();

    const paramsSwiper: {
        spaceBetween: number;
        slidesPerView: number | 'auto' | undefined;
    } = {
        spaceBetween: 10,
        slidesPerView: 'auto',
    };

    const updatePositionSwiper = () => {
        setIsFirstSlide(swiperRef.current.swiper.isBeginning);
        setIsLastSlide(swiperRef.current.swiper.isEnd);
    };

    const postTracking = async (dataTracking: any) => {
        try {
            API.postTrackingDynamic(dataTracking);
        } catch (e) {
            console.log(e.message);
        }
    };

    const goNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
            updatePositionSwiper();
        }
    };

    const goPrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
            updatePositionSwiper();
        }
    };

    const handleClickSavingLocation = () => {
        // tracking
        const params = {
            pageId: trackingConst.Pages.BOOKING_SINGLE_PAGE,
            screenId: trackingConst.Screens.BOOKING_SELECT_SALON_SCREEN,
            eventId: trackingConst.Events.CLICK_SAVING_LOCATION,
            tokenKey: trackingData.token,
            source: trackingData.source,
            utmSource: trackingData.utmSource,
            data: {
                customerId: trackingData.customerId || 0,
            },
        };
        postTracking(params);

        history.push('/save-location');
    };

    const renderAddLocation = () => {
        const isShow = userPhone && userPhone === phone;
        return (
            isShow && (
                <div
                    className="salon__box-location show"
                    onClick={handleClickSavingLocation}
                    role="presentation"
                >
                    <img src={bookmark} alt="" />

                    <div className="location__text">
                        <div className="text__top">Địa điểm đã lưu</div>
                        <div className="text__bottom">
                            Đến địa điểm yêu thích của bạn dễ dàng
                        </div>
                    </div>
                    <img
                        src={arrowright}
                        alt=""
                        className="location__arrowRight"
                    />
                </div>
            )
        );
    };

    const handleOnClickAddressItem = (id: number, index: number) => {
        setActiveSlide(index);
        setActiveListAddress(id);
    };

    const isActiveListAddress = (id: number) => {
        return idSelectAddress === id;
    };

    const handleSlideInit = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            setIsFirstSlide(swiperRef.current.swiper.isBeginning);
            setIsLastSlide(swiperRef.current.swiper.isEnd);
        }
    };

    const handleSlideChange = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            setIsFirstSlide(swiperRef.current.swiper.isBeginning);
        }
    };

    const handleTouchChange = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            setIsFirstSlide(swiperRef.current.swiper.isBeginning);
            setIsLastSlide(swiperRef.current.swiper.isEnd);
        }
    };

    useEffect(() => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slideTo(activeSlide, 200);
            updatePositionSwiper();
        }
    }, [activeSlide]);

    useEffect(() => {
        if (swiperRef.current) {
            swiperRef.current.swiper.update();
            updatePositionSwiper();
        }
    }, [listAddress]);
    const classButtonNext =
        isLastSlide || isLoading
            ? 'btn-navigation btn-right btn_hide'
            : 'btn-navigation btn-right';
    const classButtonPrev =
        isFirstSlide || isLoading
            ? 'btn-navigation btn-left btn_hide'
            : 'btn-navigation btn-left';
    return (
        <>
            <div className="salon__list-address show">
                <div className="list-address">
                    <Swiper
                        ref={swiperRef}
                        {...paramsSwiper}
                        shouldSwiperUpdate
                        on={{
                            touchMove: handleTouchChange,
                            slideChange: handleSlideChange,
                            init: handleSlideInit,
                        }}
                    >
                        <div
                            className="list-address__button list-address__button--nearSalon"
                            onClick={() => getSalonNear()}
                            role="presentation"
                        >
                            <img
                                src={navigate}
                                alt=""
                                className="list-address__icon"
                            />
                            <span className="list-address__text">
                                Tìm salon gần bạn
                            </span>
                        </div>
                        {listAddress.map(
                            (item: IListSaveAddress, index: number) => {
                                return (
                                    <div
                                        className={
                                            isActiveListAddress(item.attrId)
                                                ? 'list-address__button list-address__button--active'
                                                : 'list-address__button'
                                        }
                                        key={`${item.attrId}_${index + 1}`}
                                        onClick={() =>
                                            handleOnClickAddressItem(
                                                item.attrId,
                                                index,
                                            )
                                        }
                                        role="presentation"
                                    >
                                        <img
                                            src={
                                                listIconLocation[index] ||
                                                bookmark
                                            }
                                            alt=""
                                            className="list-address__icon"
                                        />
                                        <span className="list-address__text">
                                            {`Salon gần ${item.attrName}`}
                                        </span>
                                    </div>
                                );
                            },
                        )}
                    </Swiper>
                    <div
                        className={classButtonPrev}
                        onClick={goPrev}
                        role="presentation"
                    >
                        <img src={iconLeft} alt="" className="icon-btn" />
                    </div>
                    <div
                        className={classButtonNext}
                        onClick={goNext}
                        role="presentation"
                    >
                        <img src={iconRight} alt="" className="icon-btn" />
                    </div>
                </div>
            </div>
            {renderAddLocation()}
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        trackingData: state.trackingData,
        userPhone: state.user.phoneNumber,
    };
};

export default connect(mapStateToProps)(ListAddress);
