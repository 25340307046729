import React, { useEffect } from 'react';
// import './styles/output.css'
import './App.scss';
import 'antd/dist/antd.css';
import 'swiper/css/swiper.css';
import { Provider } from 'react-redux';
import { useCookies } from 'react-cookie';
import uuid from 'uuid';
import Routes from './pages/Routes';
import configureStore from './store/configureStore';

export const store = configureStore();

const App = () => {
    const uuidv4 = uuid.v4;
    const [cookies, setCookie] = useCookies(['trackingId']);

    async function setTrackingId() {
        const { trackingId } = cookies;
        if (trackingId) {
            return;
        }
        // Set expires 3 years
        const cookieExpires = new Date(
            new Date().getTime() + 3600 * 1000 * 24 * 365 * 3,
        );
        setCookie('trackingId', uuidv4(), {
            path: '/',
            expires: cookieExpires,
            domain: process.env.REACT_APP_BASE_URL,
        });
    }
    useEffect(() => {
        setTrackingId();
    }, []);

    return (
        <>
            <div className="App">
                <Provider store={store}>
                    <Routes />
                </Provider>
            </div>
        </>
    );
};

export default App;
