import React, { FC } from 'react';
import botAvatar from 'images/bot_avatar_1654_1.jpg';

const BotMessage: FC<{
    message: string;
    type?: string;
    reception?: { name: string; avatar: string };
}> = ({ message, type = 'full', reception }) => {
    // type in: [normal, minimal, full, reception], default: full
    const typeClass = type === 'full' ? '' : `bot-message__${type}`;

    return (
        <div
            className={`bot-message rounded-lg !border-[#EF4E24] !bg-[#FFF9F1] text-[#EF4E24] border-1 p-1 ${typeClass}`}
        >
            {reception ? (
                <div className="avatar">
                    <div
                        className="reception-avatar"
                        style={{
                            backgroundImage: `url("${reception.avatar}")`,
                        }}
                    />
                </div>
            ) : (
                <img src={botAvatar} alt="" />
            )}
            <div className="content">
                {reception && (
                    <div className="content__header">
                        <span className="label">Supporter:</span>
                        <span className="name">{reception.name}</span>
                    </div>
                )}
                <p>{message}</p>
            </div>
        </div>
    );
};

export default BotMessage;
