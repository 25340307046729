/* eslint-disable no-nested-ternary */
import React, { FC, useRef, useState } from 'react';
import useWindowSize from 'hooks/useWindowSize';
import Swiper from 'react-id-swiper';
import { Button } from 'antd';
import vectorLeft from 'images/icons/Vector_left.svg';
import vectorRight from 'images/icons/Vector_right.svg';
import classNames from 'classnames';
import '../index.scss';
import LayoutHeadTitle from '../headTitle';
import RedirectLink from '../redirectLink';
import Banner from '../banner';

const LayoutSalonAddress: FC<{ data: any }> = ({ data }) => {
    const swiperRef = useRef<any>(null);
    const [isFirstSlide, setIsFirstSlide] = useState<boolean>(true);
    const [isLastSlide, setIsLastSlide] = useState<boolean>(false);
    const size: any = useWindowSize();
    const isMobile = size.width < 768;

    const paramsSwiper = {
        spaceBetween: 30,
        breakpoints: {
            600: {
                slidesPerView:
                    data.slidePerViewPC > 0
                        ? data.slidePerViewPC
                        : data.list.length < 4
                        ? data.list.length
                        : 4,
                spaceBetween: 20,
            },
            0: {
                slidesPerView:
                    data.slidePerViewMB > 0 ? data.slidePerViewMB : 1.5,
                spaceBetween: 16,
            },
        },
        freeMode: true,
    };

    const handleSlideChange = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            setIsFirstSlide(swiperRef.current.swiper.isBeginning);
            setTimeout(() => {
                setIsLastSlide(swiperRef.current.swiper.isEnd);
            }, 500);
        }
    };

    const handleTouchChange = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            setIsFirstSlide(swiperRef.current.swiper.isBeginning);
            setIsLastSlide(swiperRef.current.swiper.isEnd);
        }
    };

    const handleSlideInit = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            setIsFirstSlide(swiperRef.current.swiper.isBeginning);
            setIsLastSlide(swiperRef.current.swiper.isEnd);
        }
    };

    const goNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
            setIsFirstSlide(swiperRef.current.swiper.isBeginning);
            setIsLastSlide(swiperRef.current.swiper.isEnd);
        }
    };

    const goPrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
            setIsFirstSlide(swiperRef.current.swiper.isBeginning);
            setIsLastSlide(swiperRef.current.swiper.isEnd);
        }
    };

    const classButtonPrev = classNames('swiper_button button_prev', {
        swiper_button_disable: isFirstSlide,
    });

    const classButtonNext = classNames('swiper_button button_next', {
        swiper_button_disable: isLastSlide,
    });

    const isShowButtonSwiper =
        data.list.length > Math.round(data.slidePerViewPC);

    return (
        <div className="layout layout-blog">
            <div className="container">
                <LayoutHeadTitle data={data} />
                <div className="home_store_banner">
                    <Banner data={data} />
                </div>
                <div className="layout__blog">
                    {data.list.length > 0 && (
                        <>
                            <Swiper
                                ref={swiperRef}
                                {...paramsSwiper}
                                on={{
                                    init: handleSlideInit,
                                    touchMove: handleTouchChange,
                                    slideChange: handleSlideChange,
                                }}
                            >
                                {data.list.map((item: any) => {
                                    return (
                                        <div className="blog" key={item.id}>
                                            <RedirectLink
                                                redirect={item.redirectLink}
                                            >
                                                <div
                                                    className="blog__media"
                                                    style={{ borderRadius: 10 }}
                                                >
                                                    <div
                                                        className="item__media"
                                                        style={{
                                                            maxHeight: 150,
                                                            height: '150px',
                                                            position:
                                                                'relative',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                maxHeight: 150,
                                                                height: '100%',
                                                                backgroundImage: `url(${
                                                                    isMobile &&
                                                                    item.linkImageMb
                                                                        ? item.linkImageMb
                                                                        : item.linkImage
                                                                })`,
                                                                backgroundSize:
                                                                    'cover',
                                                            }}
                                                        />
                                                    </div>

                                                    <div className="absolute left-0 w-full bottom-[6px] text-center">
                                                        <div className="text-white font-semibold md:font-bold text-base md:text-[20px]">
                                                            <>
                                                                {item.title ===
                                                                    'Các Thành phố khác' && (
                                                                    <div className="flex justify-center gap-1.5 mb-1">
                                                                        <div className="w-[10px] h-[10px] md:w-[12px] md:h-[12px] rounded-full bg-[#C3CCDE]" />
                                                                        <div className="w-[10px] h-[10px] md:w-[12px] md:h-[12px] rounded-full bg-[#C3CCDE]" />
                                                                        <div className="w-[10px] h-[10px] md:w-[12px] md:h-[12px] rounded-full bg-[#C3CCDE]" />
                                                                    </div>
                                                                )}
                                                            </>
                                                            {item.title}
                                                        </div>
                                                        <div className="text-white text-sm md:text-base">
                                                            {item.subTitle}
                                                        </div>
                                                    </div>
                                                </div>
                                            </RedirectLink>
                                        </div>
                                    );
                                })}
                            </Swiper>
                            {isShowButtonSwiper && data.list.length > 3 && (
                                <>
                                    <Button
                                        className={classButtonPrev}
                                        shape="circle"
                                        disabled={isFirstSlide}
                                        onClick={goPrev}
                                    >
                                        <img src={vectorLeft} alt="left" />
                                    </Button>
                                    <Button
                                        className={classButtonNext}
                                        shape="circle"
                                        disabled={isLastSlide}
                                        onClick={goNext}
                                    >
                                        <img src={vectorRight} alt="right" />
                                    </Button>
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default LayoutSalonAddress;
