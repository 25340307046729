import Client from './client/luckyWheelClient';
import CustomerInsightClient from './client/SavingLocationClient';
import CustomerInsightClientv2  from "./client/luckyWheelClientv2";
const resources = '/api/v1';

export default {
    fetchStaffInfo(params) {
        return Client.get(`${resources}/wheeling`, {params});
    },
    fetchGifts(params) {
        return Client.get(`${resources}/gifts`, {params});
    },
    fetchConfigExchange(params) {
        return Client.get(`${resources}/times/exchange-configs`, {params});
    },
    exchangeTurn(params) {
        const { idPackage } = params;
        return Client.get(`${resources}/times/exchange/${idPackage}`, {params});
    },
    wheelProcess(params) {
        return Client.get(`${resources}/wheeling/process`, {params});
    },
    fetchListGiftStaff(params) {
        const { ...ortherParam } = params;
        return Client.get(`${resources}/wheeling/my-receive-gifts`, {params: ortherParam});
    },
    fetchListGift(params) {
        const { ...ortherParam } = params;
        return Client.get(`${resources}/wheeling/all-receive-gifts`, {params: ortherParam});
    },
    fetchQuests(params) {
        return Client.get(`${resources}/quests`, {params});
    },
    fetchDetailRewardWinning(params){
        return CustomerInsightClient.get('/api/customer/reward-vqmm', {params});
    },
    fetchHistoryPoint(params){
        return CustomerInsightClient.get('/api/customer/history-lucky-point', {params});
    },
    fetchGetPoint(params){
        return CustomerInsightClientv2.get(`/api/LuckySprint/GetPoint?customerId=${params}`);
    },
    fetchGetVoucher(params){
        return CustomerInsightClientv2.get(`/api/LuckySprint/GetVoucher?page=${params?.page}&pageSize=${params?.pageSize}`);
    },
    fetchGetDetailVoucher(params){
        return CustomerInsightClientv2.get(`/api/LuckySprint/GetVoucherById?voucherId=${params}`);
    },
    fetchDoiVoucher(params){
        return CustomerInsightClientv2.get(`/api/LuckySprint/DoiVoucher?voucherId=${params.voucherId}&customerId=${params.customerId}`);
    }
};
