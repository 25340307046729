import { Reducer } from 'redux';
import {
    BOOKING_SET_ID_SUCCESS,
    BOOKING_SET_TIME_SPECIAL,
    BOOKING_SET_PHONE,
} from '../actionTypes/booking';
import { Action } from '../actions/booking';

export type IStateBooking = {
    bookingId: number;
    phoneBooking: string;
    listTimeSpecialHours: Array<any>;
};

const initialState: IStateBooking = {
    bookingId: 0,
    phoneBooking: '',
    listTimeSpecialHours: [],
};

const booking: Reducer<IStateBooking, Action> = (
    state = initialState,
    action: Action,
) => {
    switch (action.type) {
        case BOOKING_SET_ID_SUCCESS: {
            return { ...state, bookingId: action.bookingId };
        }

        case BOOKING_SET_PHONE: {
            return { ...state, phoneBooking: action.bookingPhone };
        }

        case BOOKING_SET_TIME_SPECIAL: {
            return {
                ...state,
                listTimeSpecialHours: action.listTimeSpecialHours,
            };
        }

        default:
            return state;
    }
};

export default booking;
