import React, { FC, useEffect, useRef, useState } from 'react';
import Swiper from 'react-id-swiper';
import { ITimeBox } from 'pages/bookingNew/types/DateTime';
import classNames from 'classnames';
import { Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import KEY from 'pages/bookingNew/Const';
import ItemBoxTimeGB from './ItemBoxTimeGB';

const paramSwiper = {
    spaceBetween: 11,
    slidesPerView: 4.5,
    // breakpoints: {
    //     1200: {
    //         spaceBetween: 8,
    //         slidesPerView: 4.5,
    //     },
    //     700: {
    //         spaceBetween: 8,
    //         slidesPerView: 4.3,
    //     },
    //     500: {
    //         spaceBetween: 8,
    //         slidesPerView: 3.8,
    //     },
    //     100: {
    //         spaceBetween: 8,
    //         slidesPerView: 3.3,
    //     },
    // },
};

const BookingSlot: FC<{
    isLoadingHour: any;
    data: any;
    onChangeSelected: any;
    selected: any;
}> = ({ isLoadingHour, data, onChangeSelected, selected }) => {
    const swiperRef = useRef<any>(null);
    const [isFirstSlide, setIsFirstSlide] = useState(true);
    const [isLastSlide, setIsLastSlide] = useState(false);
    const [newHourList, setNewHourList] = useState<any>([]);
    const [listSlotSelected, setListSlotSelected] = useState<any>([]);
    const [isFull, setIsFull] = useState(false);
    console.log(listSlotSelected, 'listSlotSelected');
    useEffect(() => {
        if (data) {
            const combinedHours = data.flatMap((item: any) =>
                item.hours.map((hour: any) => ({ ...hour, name: item.name })),
            );
            setNewHourList(combinedHours);
        }
    }, [data]);

    //
    const scrollToSelectTime = () => {
        const element = document.querySelector('#box-time') as HTMLDivElement;
        const offset = 200;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;
        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
        });
    };

    const handleSlideChange = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            setIsFirstSlide(swiperRef.current.swiper.isBeginning);
            setIsLastSlide(swiperRef.current.swiper.isEnd);
        }
    };

    const goNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
            setIsFirstSlide(swiperRef.current.swiper.isBeginning);
            setIsLastSlide(swiperRef.current.swiper.isEnd);
        }
    };

    const goPrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
            setIsFirstSlide(swiperRef.current.swiper.isBeginning);
            setIsLastSlide(swiperRef.current.swiper.isEnd);
        }
    };

    const classButtonPrev = classNames('swiper_button button_prev', {
        swiper_button_disable: isFirstSlide,
    });

    const classButtonNext = classNames('swiper_button button_next', {
        swiper_button_disable: isLastSlide,
    });
    //

    useEffect(() => {
        if (selected[KEY.GROUP_BOOKING] === listSlotSelected?.length) {
            onChangeSelected(
                KEY.BOOK_HOURS,
                listSlotSelected.map((item: any) => ({
                    bookHourId: item.bookHourId,
                    secondSubHourId: item.secondSubHourId,
                    subHourId: item.subHourId,
                })),
            );
            setIsFull(true);
        } else {
            onChangeSelected(
                KEY.BOOK_HOURS,
                listSlotSelected.map((item: any) => ({
                    bookHourId: item.bookHourId,
                    secondSubHourId: item.secondSubHourId,
                    subHourId: item.subHourId,
                })),
            );
            setIsFull(false);
        }
        if (selected[KEY.GROUP_BOOKING] < listSlotSelected?.length) {
            const newListJobPosition = listSlotSelected?.filter(
                (item: any, index: number) =>
                    index !== listSlotSelected?.length - 1,
            );
            setListSlotSelected(newListJobPosition);
            onChangeSelected(
                KEY.BOOK_HOURS,
                newListJobPosition.map((item: any) => ({
                    bookHourId: item.bookHourId,
                    secondSubHourId: item.secondSubHourId,
                    subHourId: item.subHourId,
                })),
            );
            setIsFull(false);
        }
        if (selected[KEY.GROUP_BOOKING] === 1) {
            onChangeSelected(KEY.BOOK_HOURS, []);
            setIsFull(false);
        }
    }, [selected[KEY.GROUP_BOOKING], listSlotSelected]);

    return (
        <div className={`${isLoadingHour ? 'hide-time' : 'relative'}`}>
            <div className="h-[20px]">
                {selected[KEY.GROUP_BOOKING] != listSlotSelected?.length ? (
                    <div className="text-sm text-[#EF4E24] font-semibold">
                        Còn{' '}
                        {selected[KEY.GROUP_BOOKING] - listSlotSelected?.length}{' '}
                        người chưa chọn giờ
                    </div>
                ) : null}
            </div>
            {newHourList.length ? (
                <Swiper
                    {...paramSwiper}
                    ref={swiperRef}
                    on={{ slideChange: handleSlideChange }}
                >
                    {newHourList
                        .filter(
                            (data: any) => data.totalEmpty > 0 && data.isFree,
                        )
                        .map((item: any, indexGr: number) => {
                            return (
                                <div>
                                    <ItemBoxTimeGB
                                        hourData={item}
                                        setListSlotSelected={
                                            setListSlotSelected
                                        }
                                        listSlotSelected={listSlotSelected}
                                        isFull={isFull}
                                    />
                                </div>
                            );
                        })}
                    </Swiper>
                ) : null}
                <Button
                    className={classButtonPrev}
                    shape="circle"
                    disabled={isFirstSlide}
                    icon={<LeftOutlined className="swiper_button_icon" />}
                    onClick={goPrev}
                />
                <Button
                    className={classButtonNext}
                    shape="circle"
                    disabled={isLastSlide}
                    icon={<RightOutlined className="swiper_button_icon" />}
                    onClick={goNext}
                />
            </div>
        );
    };

export default BookingSlot;
